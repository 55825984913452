import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import HomepageScreen from "./HomepageScreen";

const HomeRoutes: RouteObject = {
  path: "/home",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <MenuLayout currentTab="home">
          <HomepageScreen />
        </MenuLayout>
      ),
    },
  ],
};

export default HomeRoutes;
