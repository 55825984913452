import {
  NumberInput,
  PasswordInput,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "../lib/CopyToClipboard";
import useApolloErrorHandler from "../lib/handleApolloError";
import Screen from "../lib/Screen";
import {
  hasMaxValue,
  hasMinValue,
  isPassword,
  isRequired,
  mergeRules,
} from "../lib/validate";
import { CreateSystemDocument } from "./CreateSystem.generated";

type FormData = {
  name: string;
  code: string;
  recordingRetention: number;
  ameelioStaffPassword: string;
};

export default function CreateSystemScreen() {
  const navigate = useNavigate();
  const handleApolloError = useApolloErrorHandler();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
  });

  const [createSystem] = useMutation(CreateSystemDocument, {
    onError: (e) => handleApolloError(e),
  });

  const onSubmit = async ({ code, ...input }: FormData) => {
    await createSystem({
      variables: { input: { ...input, code: code.toUpperCase() } },
      onCompleted: (data) =>
        navigate(`/toolbox/systems/${data.createSystem.system.id}`),
    });
  };

  const code = watch("code");
  const staffAccountEmail = code
    ? `connect-support+${code.toLowerCase()}@ameelio.org`
    : "connect-support+CODE@ameelio.org";

  return (
    <Screen title="Create system">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <TextInput
            control={control}
            name="name"
            label={t("Name")}
            rules={isRequired("Please provide a name")}
          />
          <TextInput
            control={control}
            name="code"
            label="Code"
            helperText={`The resident portal URL will be: https://${
              code?.toLowerCase() || "code"
            }-app.ameelio.org`}
            rules={mergeRules(isRequired("Please provide a valid code."), {
              pattern: /^[A-Za-z-]{1,63}$/,
            })}
          />
          <NumberInput
            control={control}
            name="recordingRetention"
            label="Recording retention (days)"
            rules={mergeRules(
              isRequired("Recording retention is required"),
              hasMinValue(0),
              hasMaxValue(365)
            )}
          />
          <Stack spacing={2} component="fieldset">
            <legend>Ameelio staff account</legend>
            <Typography variant="body1">
              Create a NordPass entry with a generated password, then share it
              with support.
            </Typography>
            <CopyToClipboard label="Username" value={staffAccountEmail} />
            <PasswordInput
              control={control}
              name="ameelioStaffPassword"
              label="Password"
              rules={isPassword()}
            />
          </Stack>
          <SubmitButton submitting={isSubmitting}>{t("Submit")}</SubmitButton>
        </Stack>
      </form>
    </Screen>
  );
}
