import { Box, Modal, SxProps, Theme } from "@mui/material";

type Props = {
  sx: SxProps<Theme>;
  children: React.ReactNode;
};

export default function FullscreenBox({ children, sx }: Props) {
  return (
    <Modal
      open
      hideBackdrop
      disableAutoFocus
      role="dialog"
      sx={{
        overflowY: "auto",
        ...sx,
      }}
    >
      <Box sx={{ outline: "none" }}>{children}</Box>
    </Modal>
  );
}
