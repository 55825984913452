import { Box, Typography } from "@mui/material";

type Props = {
  date: Date;
};

export default function TimelineDateMarker({ date }: Props) {
  return (
    <Box sx={{ textAlign: "center" }} pb={1}>
      <Typography variant="caption" color="text.secondary">
        {new Intl.DateTimeFormat([], {
          month: "long",
          day: "numeric",
        }).format(date)}
      </Typography>
    </Box>
  );
}
