import { Avatar } from "@ameelio/ui";
import useAudioLevel from "../../lib/useAudioLevel";

type Props = {
  user: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  stream: MediaStream | undefined;
};

function AudioIndicatingAvatar({ user, stream }: Props) {
  const relativeVolume = useAudioLevel({ stream });
  const size = Math.round(relativeVolume * 15);

  return (
    <Avatar
      contact={user}
      sx={{
        width: 100,
        height: 100,
        boxShadow: `0px 0px ${size}px ${size}px #fff`,
      }}
    />
  );
}

export default AudioIndicatingAvatar;
