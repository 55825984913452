import { Box, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import buildPageTitle from "../lib/buildPageTitle";
import { NotificationsList } from "./NotificationsList";

export default function NotificationsScreen() {
  const theme = useTheme();
  const { t } = useTranslation();
  const pageTitle = buildPageTitle(t("Notifications"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        whiteSpace: "nowrap",
        padding: "0 !important",
        paddingTop: `${theme.spacing(2)} !important`,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <NotificationsList />
    </Box>
  );
}
