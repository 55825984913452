import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { ExpandMore, InfoOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { MeetingType, PrivacyLevel } from "../api/graphql";
import { BaseSkeleton, TitleSkeleton } from "../lib/closet";
import useApolloErrorHandler from "../lib/handleApolloError";
import privacyLevelDescription from "../lib/privacyLevelDescription";
import { formatTime, hoursMinutesSecondsTextLabel } from "../lib/timeFormats";
import { GetCurrentInmateVoiceCallInfoDocument } from "./GetCurrentInmateVoiceCallInfo.generated";
import { StartVoiceCallDocument } from "./StartVoiceCall.generated";

type Props = {
  connectionId: string;
  onCancel: () => void;
};

function ReducedRemainingDurationNote({
  remainingDuration,
}: {
  remainingDuration: number;
}) {
  const { t } = useTranslation();
  const remainingMinutes = Math.floor(remainingDuration / (1000 * 60));
  return (
    <Alert severity="warning">
      {remainingDuration % (1000 * 60) > 0
        ? t(
            "You have less than {{count}} minutes available for this call, shorter than usual.",
            { count: remainingMinutes + 1 }
          )
        : t(
            "You have {{count}} minutes available for this call, shorter than usual.",
            { count: remainingMinutes }
          )}
    </Alert>
  );
}

function OutOfSessionMinutesNote({ resetLength }: { resetLength: number }) {
  const { t } = useTranslation();

  return (
    <Alert severity="error">
      {t(
        "You've used all of your voice call minutes for this session. Your session minutes reset {{count}} minutes after the end of your most recent call.",
        { count: resetLength / (60 * 1000) }
      )}
    </Alert>
  );
}

function OutOfDailyQuotaNote() {
  const { t } = useTranslation();

  return (
    <Alert severity="error">
      {t(
        "You've used all of your voice call minutes today. Your minutes reset tomorrow."
      )}
    </Alert>
  );
}

function RemainingTimeBox({
  remaining,
  resetTime,
  description,
}: {
  remaining: number;
  resetTime?: number | null;
  description: string;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 3 },
        px: 1,
        height: { xs: 140, sm: 120 },
        width: 1 / 2,
        borderRadius: 4,
        border: 0.5,
        borderColor: "divider",
        backgroundColor: grey[50],
      }}
    >
      <Stack alignItems="center">
        <Typography variant="h3" style={{ textAlign: "center" }}>
          {hoursMinutesSecondsTextLabel(remaining)}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ textAlign: "center" }}
        >
          {description}
        </Typography>
        {resetTime && (
          <Typography
            variant="caption"
            color="text.disabled"
            style={{ textAlign: "center" }}
          >
            {t("Resets at {{resetTime}}", {
              resetTime: formatTime(resetTime),
            })}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

function ItemNumber({ number }: { number: number }) {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "50%",
        borderColor: "text.secondary",
        borderStyle: "solid",
        borderWidth: 1,
        marginTop: "1px",
        width: 24,
        height: 24,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="caption" color="text.secondary">
        {number}
      </Typography>
    </Box>
  );
}

function PrivacyInformation({ privacyLevel }: { privacyLevel: PrivacyLevel }) {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="overline">{t("Monitoring")}</Typography>
        <Typography variant="body1">
          {privacyLevelDescription(MeetingType.VoiceCall, privacyLevel)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="overline">{t("Privacy Notice")}</Typography>
        <Typography variant="body1">
          {privacyLevel === PrivacyLevel.Monitored
            ? t(
                "By participating in this call, I consent to the potential monitoring and recording of audio by the affiliated correctional facility. I agree to abide by the facility's stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities."
              )
            : t(
                "I agree to abide by the facility's stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities."
              )}
        </Typography>
      </Box>
    </Stack>
  );
}

function LimitedVoiceCallDialogContent({
  privacyLevel,
  maxDuration,
  maxQuota,
  resetLength,
  resetTime,
  remainingDuration,
  remainingQuota,
}: {
  privacyLevel: PrivacyLevel;
  maxDuration: number;
  maxQuota: number;
  resetLength: number;
  resetTime: number | null;
  remainingDuration: number;
  remainingQuota: number;
}) {
  const { t } = useTranslation();

  const showReducedRemainingDurationNote =
    remainingDuration > 0 && remainingDuration < maxDuration;
  const showOutOfSessionMinutesNote =
    remainingDuration === 0 && remainingQuota > 0;
  const showOutOfDailyQuotaNote = remainingQuota === 0;

  return (
    <DialogContent sx={{ marginTop: 2 }}>
      <Typography variant="caption">{t("VOICE CALL MINUTES")}</Typography>
      <Stack direction="row" alignItems="center" spacing={2} marginTop={1}>
        <RemainingTimeBox
          remaining={remainingDuration}
          resetTime={resetTime}
          description={t("Available now")}
        />
        <RemainingTimeBox
          remaining={remainingQuota}
          description={t("Remaining today")}
        />
      </Stack>

      <Box pt={1} display="flex" alignItems="center">
        <InfoOutlined sx={{ fontSize: 14 }} />
        <Typography pl={1} variant="caption" color="text.secondary">
          {t(
            "This is the total time for all of your voice calls, not for a particular contact."
          )}
        </Typography>
      </Box>

      <Accordion
        sx={{
          paddingTop: 1,
          "&:before": {
            display: "none",
          },
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          sx={{
            boxShadow: "none",
            padding: 0,
          }}
        >
          <Typography variant="body1" color="primary">
            {t("How do voice call minutes work and when do they reset?")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container wrap="nowrap" marginBottom={2}>
            <Grid item flexGrow={1} marginRight={1}>
              <ItemNumber number={1} />
            </Grid>
            <Grid item flexGrow={1}>
              <Stack>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {t(
                    "A single call can be up to {{maxDurationMinutes}} minutes long",
                    { maxDurationMinutes: maxDuration / (60 * 1000) }
                  )}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {t(
                    "If your call is less than {{maxDurationMinutes}} minutes, you may make additional calls with the remaining minutes you have available. You will receive additional minutes for calls {{resetLengthMinutes}} minutes after your most recent call.",
                    {
                      maxDurationMinutes: maxDuration / (60 * 1000),
                      resetLengthMinutes: resetLength / (60 * 1000),
                    }
                  )}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap">
            <Grid item flexGrow={1} paddingRight={1}>
              <ItemNumber number={2} />
            </Grid>
            <Grid item flexGrow={1}>
              <Stack>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {maxQuota % (60 * 60 * 1000) === 0
                    ? t("You get {{count}} hours of calls every day", {
                        count: maxQuota / (60 * 60 * 1000),
                      })
                    : t("You get {{count}} minutes of calls every day", {
                        count: maxQuota / (60 * 1000),
                      })}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  paddingBottom={3}
                >
                  {maxQuota % (60 * 60 * 1000) === 0
                    ? t(
                        "You are entitled to {{count}} hours ({{maxQuotaMinutes}} minutes) of free voice calls every day and can choose who you'd like to call. Please note that if you have less than {{maxDurationMinutes}} minutes remaining in your day then your call will be shorter than usual.",
                        {
                          count: maxQuota / (60 * 60 * 1000),
                          maxQuotaMinutes: maxQuota / (60 * 1000),
                          maxDurationMinutes: maxDuration / (60 * 1000),
                        }
                      )
                    : t(
                        "You are entitled to {{maxQuotaMinutes}} minutes of free voice calls every day and can choose who you'd like to call. Please note that if you have less than {{maxDurationMinutes}} minutes remaining in your day then your call will be shorter than usual.",
                        {
                          maxQuotaMinutes: maxQuota / (60 * 1000),
                          maxDurationMinutes: maxDuration / (60 * 1000),
                        }
                      )}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  {t("**Your daily limit resets every day.**")}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box
        sx={{
          p: 2,
          borderRadius: 4,
          border: 0.5,
          borderColor: "divider",
          backgroundColor: grey[50],
        }}
      >
        <PrivacyInformation privacyLevel={privacyLevel} />
      </Box>
      <Box mt={2}>
        {showReducedRemainingDurationNote && (
          <ReducedRemainingDurationNote remainingDuration={remainingDuration} />
        )}
        {showOutOfSessionMinutesNote && (
          <OutOfSessionMinutesNote resetLength={resetLength} />
        )}
        {showOutOfDailyQuotaNote && <OutOfDailyQuotaNote />}
      </Box>
    </DialogContent>
  );
}

function UnlimitedVoiceCallDialogContent({
  privacyLevel,
}: {
  privacyLevel: PrivacyLevel;
}) {
  return (
    <DialogContent sx={{ marginTop: 2 }}>
      <PrivacyInformation privacyLevel={privacyLevel} />
    </DialogContent>
  );
}

export default function BeginVoiceCallDialog({
  onCancel,
  connectionId,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const location = useLocation();
  const navigate = useNavigate();

  const { data, error } = useQuery(GetCurrentInmateVoiceCallInfoDocument, {
    fetchPolicy: "network-only",
    variables: { connectionId },
  });

  const [startCall, { loading: starting }] = useMutation(
    StartVoiceCallDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: (startedData) => {
        navigate(`/voice-call/${startedData.createVoiceCall.meeting.id}`, {
          state: { from: location.pathname },
        });
      },
      variables: {
        input: {
          connectionId,
        },
      },
    }
  );

  if (error) throw error;
  if (!data?.currentInmate)
    return (
      <Dialog fullWidth title="">
        <Stack margin={4} spacing={4}>
          <TitleSkeleton />
          <BaseSkeleton variant="rectangular" height={300} />{" "}
        </Stack>
      </Dialog>
    );

  const limitedByQuota = !data.connection.organizationMembership;
  // this mirrors the logic in createVoiceCall
  const privacyLevel = data.connection.confidential
    ? PrivacyLevel.UnmonitoredLogged
    : data.currentInmate.facility.voiceCallPrivacyLevel;

  const remainingDuration = Math.min(
    data.currentInmate.remainingVoiceCallTimeQuota,
    data.currentInmate.remainingVoiceCallDuration
  );

  return (
    <Dialog
      title={t("Review the following before making your voice call")}
      onClose={onCancel}
    >
      {limitedByQuota ? (
        <LimitedVoiceCallDialogContent
          maxDuration={data.currentInmate.facility.maxVoiceCallDuration || 0}
          maxQuota={data.currentInmate.voiceCallTimeQuota}
          resetLength={data.currentInmate.facility.voiceCallResetTime}
          resetTime={data.currentInmate.remainingVoiceCallDurationResetTime}
          remainingQuota={data.currentInmate.remainingVoiceCallTimeQuota}
          remainingDuration={remainingDuration}
          privacyLevel={privacyLevel}
        />
      ) : (
        <UnlimitedVoiceCallDialogContent privacyLevel={privacyLevel} />
      )}
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button autoFocus onClick={onCancel}>
          {t("Cancel")}
        </Button>

        <SubmitButton
          submitting={starting}
          onClick={() => startCall()}
          disabled={limitedByQuota && remainingDuration === 0}
        >
          {t("Agree & Call")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
