import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SystemUserStatus } from "../api/graphql";
import { useCurrentCorrespondent } from "../SessionBoundary";
import RejectionAlert from "./RejectionAlert";

export default function useIdRejectionStatus() {
  const user = useCurrentCorrespondent();
  if (user.__typename !== "Visitor") return false;
  return user.systemRelationships.some(
    (sr) => sr.status === SystemUserStatus.Rejected
  );
}

export function RejectedIDAlert() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <RejectionAlert
      title={t(
        "You must upload new ID documents for your pending contact requests."
      )}
      onClick={() => navigate("/settings")}
    />
  );
}
