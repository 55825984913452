import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PrivacyLevel } from "../api/graphql";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { darkPalette, ltrTheme } from "../theme";
import { GetMeetingInfoQuery } from "./GetMeetingInfo.generated";
import AudioIndicatingAvatar from "./LiveCall/AudioIndicatingAvatar";
import CallControls from "./LiveCall/CallControls";
import ParticipantPlaceholder from "./LiveCall/ParticipantPlaceholder";
import VoiceCallContext from "./LiveCall/VoiceCallContext";

const style: SxProps<Theme> = {
  px: 2,
  py: 2,
  height: "100%",
  backgroundColor: darkPalette.background.default,
  overflow: "hidden",
  position: "relative",
};

type Props = {
  muted: boolean;
  alerts: boolean;
  onToggleAudio: () => void;
  onToggleAlerts: () => void;
  onLeave: () => void;
  meeting?: {
    privacyLevel: PrivacyLevel;
    interval?: GetMeetingInfoQuery["meeting"]["interval"];
    correspondents: GetMeetingInfoQuery["meeting"]["correspondents"];
    connections?: GetMeetingInfoQuery["meeting"]["connections"];
  };
  missed: boolean;
};

function DialingScreen({
  muted,
  alerts,
  onToggleAlerts,
  onToggleAudio,
  onLeave,
  meeting,
  missed,
}: Props) {
  const { t } = useTranslation();
  const currentUser = useCurrentCorrespondent();

  const contact = meeting?.correspondents.find(
    (x) => !(x.__typename === currentUser.__typename && x.id === currentUser.id)
  );

  const interval = meeting?.interval;

  return (
    <Box sx={{ height: 1, width: "100%" }}>
      <Box sx={style}>
        {contact && interval && (
          <>
            <VoiceCallContext
              contact={contact}
              interval={
                !meeting?.connections?.[0]?.organizationMembership // Calls to providers have no time limit
                  ? interval
                  : undefined
              }
              privacyLevel={meeting.privacyLevel}
            />
            <ParticipantPlaceholder
              styleOverrides={{ borderRadius: "initial" }}
            >
              <AudioIndicatingAvatar user={contact} stream={undefined} />
              <Typography paddingTop={1}>
                {missed ? t("Missed") : t("Initializing...")}{" "}
              </Typography>
            </ParticipantPlaceholder>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            width: 1,
            position: "absolute",
            zIndex: "modal",
            left: 0,
            bottom: ltrTheme.spacing(8),
            px: 2,
            height: 0,
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <CallControls
              muted={muted}
              hidden={false}
              alerts={alerts}
              onToggleAudio={onToggleAudio}
              onToggleAlerts={onToggleAlerts}
              onLeave={onLeave}
              leaveCallLabel={t("End call")}
              leaveCallDisabled={!missed}
              disabledReason={t("The call is still initializing")}
              styleOverrides={{
                transform: "translateY(-50%)",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function BlankDialingScreen() {
  return (
    <Box sx={{ height: 1, width: "100%" }}>
      <Box sx={style} />
    </Box>
  );
}

export default DialingScreen;
