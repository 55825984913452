import EventIcon from "@mui/icons-material/Event";
import {
  InputAdornment,
  TextField,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import {
  DatePickerProps as BaseProps,
  DesktopDatePicker as DesktopBase,
  MobileDatePicker as MobileBase,
} from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useState } from "react";
import { belowLargeTablet } from "./responsiveHelpers";
import { getLocalizedDateFormatString, inferDateFormat } from "./timeFormats";
import withController from "./withController";

type ResolvedBaseProps = BaseProps<Date, Date>;
type ManagedProps = "renderInput" | "defaultValue" | "sx" | "open";
export type DateInputProps = Omit<ResolvedBaseProps, ManagedProps> & {
  autoComplete?: string;
  onChange: Required<ResolvedBaseProps>["onChange"];
  value: Required<ResolvedBaseProps>["value"];
  error?: boolean;
  label: string;
  helperText?: string;
};

export function DateInputBase({
  value,
  error,
  helperText,
  autoComplete,
  ...rest
}: DateInputProps) {
  const [open, setOpen] = useState(false);
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  /* This format has to be adapted to this specific locale */
  const dateFormat = inferDateFormat()
    .replace(/-/gi, "/")
    .replace("DD", "dd")
    .replace("YYYY", "yyyy");

  const formatValue = (val: Date | null) => {
    try {
      if (val) return format(val, dateFormat);
    } catch (e) {
      return val;
    }
    return val;
  };

  if (isMobileOrSmallTablet) {
    return (
      <MobileBase
        {...rest}
        value={value || null}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        inputFormat={dateFormat}
        disableMaskedInput
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EventIcon />
            </InputAdornment>
          ),
        }}
        renderInput={({ onChange, ...params }) => (
          <TextField
            {...params}
            value={formatValue(value)}
            onClick={() => setOpen(true)}
            sx={{ width: 1 }}
            error={error}
            helperText={helperText}
            placeholder={getLocalizedDateFormatString().toLowerCase()}
          />
        )}
      />
    );
  }
  return (
    <DesktopBase
      {...rest}
      value={value || null}
      inputFormat={dateFormat}
      open={open}
      onClose={onClose}
      disableMaskedInput
      renderInput={({ onChange, ...params }) => (
        <TextField
          {...params}
          value={formatValue(value)}
          onClick={onOpen}
          autoComplete={autoComplete}
          sx={{ width: 1 }}
          error={error}
          helperText={helperText}
          placeholder={getLocalizedDateFormatString().toLowerCase()}
        />
      )}
    />
  );
}

export default withController(DateInputBase);
