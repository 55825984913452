import { Avatar } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  AccountCircleOutlined,
  ArrowBackIosNew,
  CalendarToday,
} from "@mui/icons-material";
import VideocamIcon from "@mui/icons-material/Videocam";
import {
  alpha,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { common } from "@mui/material/colors";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ConnectionStatus,
  CorrespondentFeature,
  MessageType,
} from "../api/graphql";
import { unaccompaniedVisitAllowed } from "../Events/utils";
import activeOrgMembership from "../lib/activeOrgMembership";
import { BaseSkeleton } from "../lib/closet";
import featureEnabled from "../lib/featureEnabled";
import Link from "../lib/Link";
import {
  formatTimeRange,
  relativeDate,
  shortMonthDateFormat,
} from "../lib/timeFormats";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import withSx from "../lib/withSx";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { ltrTheme } from "../theme";
import { GetConnectionDetailsDocument } from "./GetConnectionDetails.generated";
import { GetConnectionFileGalleryDocument } from "./GetConnectionFileGallery.generated";
import { DocumentGallery, ImageGallery } from "./MediaGallery";

type Props = {
  connectionId: string;
};

const Panel = withSx(Box, {
  py: 3,
  px: 2,
  flexGrow: 0,
  width: { xs: "inherit", md: 240 },
  minWidth: 240,
  overflow: "auto",
  backgroundColor: "background.default",
  borderLeftStyle: "solid",
  borderLeftWidth: { xs: 0, md: 1 },
  borderBottomWidth: { xs: 1, md: 0 },
  borderBottomStyle: "solid",
  borderColor: "divider",
});

export default function ContactDetailsPanel({ connectionId }: Props) {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const organizationId = activeOrgMembership(user)?.organization.id || "";
  const { data } = useQuery(GetConnectionDetailsDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      connectionId,
      organizationId,
      fetchOrganizationInfo: false, // 2023.02.07 - Not required for designs at present
      fileGalleryCount: 9,
    },
  });

  const mayScheduleEvents =
    useFeaturePermitted(CorrespondentFeature.ScheduleMeetings) &&
    unaccompaniedVisitAllowed(user, connectionId);

  const {
    data: galleryData,
    error,
    fetchMore,
  } = useQuery(GetConnectionFileGalleryDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: connectionId,
      perPage: 9,
      before: undefined,
      after: undefined,
    },
  });

  const galleryItems = galleryData?.connection.fileGallery.edges.map(
    ({ node }) => node
  );
  const galleryImages = (galleryItems || []).filter(
    (r) => r.type === MessageType.Photo
  );
  const galleryDocuments = (galleryItems || []).filter(
    (r) => r.type === MessageType.Document
  );

  // optional callback that will load older events
  const fetchOlder = useMemo(() => {
    const pageInfo = galleryData?.connection.fileGallery.pageInfo;
    return pageInfo?.hasPreviousPage
      ? () =>
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
            },
          })
      : undefined;
  }, [galleryData, fetchMore]);

  const [seeAllImages, setSeeAllImages] = useState(false);
  const [seeAllDocuments, setSeeAllDocuments] = useState(false);

  if (error) throw error;
  if (!data) {
    return (
      <Panel>
        <BaseSkeleton />
      </Panel>
    );
  }

  const { contact } = data.connection;
  // Sort meetings start time ASC
  const upcomingMeetings = data.connection.meetings
    .filter((m) => m.interval.endAt > Date.now())
    .sort((a, b) => a.interval.startAt - b.interval.startAt);

  const photoMessages = data.connection.fileGallery.edges
    .map(({ node }) => node)
    .filter((r) => r.type === MessageType.Photo);

  const documentMessages = data.connection.fileGallery.edges
    .map(({ node }) => node)
    .filter((r) => r.type === MessageType.Document);

  if (seeAllImages) {
    return (
      <Panel>
        <Box display="flex" p={0} mb={1.5} alignItems="center">
          <IconButton onClick={() => setSeeAllImages(false)} size="small">
            <ArrowBackIosNew htmlColor={common.black} fontSize="small" />
          </IconButton>
          <Typography variant="subtitle2" color="text.primary">
            {t("Images")}
          </Typography>
        </Box>
        <ImageGallery messages={galleryImages} />
        {fetchOlder && (
          <Box justifyContent="center" display="flex" flexDirection="row" p={2}>
            <Button
              onClick={() => {
                fetchOlder();
              }}
            >
              {t("Load more")}
            </Button>
          </Box>
        )}
      </Panel>
    );
  }

  if (seeAllDocuments) {
    return (
      <Panel>
        <Box display="flex" p={0} mb={1.5} alignItems="center">
          <IconButton onClick={() => setSeeAllDocuments(false)} size="small">
            <ArrowBackIosNew htmlColor={common.black} fontSize="small" />
          </IconButton>
          <Typography variant="subtitle2" color="text.primary">
            {t("Documents")}
          </Typography>
        </Box>
        <DocumentGallery messages={galleryDocuments} />
        {fetchOlder && (
          <Box justifyContent="center" display="flex" flexDirection="row" p={2}>
            <Button
              onClick={() => {
                fetchOlder();
              }}
            >
              {t("Load more")}
            </Button>
          </Box>
        )}
      </Panel>
    );
  }

  return (
    <Panel>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={0}
        mb={3}
        gap={1.5}
      >
        <Avatar contact={contact} sx={{ width: 66, height: 66 }} />
        <Typography variant="h3" component="h2">
          {contact.fullName}
        </Typography>
      </Box>
      <Box mt={3} style={{ width: "100%" }}>
        <Stack direction="row" spacing={1}>
          <Box sx={{ flexGrow: 1 }}>
            <Link
              button
              variant="outlined"
              sx={{
                width: 1,
                color: ltrTheme.palette.text.primary,
                backgroundColor: alpha(ltrTheme.palette.text.primary, 0.04),
                "&:hover": {
                  backgroundColor: alpha(ltrTheme.palette.text.primary, 0.08),
                  border: "none",
                },
                border: "none",
              }}
              to={
                organizationId
                  ? `/organization/${organizationId}/contacts/${contact.id}`
                  : `/contacts/${connectionId}`
              }
            >
              <Stack
                direction="column"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <AccountCircleOutlined color="inherit" />
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  sx={{ padding: 0 }}
                >
                  {t("Profile")}
                </Button>
              </Stack>
            </Link>
          </Box>
          {mayScheduleEvents && (
            <Box sx={{ flexGrow: 1 }}>
              <Link
                button
                disabled={data.connection.status !== ConnectionStatus.Active}
                variant="outlined"
                sx={{
                  width: 1,
                  color: ltrTheme.palette.text.primary,
                  backgroundColor: alpha(ltrTheme.palette.text.primary, 0.04),
                  "&:hover": {
                    backgroundColor: alpha(ltrTheme.palette.text.primary, 0.08),
                    border: "none",
                  },
                  border: "none",
                }}
                state={{
                  connectionId,
                  fromPath: `/messages/${connectionId}`,
                  fromName: t("Messages"),
                }}
                to="/events/new"
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <CalendarToday color="inherit" />
                  <Button
                    variant="text"
                    color="inherit"
                    size="small"
                    sx={{ padding: 0 }}
                  >
                    {t("Schedule")}
                  </Button>
                </Stack>
              </Link>
            </Box>
          )}
        </Stack>
      </Box>
      <Box mt={3}>
        <Box mb={2}>
          {!upcomingMeetings.length && (
            <>
              <Typography variant="subtitle2" color="text.primary">
                {t("Upcoming events")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("No upcoming events")}
              </Typography>
            </>
          )}
          {upcomingMeetings.length > 0 && (
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="text.primary">
                {t("Upcoming events")}
              </Typography>
              {upcomingMeetings.map((m) => (
                <Stack key={m.id} direction="row" alignItems="start">
                  <VideocamIcon sx={{ color: "text.secondary" }} />
                  <Stack direction="column" alignItems="start" ml={0.5}>
                    <Typography variant="body1" color="text.primary">
                      {relativeDate(m.interval.startAt, shortMonthDateFormat)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatTimeRange(m.interval.startAt, m.interval.endAt)}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
        </Box>
        {featureEnabled("MEDIA_SHARING") && user.__typename === "Inmate" && (
          <Box mt={1.5}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" color="text.primary">
                {t("Images")}
              </Typography>
              {!!photoMessages.length && (
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  onClick={() => setSeeAllImages(true)}
                >
                  <Typography variant="caption" color="text.primary">
                    {t("See all")}
                  </Typography>
                </Box>
              )}
            </Stack>
            {!photoMessages.length ? (
              <Typography variant="body2" color="text.secondary">
                {t("Photos will appear here once approved")}
              </Typography>
            ) : (
              <Box mt={1}>
                <ImageGallery messages={photoMessages} />
              </Box>
            )}
          </Box>
        )}
        {featureEnabled("DOCUMENT_SHARING") && user.__typename === "Inmate" && (
          <Box mt={1.5}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" color="text.primary">
                {t("Documents")}
              </Typography>
              {!!documentMessages.length && (
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  onClick={() => setSeeAllDocuments(true)}
                >
                  <Typography variant="caption" color="text.primary">
                    {t("See all")}
                  </Typography>
                </Box>
              )}
            </Stack>
            {!documentMessages.length ? (
              <Typography variant="body2" color="text.secondary">
                {t("Documents will appear here once approved")}
              </Typography>
            ) : (
              <Box mt={1}>
                <DocumentGallery messages={documentMessages} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Panel>
  );
}
