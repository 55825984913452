import { Box } from "@mui/material";

type Props = {
  color: string;
  children: React.ReactNode;
};

export default function EventBubble({ color, children }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: color,
        borderRadius: "16px",
        paddingX: 2,
        paddingY: 1,
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}
