import {
  Dialog,
  DialogActions,
  DialogContent,
  SelectInput,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useLazyQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Inmate, MeetingType } from "../../../api/graphql";
import useApolloErrorHandler from "../../../lib/handleApolloError";
import { SecondaryButton } from "../../../lib/ModalButtons";
import { isEmail, isRequired, mergeRules } from "../../../lib/validate";
import { useCurrentVisitor } from "../../../SessionBoundary";
import { RegisteredGuest, UnregisteredGuest } from "../types";
import { GetInmateGuestDocument } from "./GetInmateGuest.generated";

type Props = {
  meetingType: MeetingType;
  inmate: Pick<Inmate, "id" | "firstName">;
  registrationRequired: boolean;
  onAddRegisteredGuest: (g: RegisteredGuest) => void;
  onAddUnregisteredGuest: (g: UnregisteredGuest) => void;
  unregisteredGuests: UnregisteredGuest[];
  onClose: () => void;
};

export default function AddGuestDialog({
  meetingType,
  inmate,
  registrationRequired,
  onAddRegisteredGuest,
  onAddUnregisteredGuest,
  unregisteredGuests,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const currentUser = useCurrentVisitor();
  const snackbarContext = useSnackbarContext();

  const [getInmateGuest] = useLazyQuery(GetInmateGuestDocument, {
    onError: (e) => handleApolloError(e),
    fetchPolicy: "cache-first",
  });

  type FormData = {
    guestType: "registered" | "unregistered";
    guestName: string;
  };

  const { handleSubmit, formState, control, watch } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      guestType: "registered",
      guestName: "",
    },
  });

  const addingRegisteredGuest = watch("guestType") === "registered";

  const onSubmit = async (formData: FormData) => {
    const guestInput = formData.guestName.trim();
    if (addingRegisteredGuest) {
      const { data } = await getInmateGuest({
        variables: {
          inmateId: inmate.id,
          visitorEmail: guestInput,
        },
      });
      const guest = data?.inmate.guest;
      if (!guest) {
        snackbarContext.alert(
          "error",
          t(
            "This email is not currently approved by the facility for {{meetingType}} with {{firstName}}. Check the address entered, or that the guest has requested {{firstName}} as a contact.",
            {
              meetingType:
                meetingType === MeetingType.VideoCall
                  ? t("video calls")
                  : t("visits"),
              firstName: inmate.firstName,
            }
          )
        );
        return false;
      }
      if (guest.id === currentUser.id) {
        snackbarContext.alert("error", t("You cannot add yourself as a guest"));
        return false;
      }
      onAddRegisteredGuest(guest);
    } else {
      if (
        unregisteredGuests
          .map((g) => g.name.toLowerCase())
          .includes(guestInput.toLowerCase())
      ) {
        snackbarContext.alert(
          "error",
          t("A guest with this name already exists")
        );
        return false;
      }
      onAddUnregisteredGuest({ name: guestInput });
    }
    return true;
  };

  return (
    <Dialog title={t("Add guest")} onClose={onClose}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Typography variant="body1">
              {registrationRequired
                ? t("Are they 13 years of age or older?")
                : t("Do they have an Ameelio Connect account?")}
            </Typography>
            <SelectInput
              name="guestType"
              control={control}
              items={[
                {
                  name: t("Yes"),
                  value: "registered",
                },
                {
                  name: t("No"),
                  value: "unregistered",
                },
              ]}
            />
            <Typography variant="body2" color="text.secondary">
              {addingRegisteredGuest
                ? t(
                    "Enter the email address of the guest's approved Ameelio Connect account."
                  )
                : t(
                    "Please be prepared to present government-issued identification during the visit if asked by facility staff."
                  )}
            </Typography>
            <TextInput
              control={control}
              name="guestName"
              sx={{
                width: "100%",
                borderRadius: "8px",
              }}
              rules={mergeRules(
                isRequired(
                  addingRegisteredGuest
                    ? t("Please enter a valid email address.")
                    : t("Please enter a valid name.")
                ),
                addingRegisteredGuest ? isEmail() : {}
              )}
              autoComplete="off"
              type={addingRegisteredGuest ? "email" : "text"}
              label={
                addingRegisteredGuest
                  ? t("Guest's email address")
                  : t("Guest's full name")
              }
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <SecondaryButton onClick={onClose}>{t("Cancel")}</SecondaryButton>
          <SubmitButton
            disabled={formState.isSubmitting || !formState.isValid}
            submitting={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t("Add")}
          </SubmitButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}
