import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import buildPageTitle from "../lib/buildPageTitle";
import getFrom from "../lib/getFrom";
import Link from "../lib/Link";
import { dateToIso } from "../lib/timeFormats";
import { ScreenTitle } from "../lib/typography";
import CredentialsSignupForm, {
  CredentialsStepData,
} from "./CredentialsSignupForm";
import { linkStyle } from "./LoginScreen";
import ProfileSignupForm, { ProfileStepData } from "./ProfileSignupForm";

type FormStep = "credentials" | "profile";
type FormData = CredentialsStepData & ProfileStepData;

export default function SignupScreen() {
  const { t } = useTranslation();
  const [step, setStep] = useState<FormStep>("credentials");
  const [finished, setFinished] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
    passwordConfirmation: "",
    firstName: "",
    lastName: "",
    phone: "",
    dateOfBirth: undefined,
    termsOfUse: false,
  });
  const { register } = useAuthContext();

  const location = useLocation();
  const from = getFrom(location) || "/";

  const onSubmit = async ({ termsOfUse, ...input }: FormData) => {
    // Must have a dob and have accepted the terms
    if (!(input.dateOfBirth && termsOfUse)) return;
    if (
      await register({
        ...input,
        dateOfBirth: dateToIso(input.dateOfBirth),
      })
    )
      setFinished(true);
  };

  const pageTitle = buildPageTitle(t("Sign up"));

  if (!finished) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <ScreenTitle>{t("Create your Ameelio Connect account")}</ScreenTitle>
        {step === "credentials" && (
          <CredentialsSignupForm
            defaultValues={formData}
            onSubmit={(data) => {
              setFormData({ ...formData, ...data });
              setStep("profile");
            }}
            from={from}
          />
        )}
        {step === "profile" && (
          <ProfileSignupForm
            defaultValues={formData}
            onBack={() => setStep("credentials")}
            onSubmit={async (data) => {
              setFormData({ ...formData, ...data });
              await onSubmit({ ...formData, ...data });
            }}
          />
        )}
        <Link
          to="/support"
          state={{ backTo: "/signup", parentName: t("Sign up") }}
          sx={{
            pt: 4,
            display: "inline-block",
            ...linkStyle,
          }}
        >
          {t("Need support?")}
        </Link>
      </>
    );
  }
  return <Navigate to="/home" state={{ newUser: true }} />;
}
