import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateOrganizationInvitationMutationVariables = Types.Exact<{
  input: Types.CreateOrganizationInvitationInput;
}>;


export type CreateOrganizationInvitationMutation = { __typename: 'Mutation', createOrganizationInvitation: { __typename: 'CreateOrganizationInvitationResponse', organizationInvitation: { __typename: 'OrganizationInvitation', inviteCode: string, id: Types.Scalars['ID']['input'], email: string, role: Types.OrganizationMembershipRole } } };


export const CreateOrganizationInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOrganizationInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateOrganizationInvitationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOrganizationInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationInvitation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationInvitationFragment"}},{"kind":"Field","name":{"kind":"Name","value":"inviteCode"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrganizationInvitationFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrganizationInvitation"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]} as unknown as DocumentNode<CreateOrganizationInvitationMutation, CreateOrganizationInvitationMutationVariables>;