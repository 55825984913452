import {
  Dialog,
  DialogActions,
  DialogContent,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Box, List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Organization,
  OrganizationMembership,
  OrganizationMembershipRole,
  Visitor,
} from "../api/graphql";
import { DeleteVisitorDocument } from "../DeleteVisitor.generated";
import useApolloErrorHandler from "../lib/handleApolloError";
import { PrimaryButton, SecondaryButton } from "../lib/ModalButtons";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  visitor: Pick<Visitor, "id"> & {
    organizationMemberships: (Pick<OrganizationMembership, "id" | "role"> & {
      organization: Pick<Organization, "name"> & {
        memberships: Pick<OrganizationMembership, "id" | "role">[];
      };
    })[];
  };
};

export default function DeleteVisitorDialog({
  isOpen,
  onClose,
  visitor,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();
  const onlyAdminOfOrg = visitor.organizationMemberships.find(
    (m) =>
      // they are an admin in an org
      (m.role === OrganizationMembershipRole.Admin &&
        // the org is just them
        m.organization.memberships.length === 1) ||
      // or the org is more than just them but has no other active admins
      (m.organization.memberships.length > 1 &&
        !m.organization.memberships.some(
          (mem) =>
            mem.id !== m.id && mem.role === OrganizationMembershipRole.Admin
        ))
  );

  const [deleteVisitor, { loading }] = useMutation(DeleteVisitorDocument, {
    onError: (e) => handleApolloError(e),
    onCompleted: () => {
      navigate("/toolbox/visitors");
      snackbarContext.alert(
        "info",
        t("This visitor's account has been deleted")
      );
    },
  });

  if (!isOpen) return null;

  return (
    <Dialog title={t("Delete account")} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" color="text.primary">
          {t(
            "Before proceeding, please ensure you have verified the following:"
          )}
        </Typography>
        <Box my={2}>
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              {t(
                "The request has come directly from the account owner themselves"
              )}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              {t(
                "The account owner is unable to perform this action themselves"
              )}
            </ListItem>
            {!!onlyAdminOfOrg && (
              <ListItem sx={{ display: "list-item" }}>
                {t(
                  "The account owner is aware they are the only administrator of their organization, {{orgName}}. Deleting their account means any other memberships in the organization will be deactivated and the organization itself disabled.",
                  { orgName: onlyAdminOfOrg.organization.name }
                )}
              </ListItem>
            )}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          color="error"
          onClick={() => {
            deleteVisitor({
              variables: {
                input: { visitorId: visitor.id },
              },
            });
          }}
          disabled={loading}
        >
          {t("Delete account")}
        </SecondaryButton>
        <PrimaryButton onClick={onClose} disabled={loading}>
          {t("Cancel")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
