import { Accordion, Avatar, Media } from "@ameelio/ui";
import { GroupsOutlined } from "@mui/icons-material";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Inmate, Meeting, Visitor } from "../api/graphql";
import { useCurrentCorrespondent } from "../SessionBoundary";

type Props = {
  meeting: Pick<Meeting, "id"> & {
    correspondents: Pick<
      Visitor | Inmate,
      "__typename" | "id" | "firstName" | "lastName" | "fullName"
    >[];
  };
  onChange: (expanded: boolean) => void;
  textColor?: { primary?: string; secondary?: string };
  sx?: SxProps<Theme>;
  expandIconSx?: SxProps<Theme>;
};

export default function MeetingParticipantsAccordion({
  meeting,
  onChange,
  textColor,
  sx,
  expandIconSx,
}: Props) {
  const { t } = useTranslation();
  const currentUser = useCurrentCorrespondent();
  const hosts = meeting.correspondents.filter(
    (c) => c.__typename === "Visitor"
  );
  const students = meeting.correspondents.filter(
    (c) => c.__typename === "Inmate"
  );
  const attendees = [...hosts, ...students];
  return (
    <Accordion
      title={t("Attendees")}
      titleImage={<GroupsOutlined />}
      numItems={meeting.correspondents.length}
      textColor={textColor}
      expandIconSx={expandIconSx}
      sx={sx}
      onChange={onChange}
      details={
        <>
          {attendees.map((c) => (
            <Box key={c.id} p={1} pl={5}>
              <Media image={<Avatar contact={c} />}>
                <Typography
                  variant="subtitle2"
                  color={textColor?.primary || "text.primary"}
                >
                  {currentUser.fullName} {currentUser.id === c.id && t("(you)")}
                  {currentUser.id !== c.id &&
                    c.__typename === "Visitor" &&
                    t("(co-host)")}
                </Typography>
              </Media>
            </Box>
          ))}
        </>
      }
    />
  );
}
