import { Button, SubmitButton, TextInput } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Rating as StarRating,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { MeetingType } from "../api/graphql";
import getFrom from "../lib/getFrom";
import useApolloErrorHandler from "../lib/handleApolloError";
import Link from "../lib/Link";
import {
  DroppedStreams,
  useStreamTrackerContext,
} from "../lib/StreamTrackerProvider";
import { ScreenTitle } from "../lib/typography";
import { useCurrentCorrespondent } from "../SessionBoundary";
import CallEnded from "./CallEnded.svg";
import { CreateCallReportDocument } from "./CreateCallReport.generated";
import { GetMeetingInfoQuery } from "./GetMeetingInfo.generated";

type Props = {
  meeting: GetMeetingInfoQuery["meeting"];
  title: string;
  collectRating: boolean;
  terminated: boolean;
  connectedElsewhere: boolean;
  rejoinCall?: () => void;
  allowTextFeedback?: boolean;
};

export default function Rating({
  meeting,
  title,
  collectRating,
  terminated,
  connectedElsewhere,
  rejoinCall,
  allowTextFeedback,
}: Props) {
  const location = useLocation();
  const from = getFrom(location) || "/";
  const streamTrackerContext = useStreamTrackerContext();
  const [rating, setRating] = useState<number | null>(null);
  const [feedbackCode, setFeedbackCode] = useState<string | undefined>(
    undefined
  );
  const [isRatingSubmitted, setRatingSubmitted] = useState(false);

  const handleApolloError = useApolloErrorHandler();

  const [rateCall, { loading: rateCallSubmitting }] = useMutation(
    CreateCallReportDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: () => setRatingSubmitted(true),
    }
  );
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();

  const {
    handleSubmit: handleFormSubmit,
    formState: { isSubmitting },
    getValues,
    control,
  } = useForm<{ rating: string; feedback: string; comment: string }>();

  const badCallDescription = useMemo(() => {
    switch (streamTrackerContext.droppedStreamsType) {
      case DroppedStreams.local:
        return t(
          "We’re keeping track of this information and will share it with {{facilityName}} so they can improve their connection for future calls.",
          {
            facilityName: meeting.facility.name,
          }
        );
      case DroppedStreams.peer:
        return t(
          "We’ve shared some tips with {{peerFirstName}} for improving their connection for your next call.",
          {
            peerFirstName: meeting.correspondents.filter(
              (c) => c.id !== user.id
            )[0].firstName,
          }
        );
      case DroppedStreams.localAndPeer:
        return t(
          "We’re keeping track of this information and will share it with {{facilityName}} so they can improve their connection for future calls.",
          {
            facilityName: meeting.facility.name,
          }
        );
      default:
        return "";
    }
  }, [streamTrackerContext, meeting, user, t]);

  const isVoiceCall = meeting.meetingType === MeetingType.VoiceCall;

  const VIDEO_CALL_FEEDBACK_OPTIONS: { code: string; feedback: string }[] = [
    { code: "AudioWasLow", feedback: t("Audio was low") },
    { code: "TheyCantSee", feedback: t("They couldn't see and/or hear me") },
    { code: "ICantSeeHear", feedback: t("I couldn't see or hear them") },
    { code: "Freezing", feedback: t("Call was freezing a lot") },
    { code: "Other", feedback: t("Other") },
  ];

  const VOICE_CALL_FEEDBACK_OPTIONS: { code: string; feedback: string }[] = [
    { code: "AudioWasLow", feedback: t("Audio was low") },
    { code: "MicNotWorking", feedback: t("My mic wasn't working") },
    { code: "CouldNotHear", feedback: t("I couldn't hear them") },
    { code: "CouldNotConnect", feedback: t("Call did not connect") },
    { code: "Other", feedback: t("Other") },
  ];

  const doSubmit = () => {
    if (!rating) return;
    rateCall({
      variables: {
        input: {
          meetingId: meeting.id,
          rating,
          description: feedbackCode,
          comment: getValues("comment"),
        },
      },
    });
  };
  const isNegativeRating = rating && rating <= 2;
  const hasDroppedStreams =
    // Not all parents of this component render it as a child
    // of StreamTrackerProvider, so `streamTrackerContext`
    // can be an empty object. In this case we should not interpret
    // non-captured dropped streams
    streamTrackerContext.droppedStreamsType !== undefined &&
    streamTrackerContext.droppedStreamsType !== DroppedStreams.none;

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        minHeight: 1,
        alignItems: "center",
        justifyContent: "center",
        pb: 3,
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={CallEnded} alt="" width="304" style={{ marginTop: 40 }} />
        <ScreenTitle sx={{ textAlign: "center", mt: 3 }}>{title}</ScreenTitle>
        <Box
          sx={{
            textAlign: "center",
            marginTop: 1,
            overflow: "auto",
            maxWidth: 0.9,
          }}
        >
          {connectedElsewhere ? (
            <Typography variant="body1">
              {t(
                "You were disconnected because you joined the call from another device. Rejoin the call if you'd like to use this device."
              )}
            </Typography>
          ) : (
            <>
              {terminated && (
                <Typography variant="body1">
                  {t("Your call was terminated by the staff monitor.")}{" "}
                </Typography>
              )}
              {hasDroppedStreams && (
                <Typography variant="body1" mt={1}>
                  {t("Sorry about the bad connection.")} {badCallDescription}
                </Typography>
              )}
            </>
          )}
        </Box>

        {collectRating && (
          <Card
            sx={{ padding: 3, marginTop: 2, overflow: "auto", maxWidth: 0.9 }}
            variant="outlined"
          >
            {isRatingSubmitted ? (
              <>{t("Thanks for your response.")}</>
            ) : (
              <form onSubmit={handleFormSubmit(doSubmit)}>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    maxWidth: 0.9,
                  }}
                >
                  <Typography variant="body1">
                    {isVoiceCall
                      ? t("How was the audio quality of your call?")
                      : t("How was the audio and video quality of your call?")}
                  </Typography>
                  <StarRating
                    value={rating}
                    onChange={(_, val) => {
                      setRating(val);
                    }}
                    sx={{ marginTop: 1 }}
                  />
                  {isNegativeRating && (
                    <>
                      <Typography variant="body1" sx={{ marginTop: 3 }}>
                        {t("What could have gone better?")}
                      </Typography>
                      <RadioGroup
                        value={feedbackCode}
                        onChange={(_, value) => setFeedbackCode(value)}
                        sx={{ mt: 2 }}
                      >
                        {(isVoiceCall
                          ? VOICE_CALL_FEEDBACK_OPTIONS
                          : VIDEO_CALL_FEEDBACK_OPTIONS
                        ).map(({ code, feedback: label }) => (
                          <FormControlLabel
                            key={code}
                            value={code}
                            control={<Radio />}
                            label={label}
                            sx={{ textAlign: "start" }}
                          />
                        ))}
                      </RadioGroup>
                      {allowTextFeedback && feedbackCode === "Other" && (
                        <TextInput
                          name="comment"
                          control={control}
                          multiline
                          minRows={3}
                          maxRows={6}
                          fullWidth
                          label={t("Feedback (optional)")}
                          margin="normal"
                        />
                      )}
                    </>
                  )}
                  {rating && (
                    <SubmitButton
                      submitting={isSubmitting || rateCallSubmitting}
                      variant="outlined"
                      sx={{ marginTop: 3, width: 1 }}
                    >
                      {t("Submit")}
                    </SubmitButton>
                  )}
                </Box>
              </form>
            )}
          </Card>
        )}
        <Stack sx={{ marginTop: 6 }} spacing={2}>
          {rejoinCall && (
            <Button onClick={rejoinCall} variant="contained">
              {t("Rejoin call")}
            </Button>
          )}
          {isVoiceCall ? (
            <Link to={from} button variant="outlined">
              {t("Dismiss")}
            </Link>
          ) : (
            <Link to="/" button variant="outlined">
              {t("Return Home")}
            </Link>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
