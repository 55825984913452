import { useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Organization } from "../api/graphql";
import { AcceptOrganizationInvitationDocument } from "../Authentication/AcceptOrganizationInvitation.generated";
import useApolloErrorHandler from "./handleApolloError";

type Props = {
  organization: Pick<Organization, "name">;
  inviteCode: string;
};

export default function PendingOrganizationInvitation({
  organization,
  inviteCode,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();
  const [acceptInvitation] = useMutation(AcceptOrganizationInvitationDocument, {
    onError,
  });
  return (
    <Alert
      severity="warning"
      variant="outlined"
      sx={{ mt: 1, alignItems: "flex-start", backgroundColor: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <AlertTitle>{t("Invitation to join an organization")}</AlertTitle>
        <Typography variant="body2">
          {t("{{organizationName}} has invited you to join.", {
            organizationName: organization.name,
          })}
        </Typography>
        <Box sx={{ flexDirection: "row", mt: 3 }}>
          <Button
            variant="outlined"
            onClick={async () => {
              const result = await acceptInvitation({
                variables: { input: { inviteCode } },
              });
              if (result.data?.acceptOrganizationInvitation) {
                snackbarContext.alert(
                  "success",
                  t("You have been added to {{organizationName}}", {
                    organizationName: organization.name,
                  })
                );
                navigate("/", { replace: true });
              }
            }}
          >
            {t("Accept invitation")}
          </Button>
        </Box>
      </Box>
    </Alert>
  );
}
