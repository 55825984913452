import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeactivateOrganizationMembershipMutationVariables = Types.Exact<{
  input: Types.DeactivateOrganizationMembershipInput;
}>;


export type DeactivateOrganizationMembershipMutation = { __typename: 'Mutation', deactivateOrganizationMembership: { __typename: 'DeactivateOrganizationMembershipResponse', organizationMembership: { __typename: 'OrganizationMembership', id: Types.Scalars['ID']['input'], role: Types.OrganizationMembershipRole, status: Types.OrganizationMembershipStatus } } };


export const DeactivateOrganizationMembershipDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeactivateOrganizationMembership"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeactivateOrganizationMembershipInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deactivateOrganizationMembership"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationMembership"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationMembershipFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrganizationMembershipFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrganizationMembership"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]} as unknown as DocumentNode<DeactivateOrganizationMembershipMutation, DeactivateOrganizationMembershipMutationVariables>;