import { Button } from "@ameelio/ui";
import { SxProps, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import useBoolean from "../lib/useBoolean";
import InviteMemberDialog from "./InviteMemberDialog";

type Props = {
  isAdmin: boolean;
  organizationId: string;
  sx?: SxProps<Theme>;
};

export default function InviteTeamMemberButton({
  isAdmin,
  organizationId,
  sx,
}: Props) {
  const { t } = useTranslation();
  const dialog = useBoolean();

  return (
    <>
      {dialog.current && (
        <InviteMemberDialog
          organizationId={organizationId}
          onClose={dialog.disable}
        />
      )}
      <Button
        disabled={!isAdmin}
        disabledReason={t("Only organization admins may invite members")}
        variant="outlined"
        sx={sx}
        onClick={dialog.enable}
      >
        {t("Invite team member")}
      </Button>
    </>
  );
}
