import { InlineBadge } from "@ameelio/ui";
import {
  DomainOutlined,
  HistoryOutlined,
  HomeOutlined,
  Troubleshoot,
} from "@mui/icons-material";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ConnectionStatus,
  Organization,
  OrganizationMembershipStatus,
} from "../api/graphql";
import { GetCurrentUserQuery } from "../GetCurrentUser.generated";
import Link from "../lib/Link";
import messagingEnabled from "../lib/messagingEnabled";
import OrganizationMembershipLogo from "../lib/OrganizationMembershipLogo";
import TruncatedTypography from "../lib/TruncatedTypography";
import { useMessageBadges } from "../Messages/MessageBadgeProvider";
import { useCurrentCorrespondent, useCurrentStaff } from "../SessionBoundary";

const MenuTabs = [
  "none",
  "home",
  "events",
  "messages",
  "contacts",
  "organization",
  "settings",
  "organizations-tools",
  "systems-tools",
  "visitors-tools",
  "support",
  "debug-calls",
] as const;
export type MenuTab = (typeof MenuTabs)[number];

export function MenuLink({
  to,
  icon,
  onClick,
  label,
  current,
  disabled,
  variant,
  badgeContent,
  state,
}: {
  to: string;
  onClick?: () => void;
  icon: JSX.Element;
  label: string;
  current?: boolean;
  disabled?: boolean;
  variant?: "outlined" | "contained";
  badgeContent?: number;
  state?: Record<string, unknown>;
}) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      to={disabled ? "" : to}
      onClick={onClick}
      variant="subtitle2"
      state={state}
      underline="hover"
      sx={{
        display: "flex",
        color: disabled
          ? "rgba(0 0 0 / .38)"
          : current
            ? undefined
            : "text.primary",
        backgroundColor: current
          ? (theme) => `${theme.palette.primary.main}14`
          : undefined,
        cursor: disabled ? "default" : undefined,
        border: variant === "outlined" ? 1 : undefined,
        borderRadius: 2,
        paddingY: 1.5,
        paddingLeft: 3,
        paddingRight: 1,
        alignItems: "center",
      }}
    >
      {icon}
      <Box component="span" ml={2}>
        {label}
      </Box>
      <InlineBadge
        color="primary"
        badgeContent={badgeContent}
        sx={{ marginLeft: "auto" }}
      />
    </Link>
  );
}

export function MessagesMenuLink(props: Parameters<typeof MenuLink>[0]) {
  const { totalUnread } = useMessageBadges();
  return <MenuLink {...{ ...props, badgeContent: totalUnread }} />;
}

export function UserAccountDetails({
  user,
  membership,
}: {
  user: GetCurrentUserQuery["me"];
  membership?: {
    organization: Pick<Organization, "id" | "logoImageUrl" | "name">;
  };
}) {
  if (
    user?.__typename !== "Visitor" &&
    user?.__typename !== "Staff" &&
    user?.__typename !== "Inmate"
  )
    return <div />;
  return (
    <Box display="flex">
      <OrganizationMembershipLogo membership={membership} user={user} />
      <Box ml={2} flexGrow={1} minWidth={0}>
        <TruncatedTypography variant="h3" color="text.primary" component="div">
          {user.firstName}
        </TruncatedTypography>
        {user.__typename === "Inmate" && (
          <TruncatedTypography variant="body2" color="text.secondary">
            {user.facility.name}
          </TruncatedTypography>
        )}
        {membership && (
          <TruncatedTypography variant="body2" color="text.secondary">
            {membership.organization.name}
          </TruncatedTypography>
        )}
      </Box>
    </Box>
  );
}

export type MenuProps = {
  currentTab: MenuTab;
  onClick?: () => void;
};

export function CorrespondentMenu({ currentTab, onClick }: MenuProps) {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const membership =
    user.__typename === "Visitor"
      ? user.organizationMemberships.find(
          (m) => m.status === OrganizationMembershipStatus.Active
        )
      : undefined;

  return (
    <>
      <Box margin={3}>
        {user.__typename === "Inmate" ? (
          <UserAccountDetails user={user} />
        ) : (
          <UserAccountDetails user={user} membership={membership} />
        )}
      </Box>
      <Divider />
      <Stack spacing={2} margin={3} component="nav">
        <MenuLink
          current={currentTab === "home"}
          to="/home"
          icon={<HomeOutlined />}
          label={t("Home")}
          onClick={onClick}
        />
        <MenuLink
          current={currentTab === "events"}
          to="/events"
          icon={<CalendarMonthIcon />}
          label={t("Events")}
          onClick={onClick}
        />
        {messagingEnabled(user) ? (
          <MessagesMenuLink
            current={currentTab === "messages"}
            to="/messages"
            icon={
              messagingEnabled(user) ? (
                <MessageOutlinedIcon />
              ) : (
                <HistoryOutlined />
              )
            }
            label={t("Messages")}
            onClick={onClick}
          />
        ) : null}
        <MenuLink
          current={currentTab === "contacts"}
          to="/contacts"
          icon={<PeopleOutlineOutlinedIcon />}
          label={t("Contacts")}
          badgeContent={
            // Alerting visitor to the number of rejected connection
            // requests requiring attention
            (user.__typename === "Visitor" &&
              user.connections.filter(
                (c) => c.status === ConnectionStatus.Rejected
              )?.length) ||
            undefined
          }
          onClick={onClick}
        />
        {membership && (
          <MenuLink
            current={currentTab === "organization"}
            to={`/organization/${membership?.organization.id}/team`}
            icon={<BusinessOutlinedIcon />}
            label={t("Organization")}
            onClick={onClick}
          />
        )}
      </Stack>
      <Divider />
      <Stack spacing={2} margin={3} component="nav">
        {user.__typename === "Visitor" && (
          <MenuLink
            to="/support"
            icon={<HelpOutlinedIcon sx={{ color: "text.primary" }} />}
            label={t("Support")}
            state={{ backTo: "/", parentName: t("Home") }}
          />
        )}
        <MenuLink
          current={currentTab === "settings"}
          to="/settings"
          icon={<SettingsOutlinedIcon />}
          label={t("Settings")}
          onClick={onClick}
        />
        <MenuLink
          to="/logout"
          icon={<LogoutOutlinedIcon />}
          label={t("Log out")}
        />
      </Stack>
    </>
  );
}

export function ToolboxMenu({ currentTab, onClick }: MenuProps) {
  const { t } = useTranslation();
  const user = useCurrentStaff();
  return (
    <>
      <Box margin={3}>
        <UserAccountDetails user={user} />
      </Box>
      <Divider />
      <Stack spacing={2} margin={3} component="nav">
        <MenuLink
          current={currentTab === "systems-tools"}
          to="/toolbox/systems"
          icon={<DomainOutlined />}
          label={t("Systems")}
          onClick={onClick}
        />
        <MenuLink
          current={currentTab === "organizations-tools"}
          to="/toolbox/organizations"
          icon={<PeopleOutlineOutlinedIcon />}
          label={t("Organizations")}
          onClick={onClick}
        />
        <MenuLink
          current={currentTab === "visitors-tools"}
          to="/toolbox/visitors"
          icon={<PersonOutlineIcon />}
          label={t("Visitors")}
          onClick={onClick}
        />
        <MenuLink
          current={currentTab === "debug-calls"}
          to="/toolbox/debug/calls"
          icon={<Troubleshoot />}
          label={t("Debug calls")}
          onClick={onClick}
        />
        <MenuLink
          to="/logout"
          icon={<LogoutOutlinedIcon />}
          label={t("Log out")}
        />
      </Stack>
    </>
  );
}
