import { Button } from "@ameelio/ui";
import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import addStylesForDevices from "../../lib/addStylesForDevices";
import { onlyMobile } from "../../lib/responsiveHelpers";
import withController from "../../lib/withController";
import { ltrTheme } from "../../theme";
import ImageCapture from "./ImageCapture";

type Props = {
  id: "imageOne" | "imageTwo" | "imageThree";
  title: string;
  description: string;
  value?: File;
  onChange: (file: File | undefined) => void;
  error?: boolean;
  placeholderImage: {
    src: string;
    alt: string;
  };
  sx?: SxProps<Theme>;
};

export function UploadImageInputBase({
  id,
  title,
  description,
  value,
  onChange,
  error,
  placeholderImage,
  sx,
}: Props) {
  const { t } = useTranslation();
  const isMobile = onlyMobile(measureScreenWidth);
  const [isCapturing, setIsCapturing] = useState<boolean>(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".heic"],
    },
    maxFiles: 1,
    onDropAccepted: ([file]) => onChange(file),
  });

  const [previewUrl, setPreviewUrl] = useState<string>();
  useEffect(() => {
    if (!value) return setPreviewUrl(undefined);
    const url = URL.createObjectURL(value);
    setPreviewUrl(url);
    return () => URL.revokeObjectURL(url);
  }, [value]);

  return (
    <>
      {isCapturing && (
        <ImageCapture
          title={title}
          description={
            // The "selfie" description is slightly different
            id === "imageThree"
              ? t(
                  "Hold the document near your face. Make sure your face is fully visible (without glasses or face coverings) and that you are wearing appropriate clothing (shirt with sleeves). Make sure there is nothing inappropriate in the background."
                )
              : t(
                  "Hold your document up to the camera so that it fills the frame as much as possible. After ensuring all of the important details are easy to read, take the picture using the capture button below."
                )
          }
          onCancel={() => setIsCapturing(false)}
          onConfirm={(file) => {
            onChange(file);
            setIsCapturing(false);
          }}
        />
      )}
      <Box
        sx={{
          color: error ? "error.main" : undefined,
          borderBottom: 1,
          borderColor: ltrTheme.palette.divider,
          py: 2,
          display: "flex",
          flexDirection: "row",
          flex: "1 0 0",
          gap: 1.5,
          alignItems: "center",
          ...(sx || {}),
        }}
      >
        <Box
          sx={addStylesForDevices(
            isMobile,
            {},
            {
              display: "none",
            }
          )}
        >
          <img
            src={placeholderImage.src}
            alt={placeholderImage.alt}
            width={100}
          />
        </Box>
        <Stack flex="1 0 0">
          <Typography variant="h3" component="label" color="text.primary">
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </Stack>
        {value ? (
          <Box
            display="flex"
            gap={1.5}
            justifyContent="center"
            alignItems="center"
            sx={{ flexDirection: { xs: "column", lg: "row" } }}
          >
            <img
              src={previewUrl}
              alt={t("selected file preview")}
              style={{
                width: "auto",
                height: "100px",
                maxWidth: "100%",
                borderRadius: "8px",
                objectFit: "contain",
              }}
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => onChange(undefined)}
            >
              {t("Remove")}
            </Button>
          </Box>
        ) : (
          <Box
            display="flex"
            gap={1.5}
            justifyContent="center"
            alignItems="center"
            sx={{ flexDirection: { xs: "column", lg: "row" } }}
          >
            <Button
              size="medium"
              variant="outlined"
              {...(!value ? getRootProps() : {})}
              role="button"
            >
              {t("Select photo")}
              <input {...getInputProps()} aria-hidden />
            </Button>
            <Button
              size="medium"
              variant="outlined"
              onClick={() => setIsCapturing(true)}
            >
              {t("Take photo")}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

export default withController(UploadImageInputBase);
