import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";

interface ImagePreviewProps {
  file: File;
  onClose?: () => void;
}

export default function ImageUploadPreview({
  file,
  onClose,
}: ImagePreviewProps) {
  const imageUrl = URL.createObjectURL(file);
  return (
    <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
      <img
        src={imageUrl}
        alt="preview"
        style={{ maxWidth: "200px", maxHeight: "200px", height: "auto" }}
      />
      {onClose && (
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: 0,
            ml: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}
