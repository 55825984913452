import { Trans, useTranslation } from "react-i18next";
import getEnumKeyByEnumValue from "../lib/EnumUtils";
import USState from "../lib/USState.enum";
import STATE_TO_INMATE_DB from "./stateLookupResources";

type Props = {
  defaultMessage: string;
  province: string;
  params?: object;
};

export default function ConnectionsFlowWarning({
  defaultMessage,
  province,
  params = {},
}: Props) {
  const { t } = useTranslation();

  const USStateKey = getEnumKeyByEnumValue(USState, province);
  if (!USStateKey) return null;

  return (
    <Trans
      t={t}
      defaults={defaultMessage}
      components={{
        resourceLink: (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
          <a
            href={STATE_TO_INMATE_DB[USStateKey].link}
            target="_blank"
            rel="noreferrer"
          />
        ),
      }}
      values={{
        resourceName: STATE_TO_INMATE_DB[USStateKey].name,
        stateName: province,
        ...params,
      }}
    />
  );
}
