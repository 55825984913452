import { TextInput } from "@ameelio/ui";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { ScreenTitle } from "../lib/typography";

import {
  HiddenRelationship,
  PersonalRelationship,
  ProfessionalRelationship,
} from "../lib/Relationship";
import RelationshipInput from "../lib/RelationshipInput";
import { hasMaxLength, isRequiredString, mergeRules } from "../lib/validate";
import { RelationshipFormCriteria } from "./types";

type Props = {
  stepper: StepperType;
  relationship?: string;
  onSubmit: (data: RelationshipFormCriteria) => void | Promise<void>;
};

export default function RelationshipForm({
  stepper,
  relationship,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const isStandardRelationship =
    relationship &&
    (Object.values(PersonalRelationship).includes(
      relationship as PersonalRelationship
    ) ||
      Object.values(ProfessionalRelationship).includes(
        relationship as ProfessionalRelationship
      ) ||
      Object.values(HiddenRelationship).includes(
        relationship as HiddenRelationship
      ));
  const isOtherRelationship = relationship && !isStandardRelationship;

  const {
    control,
    handleSubmit: handleFormSubmit,
    watch,
    getValues,
    formState: { isSubmitting, isValid },
  } = useForm<RelationshipFormCriteria>({
    mode: "onChange",
    defaultValues: {
      relationship:
        relationship && isStandardRelationship
          ? relationship
          : relationship
            ? PersonalRelationship.Other
            : "",
      relationshipDetails: isOtherRelationship ? relationship : "",
    },
  });

  const selectedRelationship = watch("relationship") || "";
  const relationshipDetailsValue = watch("relationshipDetails") || "";

  return (
    <StepperFormWrapper
      stepper={stepper({
        loading: isSubmitting,
        disabled:
          selectedRelationship === PersonalRelationship.Other
            ? !(relationshipDetailsValue || "").trim() || !isValid
            : !selectedRelationship,
      })}
      handleSubmit={handleFormSubmit(() =>
        onSubmit({
          relationship: getValues("relationship"),
          relationshipDetails: getValues("relationshipDetails"),
        })
      )}
    >
      <ScreenTitle>{t("How are you related?")}</ScreenTitle>
      <Stack marginTop={3} marginBottom={6} spacing={3}>
        <RelationshipInput control={control} />
        {selectedRelationship === PersonalRelationship.Other && (
          <TextInput
            control={control}
            name="relationshipDetails"
            label={t("Relationship type")}
            rules={mergeRules(
              isRequiredString(t("Please enter your relationship type.")),
              hasMaxLength(
                30,
                t(
                  "Relationship type can't be longer than {{number}} characters.",
                  { number: 30 }
                )
              )
            )}
          />
        )}
      </Stack>
    </StepperFormWrapper>
  );
}
