import { Box, Typography } from "@mui/material";
import { ScreenTitle } from "../../lib/typography";

type ScreenHeaderProps = {
  title: string;
  description: string;
};

export default function ScreenHeader({
  title,
  description,
}: ScreenHeaderProps) {
  return (
    <>
      <ScreenTitle>{title}</ScreenTitle>
      <Box mt={1}>
        <Typography variant="body1" color="text.secondary">
          {description}
        </Typography>
      </Box>
    </>
  );
}
