import { ComboInputBase } from "@ameelio/ui";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MINOR_AGE_YEARS_CUTOFF } from "../../lib/isMinor";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { ScreenTitle } from "../../lib/typography";
import { useCurrentVisitor } from "../../SessionBoundary";
import { unaccompaniedVisitAllowed } from "../utils";
import { InmateConnection, SelectConnectionData } from "./types";

type Props = {
  connections: InmateConnection[];
  stepper: StepperType;
  defaultValues: SelectConnectionData;
  onSubmit: (data: SelectConnectionData) => void;
};

export default function SelectConnectionStep({
  connections,
  stepper,
  defaultValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const currentVisitor = useCurrentVisitor();
  const [connection, setConnection] = useState<InmateConnection | null>(
    defaultValues.connections?.find(
      (c) => c.visitor.id === currentVisitor.id
    ) || null
  );

  const facility = connection?.inmate.facility;
  // We disable continuing if the user
  // is too young to schedule with a particular connection
  const oldEnoughToSchedule = connection
    ? unaccompaniedVisitAllowed(currentVisitor, connection.id)
    : true;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!connection) return;
    onSubmit({ connections: [connection] });
  };

  return (
    <StepperFormWrapper
      stepper={stepper({
        disabled: !connection || !oldEnoughToSchedule,
        loading: false,
      })}
      handleSubmit={handleSubmit}
    >
      <ScreenTitle>{t("Who would you like to meet with?")}</ScreenTitle>
      <Typography variant="body1" marginTop={2}>
        {t(
          "You can meet with people after you have been approved as a contact."
        )}
      </Typography>

      <Box marginY={6}>
        <ComboInputBase
          label={t("Person")}
          loadingText={
            <Box width={1} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          autoComplete={false}
          options={connections}
          value={connection}
          onChange={(c) => setConnection(c)}
          helperText={
            oldEnoughToSchedule
              ? t(
                  "If you can't find who you're looking for, try adding them as a contact"
                )
              : t(
                  "{{facilityName}} requires visitors to be at least {{count}} years old to schedule events with {{firstName}}",
                  {
                    facilityName: facility?.name || t("This facility"),
                    count:
                      facility?.unaccompaniedVisitAge || MINOR_AGE_YEARS_CUTOFF,
                    firstName:
                      connection?.inmate.firstName || t("your contact"),
                  }
                )
          }
          getOptionLabel={(c) => c.inmate.fullName}
          error={!oldEnoughToSchedule}
        />
      </Box>
    </StepperFormWrapper>
  );
}
