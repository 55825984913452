import { SelectInput } from "@ameelio/ui";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RelationshipFormCriteria } from "../ConnectionsFlow/types";
import {
  ConnectionRelationship,
  PersonalRelationship,
  ProfessionalRelationship,
  relationshipToHumanReadable,
} from "./Relationship";
import { isRequired } from "./validate";

export type SelectProps = {
  control: Control<RelationshipFormCriteria>;
};
function RelationshipInput({ control }: SelectProps) {
  const { t } = useTranslation();
  return (
    <SelectInput
      control={control}
      name="relationship"
      label={t("I am their...")}
      displayEmpty
      rules={isRequired(t("Please select a relationship type."))}
      renderValue={(v) =>
        relationshipToHumanReadable(v as ConnectionRelationship)
      }
      items={[
        ...Object.values(PersonalRelationship)
          .filter((cr) => cr !== PersonalRelationship.Other)
          .map((cr) => ({
            value: cr,
            name: relationshipToHumanReadable(cr),
          })),
        { type: "divider" },
        ...Object.values(ProfessionalRelationship)
          .filter((cr) => cr !== ProfessionalRelationship.Other)
          .map((cr) => ({
            value: cr,
            name: relationshipToHumanReadable(cr),
          })),
        { type: "divider" },
        {
          value: PersonalRelationship.Other,
          name: relationshipToHumanReadable(PersonalRelationship.Other),
        },
      ]}
    />
  );
}

export default RelationshipInput;
