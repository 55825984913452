import { SxProps, Theme } from "@mui/material";

export default function withSx<ComponentProps extends { sx?: SxProps<Theme> }>(
  Component: React.ComponentType<ComponentProps>,
  sxProps: SxProps<Theme>
) {
  function Wrapper(props: ComponentProps) {
    return (
      <Component
        // coercion is necessary because of some gap in typescript
        // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(props as any)}
        // eslint-disable-next-line react/destructuring-assignment
        sx={{ ...sxProps, ...(props.sx || {}) }}
      />
    );
  }
  Wrapper.displayName = `sx(${Component.displayName})`;
  return Wrapper;
}
