import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetOrganizationInvitationCodeQueryVariables = Types.Exact<{
  organizationInvitationId: Types.Scalars['ID']['input'];
}>;


export type GetOrganizationInvitationCodeQuery = { __typename: 'Query', organizationInvitation: { __typename: 'OrganizationInvitation', inviteCode: string, id: Types.Scalars['ID']['input'], email: string, role: Types.OrganizationMembershipRole } };


export const GetOrganizationInvitationCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOrganizationInvitationCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationInvitationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationInvitationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationInvitationFragment"}},{"kind":"Field","name":{"kind":"Name","value":"inviteCode"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrganizationInvitationFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrganizationInvitation"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]} as unknown as DocumentNode<GetOrganizationInvitationCodeQuery, GetOrganizationInvitationCodeQueryVariables>;