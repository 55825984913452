import * as Sentry from "@sentry/react";
import * as bodySegmentation from "@tensorflow-models/body-segmentation";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const Statuses = ["loading", "loaded", "failed"] as const;
type Status = (typeof Statuses)[number];

export type SegmenterContextType = {
  segmenter?: bodySegmentation.BodySegmenter;
  load: () => void;
  status: Status;
};

export const SegmenterContext = createContext<SegmenterContextType>(
  {} as SegmenterContextType
);

export function useSegmenterContext() {
  return useContext(SegmenterContext);
}

export default function SegmenterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Initialize body segmenter
  const [segmenter, setSegmenter] = useState<bodySegmentation.BodySegmenter>();
  const [status, setStatus] = useState<Status>("loading");
  const unloadTimeout = useRef<ReturnType<typeof setTimeout>>();

  const load = useCallback(() => {
    if (segmenter || unloadTimeout.current) return;

    const timeout = setTimeout(() => {
      const model =
        bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation;
      bodySegmentation
        .createSegmenter(model, {
          runtime: "mediapipe",
          solutionPath:
            "https://static.ameelio.org/modules/@mediapipe/selfie_segmentation/",
          modelType: "general",
        })
        .then(
          (s) => {
            setSegmenter(s);
            setStatus("loaded");
          },
          () => {
            setStatus("failed");
            Sentry.captureMessage(
              "Could not load @mediapipe/selfie_segmentation",
              "error"
            );
          }
        );
    }, 1000);

    unloadTimeout.current = timeout;
  }, [segmenter]);

  useEffect(
    () => () => {
      if (unloadTimeout.current) {
        clearTimeout(unloadTimeout.current);
      }
    },
    []
  );

  const segmenterContext = useMemo(
    () => ({ segmenter, load, status }),
    [segmenter, load, status]
  );

  return (
    <SegmenterContext.Provider value={segmenterContext}>
      {children}
    </SegmenterContext.Provider>
  );
}
