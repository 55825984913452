/* eslint-disable jsx-a11y/media-has-caption */
import { AudioHTMLAttributes, useEffect, useRef } from "react";

type PropsType = AudioHTMLAttributes<HTMLAudioElement> & {
  srcObject?: MediaStream;
};

export default function ParticipantAudio({ srcObject, ...props }: PropsType) {
  const refAudio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!refAudio.current) return;
    refAudio.current.srcObject = srcObject as MediaProvider;
  }, [srcObject]);

  return <audio ref={refAudio} {...props} />;
}
