import { useEffect, useState } from "react";
import { microElapsedTimeString } from "./timeFormats";

type Props = {
  timestamp: number | Date;
};

export default function MicroElapsedTime({ timestamp }: Props) {
  const [, refresh] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      refresh((i) => i + 1);
    }, 30 * 1000);
    return () => clearTimeout(timer);
  }, []);

  // the fragment wrapper fixes an issue with JSX element types
  return <>{microElapsedTimeString(timestamp)}</>;
}
