import { alpha, Box } from "@mui/material";
import useScrolledToBottom from "../../lib/useScrolledToBottom";
import { ltrTheme } from "../../theme";

type Props = {
  children: React.ReactNode;
};

export default function CaptionsContainer({ children }: Props) {
  const { scrolledToBottom, onScroll } = useScrolledToBottom(true);
  return (
    <Box
      onScroll={onScroll}
      sx={{
        width: `calc(100% - ${ltrTheme.spacing(4)})`,
        maxWidth: "sm",
        position: "absolute",
        left: "50%",
        overflow: "auto",
        maxHeight: "200px",
        bottom: ltrTheme.spacing(14),
        background: (th) => alpha(th.palette.common.black, 0.7),
        borderRadius: 2,
        transform: "translate(-50%)",
        p: 1,
      }}
    >
      {children}
      {scrolledToBottom && (
        <div
          ref={(node) =>
            node?.scrollIntoView({
              behavior: "auto",
              block: "start",
            })
          }
        />
      )}
    </Box>
  );
}
