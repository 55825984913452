import { useTranslation } from "react-i18next";
import EmptyScreen from "../EmptyScreen";
import Screen from "../lib/Screen";
import { useCurrentCorrespondent } from "../SessionBoundary";
import MessagesComingSoon from "./MessagesComingSoon.svg";

function NoConnectionsScreenCommon({
  contactsLink,
}: {
  contactsLink?: string;
}) {
  const { t } = useTranslation();

  const message = t(
    "Once you have approved contacts, this is where you can message with them."
  );
  const illustration = MessagesComingSoon;
  const illustrationWidth = "318";
  if (contactsLink) {
    return (
      <EmptyScreen
        message={message}
        linkProps={{
          label: t("Manage contacts"),
          to: contactsLink,
        }}
        illustration={illustration}
        illustrationWidth={illustrationWidth}
      />
    );
  }
  return (
    <EmptyScreen
      message={message}
      illustration={illustration}
      illustrationWidth={illustrationWidth}
    />
  );
}

function InmateNoConnectionsScreen() {
  return <NoConnectionsScreenCommon />;
}

function VisitorNoConnectionScreen() {
  return <NoConnectionsScreenCommon contactsLink="/contacts" />;
}

export default function NoConnectionsScreen() {
  const user = useCurrentCorrespondent();
  const { t } = useTranslation();
  return (
    <Screen title={t("Messages")}>
      {user.__typename === "Visitor" ? (
        <VisitorNoConnectionScreen />
      ) : (
        <InmateNoConnectionsScreen />
      )}
    </Screen>
  );
}
