import { useSnackbarContext } from "@ameelio/ui";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { subMilliseconds } from "date-fns";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { track, unidentify } from "./analytics";
import { UserType } from "./api/graphql";
import {
  CreateVisitorDocument,
  CreateVisitorMutationVariables,
} from "./CreateVisitor.generated";
import { ExtendSessionDocument } from "./ExtendSession.generated";
import { getToken, storeToken } from "./lib/authToken";
import useApolloErrorHandler from "./lib/handleApolloError";
import useBrowserLanguage from "./lib/useBrowserLanguage";
import { LoginDocument } from "./Login.generated";
import { LogoutDocument } from "./Logout.generated";

export type AuthContextType = {
  isLoggedIn: boolean;
  login: (
    username: string,
    password: string,
    type: UserType
  ) => Promise<boolean>;
  register: (
    input: CreateVisitorMutationVariables["input"]
  ) => Promise<boolean>;
  logout: (tokenError?: boolean) => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export function useAuthContext() {
  return useContext(AuthContext);
}

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [token, setToken] = useState<string>(() => getToken());
  const handleApolloError = useApolloErrorHandler();
  const { t } = useTranslation();
  const { adoptBrowserLanguage } = useBrowserLanguage();
  const snackbarContext = useSnackbarContext();
  const becameInactiveAtRef = useRef<number | null>();
  const checkActivityRef = useRef<NodeJS.Timer>();
  const [tokenExpiresIn, setTokenExpiresIn] = useState<number | null>();
  const [extendedSessionCount, setExtendedSessionCount] = useState<number>(0);
  const client = useApolloClient();

  // save token in the correct order. it must save to sessionStorage
  // first, before updating React state and triggering changes.
  const updateToken = useCallback((str: string) => {
    storeToken(str);
    setToken(str);
  }, []);

  // used to listen to button clicks
  // on the window and set the last active
  // timestamp
  useEffect(() => {
    const logActivityChange = () => {
      becameInactiveAtRef.current =
        window.document.visibilityState === "hidden"
          ? new Date().getTime()
          : null;
    };
    window.addEventListener("visibilitychange", logActivityChange);
    return () =>
      window.removeEventListener("visibilitychange", logActivityChange);
  }, []);

  // used to check for when the user was last
  // active and extend their session if active
  // within the the last 15 minutes
  useEffect(() => {
    // If the user is logged in and an interval to extend
    // the session isn't currently set, set one
    if (token && tokenExpiresIn && !checkActivityRef.current) {
      // Enforcing a requirement that the token must be
      // valid for at least one minute in order to set a timeout
      // to possibly extend
      checkActivityRef.current = setTimeout(
        async () => {
          // If the user is currently active, or only became
          // inactive at a time that's no more than 1/5 the token
          // expiration time in the past from the time this callback
          // is executed, request to extend the session
          const secondsSinceInactivity = becameInactiveAtRef.current
            ? subMilliseconds(
                new Date(),
                becameInactiveAtRef.current
              ).getTime() / 1000
            : 0;
          // Early return if the user became inactive at a time
          // more than 1/5 of the token expiration time in the past
          if (secondsSinceInactivity > tokenExpiresIn / 5) return;

          // The extend request may fail (in case the token has already expired)
          // - if so, fail silently/catch and allow the next authenticated request
          // to fail/force logout
          const response = await client.mutate({
            mutation: ExtendSessionDocument,
            context: {
              headers: {
                Authorization: token,
              },
            },
          });

          // First reset the current ref value
          checkActivityRef.current = undefined;

          // Check for errors in the response
          if (response.errors?.length) {
            const errorMessage = response.errors[0].message;
            const tokenError = [
              "API token is expired or revoked",
              "API token is invalid or missing",
            ].some((s) => errorMessage.includes(s));
            if (!tokenError) {
              // Only capture an unexpected error
              Sentry.captureMessage(
                `Unable to extend session due to unexpected error: ${JSON.stringify(
                  response.errors
                )}`,
                "error"
              );
            }
            return;
          }

          // Update the expiresIn variable
          const newExpiresIn = response.data?.extendSession.remainingDuration;
          setTokenExpiresIn(newExpiresIn);

          // Update the call count to change the deps and force a re-execution
          // of the useEffect callback which should set a new timeout (not
          // using setInterval instead in the case the callback delay
          // should change based on a new remainingDuration value in the response
          setExtendedSessionCount(extendedSessionCount + 1);

          // subtracting 10s to account for round-trip from this app -> Connect API -> Malan
          // (and back)
        },
        Math.max(tokenExpiresIn * 1000 - 10000)
      );
    } else if (!token && checkActivityRef.current) {
      // If the user has logged out and there is
      // still an interval check, clear it
      clearTimeout(checkActivityRef.current);
      checkActivityRef.current = undefined;
    }
    // Clean-up
    return () => {
      if (checkActivityRef.current) clearTimeout(checkActivityRef.current);
      checkActivityRef.current = undefined;
    };
  }, [client, token, tokenExpiresIn, extendedSessionCount]);

  const authContext = useMemo(
    (): AuthContextType => ({
      isLoggedIn: !!token,
      register: async (input): Promise<boolean> => {
        try {
          const result = await client.query({
            fetchPolicy: "no-cache",
            query: CreateVisitorDocument,
            variables: { input },
          });
          updateToken(result.data.createVisitor.authToken);
          // Reset session extension variables
          becameInactiveAtRef.current = null;
          setTokenExpiresIn(null);
          setExtendedSessionCount(0);
          track("Signup - Success");
          return true;
        } catch (e) {
          handleApolloError(e);
          track("Signup - Failure", {
            reason: e instanceof Error ? e.message : "unknown",
          });
          return false;
        }
      },
      login: async (
        username: string,
        password: string,
        userType = UserType.User
      ): Promise<boolean> => {
        try {
          const result = await client.query({
            fetchPolicy: "no-cache",
            query: LoginDocument,
            variables: {
              input: {
                username,
                password,
                userType,
              },
            },
          });
          // Set the token and when it expires
          updateToken(result.data.loginUser.authToken);
          // Reset session extension variables
          becameInactiveAtRef.current = null;
          setTokenExpiresIn(result.data.loginUser.remainingDuration);
          setExtendedSessionCount(0);
          track("Login - Success");
          return true;
        } catch (e) {
          handleApolloError(e);
          track("Login - Failure", {
            reason: e instanceof Error ? e.message : "unknown",
          });
          return false;
        }
      },
      logout: async (tokenError?: boolean): Promise<void> => {
        updateToken("");
        // Reset session extension variables
        becameInactiveAtRef.current = null;
        setTokenExpiresIn(null);
        setExtendedSessionCount(0);
        // If the user was automatically logged out due to
        // a token error, give them feedback
        if (tokenError)
          snackbarContext.alert(
            "info",
            t("Your session expired. Please log in again.")
          );
        adoptBrowserLanguage();
        unidentify();
        await client.clearStore();
        try {
          await client.mutate({
            mutation: LogoutDocument,
            variables: { input: { authToken: token } },
          });
        } catch (e) {
          // report but do not fail
          Sentry.captureException(e);
        }
      },
    }),
    [
      client,
      token,
      handleApolloError,
      snackbarContext,
      t,
      adoptBrowserLanguage,
      updateToken,
    ]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}
