import { useSnackbarContext } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Box, Link, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { MeetingStatus, MeetingType, PrivacyLevel } from "../api/graphql";
import { ContentSkeleton } from "../lib/closet";
import DetailsStack, { DetailsStackItem } from "../lib/DetailsStack";
import useApolloErrorHandler from "../lib/handleApolloError";
import MeetingStatusChip from "../lib/MeetingStatusChip";
import { participantsList } from "../lib/nameUtils";
import privacyLevelDescription from "../lib/privacyLevelDescription";
import Quotation from "../lib/Quotation";
import Screen from "../lib/Screen";
import ScreenSection from "../lib/ScreenSection";
import showPPTOS from "../lib/showPPTOS";
import { privacyPolicy } from "../lib/staticUrls";
import {
  durationRoundedToMinutes,
  formatTimeRange,
  shortMonthDateTime,
  shortTime,
} from "../lib/timeFormats";
import { useCurrentUser } from "../SessionBoundary";
import { ltrTheme } from "../theme";
import CancelMeetingButton from "./CancelMeetingButton";
import { DownloadScreenshotsDocument } from "./DownloadScreenshots.generated";
import EditMeetingButton from "./EditMeetingButton";
import { isCallJoinable, LEAVABLE_MEETING_STATUSES } from "./EventCard";
import EventScreenshotModal from "./EventScreenshotModal";
import {
  GetEventDetailsDocument,
  GetEventDetailsQuery,
} from "./GetEventDetails.generated";
import InPersonVisitFAQList from "./InPersonVisitFAQList";
import JoinCallButton from "./JoinCallButton";
import LeaveMeetingButton from "./LeaveMeetingButton";
import VideoCallFAQList from "./VideoCallFAQList";

const parseHostsAndParticipants = (
  meeting: GetEventDetailsQuery["meeting"]
) => {
  const participants =
    // Only inmates are participants in the case of webinars
    meeting.meetingType === MeetingType.Webinar
      ? meeting.inmates
      : [
          ...meeting.inmates,
          ...meeting.visitors,
          ...meeting.unregisteredGuests.map((guest) => ({
            id: guest,
            firstName: guest.trim().split(" ")[0],
            lastName: guest.trim().split(" ")[1],
          })),
        ];
  // Hosts are the visitors in the case of webinars
  const hosts =
    meeting.meetingType === MeetingType.Webinar ? meeting.visitors : [];

  return { hosts, participants };
};

export default function EventScreen() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("Missing meeting id");

  const navigate = useNavigate();
  const me = useCurrentUser();
  const [currentUserIsPrimaryVisitor, setCurrentUserIsPrimaryVisitor] =
    useState(false);
  const { data, loading, startPolling } = useQuery(GetEventDetailsDocument, {
    variables: { meetingId: id },
    pollInterval: 30000,
  });

  const handleApolloError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();

  const [screenshotsModalShown, setScreenshotsModalShown] = useState(false);
  const [downloadingPdf, setDownloadingPdf] = useState(false);

  const [downloadScreenshots] = useMutation(DownloadScreenshotsDocument, {
    onError: (e) => handleApolloError(e),
    onCompleted: (d) => {
      const { url } = d.downloadScreenshots;
      if (url && data?.meeting) {
        saveAs(url, `screenshots_${data.meeting.id}.pdf`);
      } else {
        snackbarContext.alert("error", t("Fetch of PDF url failed."));
      }
      setDownloadingPdf(false);
    },
  });

  // BUG: https://github.com/apollographql/apollo-client/issues/9819
  useEffect(() => {
    startPolling(30000);
  }, [startPolling]);

  useEffect(() => {
    if (data?.meeting) {
      setCurrentUserIsPrimaryVisitor(me.id === data.meeting.primaryVisitor?.id);
    }
  }, [data, me.id]);

  if (loading || !data?.meeting) {
    return <ContentSkeleton />;
  }

  const statusKey = {
    SCHEDULED: {
      key: t("Approved"),
      color: ltrTheme.palette.success.dark,
    },
    REJECTED: {
      key: t("Declined"),
      color: ltrTheme.palette.error.dark,
    },
    CANCELLED: {
      key: t("Canceled"),
      color: ltrTheme.palette.error.dark,
    },
    PENDING_APPROVAL: {
      key: t("Pending"),
      color: ltrTheme.palette.warning.dark,
    },
    LIVE: {
      key: t("Live"),
      color: ltrTheme.palette.success.dark,
    },
    ENDED: {
      key: t("Ended"),
      color: undefined,
    },
    NO_SHOW: {
      key: t("No Show"),
      color: undefined,
    },
    TERMINATED: {
      key: t("Terminated"),
      color: undefined,
    },
  };

  const { meeting } = data;
  const status = statusKey[meeting.status];
  const message = meeting.statusDetails;
  const meetingRejectedOrCancelled =
    meeting.status === MeetingStatus.Rejected ||
    meeting.status === MeetingStatus.Cancelled;
  const isCancelableStatus = [
    MeetingStatus.PendingApproval,
    MeetingStatus.Scheduled,
  ].includes(meeting.status);
  const isWebinar = meeting.meetingType === MeetingType.Webinar;

  // Participants are only inmates in the case of webinars
  const { hosts, participants } = parseHostsAndParticipants(meeting);

  // Determine if authenticated user is a host or participant (provider vs. inmate)
  const selfAsParticipantInMeeting = participants.some((p) => p.id === me.id); // In case we support viewing other organization members' meetings.
  const selfAsHostInMeeting = hosts.some((p) => p.id === me.id);
  const meNamed = { firstName: t("You"), lastName: "" };

  // Find other hosts and participants (those that aren't the authenticated user)
  const otherHosts = hosts.filter((h) => h.id !== me.id);
  const otherParticipants = participants.filter((p) => p.id !== me.id);

  // Create all hosts string
  const allHostsString = selfAsHostInMeeting
    ? participantsList([meNamed, ...otherHosts], isWebinar)
    : participantsList(hosts, isWebinar);

  // Create all participants string
  const allParticipantsString = selfAsParticipantInMeeting
    ? participantsList([meNamed, ...otherParticipants], isWebinar)
    : participantsList(participants, isWebinar);

  // Create other participants string
  const otherParticipantsString = participantsList(
    otherParticipants,
    isWebinar
  );

  // Set the cancel meeting dialog description (if needed)
  const cancelDescription = isWebinar
    ? t(
        "This can't be undone. We will let all hosts and students know that the webinar is canceled."
      )
    : t(
        "This can't be undone. We will let {{participants}} know that the meeting is canceled.",
        { participants: otherParticipantsString }
      );

  const meetingIntervalMs =
    new Date(meeting.interval.endAt).getTime() -
    new Date(meeting.interval.startAt).getTime();

  const availableScreenshots = data?.meeting?.call?.screenshots || [];

  return (
    <Screen
      showBack
      showNotifications
      headerAction={
        (isWebinar ||
          currentUserIsPrimaryVisitor ||
          me.__typename === "Inmate") &&
        isCallJoinable(meeting) ? (
          <JoinCallButton meeting={meeting} />
        ) : isCancelableStatus &&
          ((isWebinar && selfAsHostInMeeting) ||
            (!isWebinar && currentUserIsPrimaryVisitor)) ? (
          <Stack gap={1} flexDirection="row" sx={{ flexGrow: 1 }}>
            <EditMeetingButton meeting={meeting} />
            <CancelMeetingButton
              meeting={meeting}
              description={cancelDescription}
            />
          </Stack>
        ) : (
          isCancelableStatus &&
          !isWebinar &&
          (me.__typename === "Inmate" ? (
            <CancelMeetingButton
              meeting={meeting}
              description={cancelDescription}
            />
          ) : !currentUserIsPrimaryVisitor &&
            LEAVABLE_MEETING_STATUSES.includes(meeting.status) ? (
            <LeaveMeetingButton
              meeting={meeting}
              description={t("This can't be undone.")}
              onLeave={() => navigate("/events")}
            />
          ) : null)
        )
      }
      title={
        meeting.meetingType === MeetingType.VideoCall
          ? t("Video call with {{otherParticipantNames}}", {
              otherParticipantNames: otherParticipantsString,
            })
          : meeting.meetingType === MeetingType.Webinar
            ? t("Webinar: {{title}}", {
                title: meeting.title,
              })
            : meeting.meetingType === MeetingType.VoiceCall
              ? t("Voice call with {{otherParticipantNames}}", {
                  otherParticipantNames: otherParticipantsString,
                })
              : t("Visit with {{otherParticipantNames}}", {
                  otherParticipantNames: otherParticipantsString,
                })
      }
    >
      {!currentUserIsPrimaryVisitor &&
        meeting.meetingType === MeetingType.VideoCall &&
        me.__typename !== "Inmate" && (
          <Alert severity="info">
            {t(
              "This call was scheduled by another user. You need to join with them, sharing the same physical device."
            )}
          </Alert>
        )}
      {meetingRejectedOrCancelled && (
        <Alert severity="error" sx={{ mb: 4 }}>
          <Box>
            <b>
              {t("This event was {{status}}", {
                status: status.key.toLowerCase(),
              })}
            </b>
          </Box>
          {message && <Quotation pt={1}>{message}</Quotation>}
        </Alert>
      )}
      <ScreenSection title={t("Event details")}>
        <DetailsStack>
          <DetailsStackItem label={t("Status")}>
            <MeetingStatusChip
              status={meeting.status}
              meetingType={meeting.meetingType}
            />
          </DetailsStackItem>
          <DetailsStackItem label={t("Date")}>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                textDecoration: meetingRejectedOrCancelled
                  ? "line-through"
                  : undefined,
              }}
            >
              {format(new Date(meeting.interval.startAt), "EEEE MMM d")}
            </Typography>
          </DetailsStackItem>
          <DetailsStackItem label={t("Time")}>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                textDecoration: meetingRejectedOrCancelled
                  ? "line-through"
                  : undefined,
              }}
            >
              {
                // Voice calls that are "scheduled" are in a calling phase
                // and voice calls that are "no show" were not answered.
                // In both cases, displaying an end time doesn't make sense.
                meeting.meetingType === MeetingType.VoiceCall &&
                [MeetingStatus.Scheduled, MeetingStatus.NoShow].includes(
                  meeting.status
                )
                  ? shortTime.format(meeting.interval.startAt)
                  : `${formatTimeRange(
                      meeting.interval.startAt,
                      meeting.interval.endAt
                    )} (${durationRoundedToMinutes(meetingIntervalMs)})`
              }
            </Typography>
          </DetailsStackItem>
          {meeting.meetingType === MeetingType.Webinar && (
            <DetailsStackItem label={t("Host(s)")} value={allHostsString} />
          )}
          <DetailsStackItem
            label={t("Participants")}
            value={allParticipantsString}
          />
          {meeting.status === MeetingStatus.Scheduled && message && (
            <DetailsStackItem label={t("Staff note")}>
              <Quotation>{message}</Quotation>
            </DetailsStackItem>
          )}
          <DetailsStackItem label={t("Privacy")}>
            {meeting.privacyLevel === PrivacyLevel.Monitored && (
              <Typography variant="body1" color="text.primary">
                {meeting.meetingType === MeetingType.InPersonVisit
                  ? t("This visit may be monitored.")
                  : t("This call may be monitored and recorded.")}{" "}
                {showPPTOS(me) && (
                  <Link href={privacyPolicy} target="_blank">
                    {t("Privacy policy")}
                  </Link>
                )}
              </Typography>
            )}
            {meeting.privacyLevel === PrivacyLevel.UnmonitoredLogged && (
              <Typography variant="body1" color="text.primary" component="div">
                {privacyLevelDescription(
                  meeting.meetingType,
                  meeting.privacyLevel
                )}{" "}
                {showPPTOS(me) && (
                  <Link href={privacyPolicy} target="_blank">
                    {t("Privacy policy")}
                  </Link>
                )}
              </Typography>
            )}
          </DetailsStackItem>
          {availableScreenshots.length > 0 && (
            <DetailsStackItem label={t("Screenshots")}>
              <Stack spacing={1} alignItems="flex-start">
                <Typography variant="body1" color="text.primary">
                  {t(
                    "Low-quality screenshots are available for a limited time."
                  )}{" "}
                  {availableScreenshots[0].viewedAt
                    ? t("They were viewed by {{viewedBy}} on {{viewedAt}}.", {
                        viewedBy: availableScreenshots[0].viewedBy,
                        viewedAt: shortMonthDateTime(
                          new Date(availableScreenshots[0].viewedAt)
                        ),
                      })
                    : ""}
                </Typography>
                {me.__typename !== "Inmate" ? (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <Link
                    component="button"
                    variant="body1"
                    onClick={() => {
                      setDownloadingPdf(true);
                      downloadScreenshots({
                        variables: {
                          input: {
                            meetingId: meeting.id,
                          },
                        },
                      });
                    }}
                    disabled={downloadingPdf}
                  >
                    {t("Download screenshots")}
                  </Link>
                ) : (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <Link
                    component="button"
                    variant="body1"
                    onClick={() => setScreenshotsModalShown(true)}
                  >
                    {t("View screenshots")}
                  </Link>
                )}
              </Stack>
            </DetailsStackItem>
          )}
        </DetailsStack>
      </ScreenSection>

      <ScreenSection title={t("Facility information")}>
        <DetailsStack>
          <DetailsStackItem
            label={t("Facility")}
            value={meeting.facility.name}
          />
          {meeting.kiosk && (
            <DetailsStackItem
              label={t("Kiosk")}
              value={meeting.kiosk.name || ""}
            />
          )}
        </DetailsStack>
      </ScreenSection>
      {meeting.meetingType === MeetingType.VideoCall && (
        <ScreenSection title={t("What can I expect for my call?")}>
          <DetailsStack>
            <VideoCallFAQList
              meetingDurationMins={Math.round(meetingIntervalMs / 60000)}
              privacyLevel={meeting.privacyLevel}
            />
          </DetailsStack>
        </ScreenSection>
      )}
      {meeting.meetingType === MeetingType.InPersonVisit &&
        me.__typename === "Visitor" && (
          <ScreenSection title={t("Preparing for a successful visit")}>
            <DetailsStack>
              <InPersonVisitFAQList facility={meeting.facility} />
            </DetailsStack>
          </ScreenSection>
        )}
      {screenshotsModalShown && (
        <EventScreenshotModal
          meeting={data.meeting}
          onClose={() => setScreenshotsModalShown(false)}
        />
      )}
    </Screen>
  );
}
