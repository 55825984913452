import { setDefaultOptions } from "date-fns";
import { enUS, es, it } from "date-fns/locale";
import { Language } from "../api/graphql";

const languageToI18n: Record<Language, string> = {
  [Language.En]: "en",
  [Language.Es]: "es",
  [Language.It]: "it",
};

const LABELS: Record<Language, string> = {
  [Language.En]: "English",
  [Language.Es]: "Español",
  [Language.It]: "Italiano",
};

// Get the language label
function languageLabel(type: Language) {
  return LABELS[type];
}

// Check if a language is supported
function isSupportedLanguage(language: string): language is Language {
  return Object.values(languageToI18n).includes((language || "").toLowerCase());
}

// Convert browser language to i18n language. If browser language is not supported, return "en"
function browserToI18n(browserLanguage = navigator.language) {
  const prefix = (browserLanguage.split("-")[0] || "").toLowerCase();
  if (isSupportedLanguage(prefix)) return prefix;
  return "en";
}

function setDateLocale(language: Language) {
  switch (language.toUpperCase()) {
    case Language.It:
      setDefaultOptions({ locale: it });
      break;
    case Language.Es:
      setDefaultOptions({ locale: es });
      break;
    case Language.En:
      setDefaultOptions({ locale: enUS });
      // Do nothing
      break;
    default:
      throw new Error(`Unsupported language: ${language}`);
  }
}

export {
  browserToI18n,
  isSupportedLanguage,
  languageLabel,
  languageToI18n,
  setDateLocale,
};
