import { IconButton } from "@ameelio/ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Notifications from "../Notifications/Notifications";
import addStylesForDevices from "./addStylesForDevices";
import {
  belowLargeTablet,
  largeTabletAndUp,
  onlyMobile,
} from "./responsiveHelpers";
import { ScreenTitle } from "./typography";

type Props = {
  children: React.ReactNode;
  action?: React.ReactNode;
  hideNotifications?: boolean;
  enableBackNavigation?: boolean;
};

export default function ScreenHeader({
  action,
  enableBackNavigation,
  hideNotifications,
  children,
}: Props) {
  const navigate = useNavigate();
  const isMobile = onlyMobile(measureScreenWidth);
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);
  const isLargeTabletAndUp = largeTabletAndUp(measureScreenWidth);
  const iconButtonStyles: SxProps<Theme> = addStylesForDevices(
    isMobileOrSmallTablet,
    { mr: 1 },
    { display: "none" }
  );
  const actionContainerStyles: SxProps<Theme> = addStylesForDevices(
    isMobile,
    { display: "flex", width: "auto", mt: 0 },
    { display: "flex", width: 1, mt: 2 }
  );

  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={4}
      pt={3}
      pr={1}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
      }}
    >
      {enableBackNavigation && (
        <IconButton
          sx={iconButtonStyles}
          onClick={() => navigate(-1)}
          ariaLabel={t("Back")}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <ScreenTitle sx={{ flex: 1, pr: 2 }}>{children}</ScreenTitle>
      {action && <Box sx={actionContainerStyles}>{action}</Box>}
      {isLargeTabletAndUp && !hideNotifications && (
        <Box sx={{ ml: 2 }}>
          <Notifications />
        </Box>
      )}
    </Box>
  );
}
