import { useEffect, useRef, useState } from "react";

export type DeviceInfo = {
  deviceId: string;
  kind: string;
  label: string;
};

function useDevices(monitorChange?: boolean): DeviceInfo[] {
  const [devices, setDevices] = useState<DeviceInfo[]>([]);

  useEffect(() => {
    const enumerateDevices = async () => {
      try {
        const deviceList = await navigator.mediaDevices.enumerateDevices();
        const deviceInfoList = deviceList.map((device) => ({
          kind: device.kind,
          deviceId: device.deviceId,
          label: device.label || "Unknown",
        }));
        setDevices(deviceInfoList);
      } catch (error) {
        console.error("Error enumerating devices:", error);
      }
    };

    enumerateDevices();

    const handleDeviceChange = () => {
      enumerateDevices();
    };

    if (monitorChange) {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        handleDeviceChange
      );
    }

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange
      );
    };
  }, [monitorChange]);

  return devices;
}

function useDeviceChanges(
  onAdded: (device: DeviceInfo) => void,
  onRemoved: (device: DeviceInfo) => void
) {
  const devices = useDevices(true);
  const devicesRef = useRef<DeviceInfo[]>([]);

  useEffect(() => {
    const addedDevices = devices.filter(
      (newDevice) =>
        !devicesRef.current.some(
          (device) => device.deviceId === newDevice.deviceId
        )
    );
    const removedDevices = devicesRef.current.filter(
      (device) =>
        !devices.some((newDevice) => newDevice.deviceId === device.deviceId)
    );

    addedDevices.forEach(onAdded);
    removedDevices.forEach(onRemoved);

    devicesRef.current = devices;
  }, [devices, onAdded, onRemoved]);
}

export { useDeviceChanges, useDevices };
