import { Grid, Stack, Typography } from "@mui/material";
import grey from "@mui/material/colors/grey";
import { useTranslation } from "react-i18next";
import getFilenameFromUrl from "../lib/File";
import ZoomableImage from "./ZoomableImage";

export type MediaGalleryProps = {
  messages: {
    fileUrl: string | null;
    id: string;
  }[];
};
export function DocumentGallery({ messages }: MediaGalleryProps) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      columns={3}
      style={{ lineHeight: 0 }}
      rowSpacing={0.2}
      columnSpacing={0.2}
    >
      {!messages.length && (
        <Typography variant="caption" color="text.secondary">
          {t("Document you receive will appear here once approved")}
        </Typography>
      )}
      <Stack direction="column">
        {!!messages.length &&
          messages.map(
            (message) =>
              message.fileUrl && (
                <Typography
                  key={message.id}
                  sx={{
                    backgroundColor: grey[50],
                    marginBottom: 1,
                    fontSize: "small",
                  }}
                >
                  <a
                    href={message.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getFilenameFromUrl(message.fileUrl)}
                  </a>
                </Typography>
              )
          )}
      </Stack>
    </Grid>
  );
}

export function ImageGallery({ messages }: MediaGalleryProps) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      columns={3}
      style={{ lineHeight: 0 }}
      rowSpacing={0.2}
      columnSpacing={0.2}
    >
      {!messages.length && (
        <Typography variant="body2" color="text.secondary">
          {t("Photos will appear here once approved")}
        </Typography>
      )}
      {!!messages.length &&
        messages.map(
          (message) =>
            message.fileUrl && (
              <Grid item xs={1} sm={1} md={1} key={message.id}>
                <ZoomableImage
                  src={message.fileUrl}
                  messageId={message.id}
                  style={{
                    width: "100%",
                    aspectRatio: "1",
                    objectFit: "cover",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            )
        )}
    </Grid>
  );
}
