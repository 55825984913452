import { Media } from "@ameelio/ui";
import {
  ChatOutlined,
  MicOffOutlined,
  ScreenShareOutlined,
  Security,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Box, Card, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  isHost: boolean;
  sx?: SxProps<Theme>;
  textColor: string;
};

export function WebinarHostInformation({ textColor }: { textColor: string }) {
  const { t } = useTranslation();
  return (
    <Stack spacing={1.5}>
      <Media gap={2} image={<ScreenShareOutlined />}>
        <Typography variant="body2" color={textColor}>
          {t(
            "Host(s) can share their screens as well as see, hear, and interact with attendees."
          )}
        </Typography>
      </Media>
      <Media gap={2} image={<MicOffOutlined />}>
        <Typography variant="body2" color={textColor}>
          {t(
            "Attendees are muted by default. Only hosts can unmute attendees."
          )}
        </Typography>
      </Media>
      <Media gap={2} image={<ChatOutlined />}>
        <Typography variant="body2" color={textColor}>
          {t("All participants can send and receive in-call messages")}
        </Typography>
      </Media>
      <Media gap={2} image={<Security />}>
        <Typography variant="body2" color={textColor}>
          {t(
            "COs maintain the right to monitor webinars. Host(s) are notified if one is doing so."
          )}
        </Typography>
      </Media>
    </Stack>
  );
}

function WebinarStudentInformation({ textColor }: { textColor: string }) {
  const { t } = useTranslation();
  return (
    <Stack spacing={1.5}>
      <Media gap={2} image={<VisibilityOutlined />}>
        <Typography variant="body2" color={textColor}>
          {t("Your video stream is visible to all participants")}
        </Typography>
      </Media>
      <Media gap={2} image={<MicOffOutlined />}>
        <Typography variant="body2" color={textColor}>
          {t(
            "Attendees are muted by default. Only hosts can unmute attendees."
          )}
        </Typography>
      </Media>
      <Media gap={2} image={<ChatOutlined />}>
        <Typography variant="body2" color={textColor}>
          {t("All participants can send and receive in-call messages")}
        </Typography>
      </Media>
      <Media gap={2} image={<Security />}>
        <Typography variant="body2" color={textColor}>
          {t("COs maintain the right to monitor webinars")}
        </Typography>
      </Media>
    </Stack>
  );
}

export default function WebinarInfoCard({ isHost, sx, textColor }: Props) {
  const { t } = useTranslation();
  return (
    <Card variant="outlined" sx={sx || {}}>
      <Stack py={2} px={2.5} spacing={1.5}>
        <Box>
          <Typography variant="body1" color={textColor}>
            {t("About webinars")}
          </Typography>
        </Box>
        {isHost ? (
          <WebinarHostInformation textColor={textColor} />
        ) : (
          <WebinarStudentInformation textColor={textColor} />
        )}
      </Stack>
    </Card>
  );
}
