import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ltrTheme } from "../../theme";
import CallWidget from "./CallWidget";

type Props = {
  message: string;
};

const toastStyle: SxProps<Theme> = {
  position: "absolute",
  top: 0,
  left: { xs: ltrTheme.spacing(2), sm: 0 },
  zIndex: "modal",
  display: "flex",
  width: 1,
  justifyContent: { xs: "start", sm: "center" },
  mt: { xs: 3, sm: 4 },
};

export default function StatusWidget({ message }: Props) {
  return (
    <Box sx={toastStyle}>
      <CallWidget sx={{ px: 2 }}>
        <Typography variant="body2">{message}</Typography>
      </CallWidget>
    </Box>
  );
}
