import Stepper from "./Stepper";

type StepperProps = {
  onBack: () => void;
  buttonType?: ButtonType;
  disabled?: boolean;
  loading: boolean;
  steps?: string[];
  step?: string;
};

export type StepperType = (props: PassedStepperProps) => JSX.Element;

type PassedStepperProps = {
  loading?: boolean;
  disabled?: boolean;
};

type ButtonType = "next" | "finish" | "start";

export default function StepperWrapper({
  onBack,
  buttonType,
  disabled,
  loading,
  step,
  steps,
}: StepperProps) {
  const buttonText =
    buttonType ||
    ((steps || []).indexOf(step || "") === 0 // If on the first step
      ? "start"
      : (steps || []).indexOf(step || "") === (steps || []).length - 2 // If on the second-to-last step
        ? "finish"
        : "next"); // Otherwise, somewhere in the middle
  return (
    <Stepper
      onBack={onBack}
      next={buttonText === "next"}
      finish={buttonText === "finish"}
      start={buttonText === "start"}
      disabled={disabled}
      loading={loading}
      step={step}
      steps={steps}
    />
  );
}
