import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SetLanguagePreferenceMutationVariables = Types.Exact<{
  input: Types.UpdateLanguagePreferenceInput;
}>;


export type SetLanguagePreferenceMutation = { __typename: 'Mutation', updateLanguagePreference: { __typename: 'UpdateLanguagePreferenceResponse', user: { __typename: 'Inmate', id: Types.Scalars['ID']['input'], languagePreference: Types.Language } | { __typename: 'Staff' } | { __typename: 'Visitor', id: Types.Scalars['ID']['input'], languagePreference: Types.Language } } };


export const SetLanguagePreferenceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetLanguagePreference"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateLanguagePreferenceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateLanguagePreference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Inmate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"languagePreference"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Visitor"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"languagePreference"}}]}}]}}]}}]}}]} as unknown as DocumentNode<SetLanguagePreferenceMutation, SetLanguagePreferenceMutationVariables>;