import { useLazyQuery } from "@apollo/client";
import { Close, Download } from "@mui/icons-material";
import { Avatar, Box, Button, Dialog, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { GetMessageDocument } from "./GetMessage.generated";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  messageId: string;
};

export default function ZoomableImage({ src, messageId, ...rest }: Props) {
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const user = useCurrentCorrespondent();
  const { t } = useTranslation();

  const [refetchUrlAndDownload] = useLazyQuery(GetMessageDocument, {
    variables: { id: messageId },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const url = data.message?.fileUrl;

      if (url) {
        const downloadLink = document.createElement("a");
        downloadLink.href = url;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);
      }
    },
  });

  return (
    <>
      <Box
        onClick={() => setOpenImageDialog(true)}
        display="flex"
        alignItems="center"
      >
        <img
          src={src}
          alt={t("Something went wrong loading the image for this message")}
          {...rest}
        />
      </Box>
      <Dialog
        fullScreen
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        <Stack style={{ maxHeight: "100%" }}>
          <Box
            display="flex"
            sx={{
              position: "absolute",
              height: "80px",
              right: "4px",
              top: 0,
            }}
          >
            {user.__typename !== "Inmate" && (
              <Button onClick={() => refetchUrlAndDownload()}>
                <Avatar sx={{ bgcolor: "white", color: "text.primary" }}>
                  <Download />
                </Avatar>
              </Button>
            )}
            <Button onClick={() => setOpenImageDialog(false)}>
              <Avatar sx={{ bgcolor: "white", color: "text.primary" }}>
                <Close />
              </Avatar>
            </Button>
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            height="100%"
            py={10}
          >
            <img
              src={src}
              alt={t("Something went wrong loading the image for this message")}
              style={{ maxHeight: "100%" }}
            />
          </Box>
          <Box sx={{ height: (th) => th.spacing(8) }} alignItems="right" />
        </Stack>
      </Dialog>
    </>
  );
}
