import { TextInputBase } from "@ameelio/ui";
import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import debounce from "lodash.debounce";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import addStylesForDevices from "../../lib/addStylesForDevices";
import { onlyMobile } from "../../lib/responsiveHelpers";
import { GetConnectionsQuery } from "./GetConnections.generated";

type Props = {
  connections: Connection[];
  facilities: string[];
  onSearch: (isSearchNonempty: boolean, connections: Connection[]) => void;
};

type Connection = Exclude<
  GetConnectionsQuery["currentVisitor"],
  null
>["connections"][0];

export default function ContactsFilter({
  connections,
  facilities,
  onSearch,
}: Props) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("name");
  const [name, setName] = useState("");
  const [location, setLocation] = useState<string | null>(null);
  const [inputLocation, setInputLocation] = useState("");
  const isMobile = onlyMobile(measureScreenWidth);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setLocation(null);
    setName("");
    setFilter(event.target.value as string);
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
  };

  const filterContacts = useCallback(
    (thisLocation: string | null, thisName: string) => {
      let results = connections;
      if (thisName.length) {
        results = results.filter(
          (c) =>
            c.inmate.firstName.toLowerCase().includes(thisName.toLowerCase()) ||
            c.inmate.lastName.toLowerCase().includes(thisName.toLowerCase())
        );
      }

      if (thisLocation !== null && thisLocation !== undefined) {
        results = results.filter(
          (c) => c.inmate.facility.name === thisLocation
        );
      }

      onSearch(thisName.length > 0, results);
    },
    [onSearch, connections]
  );

  const throttledSearch = useRef(
    debounce(
      (thisLocation: string | null, thisName: string) =>
        filterContacts(thisLocation, thisName),
      1000,
      { leading: true }
    )
  );

  useEffect(
    () => throttledSearch.current(location, name),
    [throttledSearch, location, name]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "baseline",
        gap: 1,
        marginBottom: 4,
      }}
    >
      <Typography>{t("Search by:")}</Typography>
      <Select
        sx={{
          width: { xs: "100%", sm: "220px" },
          borderRadius: "8px",
          flex: "none",
          alignSelf: "stretch",
          flexGrow: 0,
        }}
        size="small"
        value={filter}
        onChange={handleSelectChange}
        inputProps={{ "aria-label": t("Search by:") }}
      >
        <MenuItem value="name">{t("Name")}</MenuItem>
        <MenuItem value="location">{t("Location")}</MenuItem>
      </Select>

      {filter === "name" && (
        <Box
          sx={addStylesForDevices(
            isMobile,
            {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flexGrow: 1,
            },
            { width: "100%" }
          )}
        >
          <TextInputBase
            sx={{
              width: "100%",
              borderRadius: "8px",
            }}
            label={t("Search Name")}
            onChange={handleNameChange}
            value={name}
          />
        </Box>
      )}

      {filter === "location" && (
        <Autocomplete
          value={location}
          onChange={(_, newValue: string | null) => {
            setLocation(newValue);
          }}
          inputValue={inputLocation}
          onInputChange={(_, newInputValue: string) => {
            setInputLocation(newInputValue);
          }}
          options={facilities}
          sx={addStylesForDevices(
            isMobile,
            {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flexGrow: 1,
              borderRadius: "8px",
            },
            { width: "100%" }
          )}
          renderInput={(params) => (
            <TextField {...params} label={t("Search Location")} />
          )}
        />
      )}
    </Box>
  );
}
