import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";
import NewConnectionsScreen from "./NewConnectionsScreen";
import NewOrganizationScreen from "./NewOrganizationScreen";
import ContactDetailsScreen from "./OrganizationContacts/ContactDetailsScreen";
import OrganizationMembershipScreen from "./OrganizationMembershipScreen";
import OrganizationScreen, { OrganizationTab } from "./OrganizationScreen";

const OrganizationsRoutes: RouteObject = {
  path: "/organization/",
  element: <Outlet />,
  children: [
    {
      path: "new",
      element: (
        <SessionBoundary userType="Visitor" defaultAuthAction="signup">
          <MenuLayout currentTab="organization">
            <NewOrganizationScreen />
          </MenuLayout>
        </SessionBoundary>
      ),
    },
    {
      path: ":organizationId",
      element: (
        <SessionBoundary userType="Visitor">
          <ValidateUUID id="organizationId">
            <Outlet />
          </ValidateUUID>
        </SessionBoundary>
      ),
      children: [
        {
          path: "contacts",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.contacts} />
            </MenuLayout>
          ),
        },
        {
          path: "contacts/:inmateId",
          element: (
            <MenuLayout currentTab="organization">
              <ValidateUUID id="inmateId">
                <ContactDetailsScreen />
              </ValidateUUID>
            </MenuLayout>
          ),
        },
        { path: "contacts/new", element: <NewConnectionsScreen /> },
        {
          path: "team-member/:organizationMembershipId",
          element: (
            <MenuLayout currentTab="organization">
              <ValidateUUID id="organizationMembershipId">
                <OrganizationMembershipScreen />
              </ValidateUUID>
            </MenuLayout>
          ),
        },
        {
          path: "team",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.team} />
            </MenuLayout>
          ),
        },
        {
          path: "partners",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.partners} />
            </MenuLayout>
          ),
        },
        {
          path: "info",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.info} />
            </MenuLayout>
          ),
        },
      ],
    },
  ],
};

export default OrganizationsRoutes;
