import { Box, Typography } from "@mui/material";
import { common } from "@mui/material/colors";

export default function UserVideoNameTag({ nameTag }: { nameTag: string }) {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        px: 1,
        borderTopRightRadius: 4,
        backgroundColor: "rgba(0,0,0,0.6)",
        color: common.white,
        maxWidth: "100%",
      }}
    >
      <Typography noWrap variant="caption" sx={{ display: "block" }}>
        {nameTag}
      </Typography>
    </Box>
  );
}
