import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Connection, Correspondent } from "../api/graphql";

type Props = {
  object: Pick<Connection, "createdAt"> & {
    contact: Pick<Correspondent, "firstName">;
  };
};

export default function ConnectionEventItem({ object }: Props) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" textAlign="center">
        {t("You and {{firstName}} connected.", {
          firstName: object.contact.firstName,
        })}
      </Typography>
    </Box>
  );
}
