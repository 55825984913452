import { useTranslation } from "react-i18next";
import {
  Connection,
  Facility,
  Inmate,
  Organization,
  User,
  Visitor,
} from "../api/graphql";
import DetailsStack, { DetailsStackItem } from "../lib/DetailsStack";
import { relationshipToHumanReadable } from "../lib/Relationship";
import ScreenSection from "../lib/ScreenSection";
import FacilityInfoSection from "./FacilityInfoSection";

type Props = {
  user: Pick<User, "__typename">;
  contact: Pick<Inmate, "fullName"> | Pick<Visitor, "fullName">;
  organization?: Pick<Organization, "name">;
  facility?: Facility;
  connection?:
    | Pick<Connection, "relationship" | "status" | "inmate">
    | undefined;
  permittedFeatures?: string;
  statusComponent: React.ReactNode;
};
export default function ContactDetails({
  contact,
  connection,
  facility,
  user,
  statusComponent,
  organization,
  permittedFeatures,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <ScreenSection title={t("Profile info")}>
        <DetailsStack>
          <DetailsStackItem label={t("Name")} value={contact.fullName} />
          <DetailsStackItem label={t("Status")}>
            {statusComponent}
          </DetailsStackItem>
          {user.__typename === "Visitor" && connection?.inmate && (
            <DetailsStackItem
              label={t("ID Number")}
              value={connection.inmate.inmateIdentification}
            />
          )}
          {connection?.relationship && (
            <DetailsStackItem
              label={t("Relationship")}
              value={relationshipToHumanReadable(connection.relationship)}
            />
          )}
          {organization && (
            <DetailsStackItem label={t("Team")} value={organization.name} />
          )}
          {permittedFeatures && (
            <DetailsStackItem
              label={t("Available communication")}
              value={permittedFeatures}
            />
          )}
        </DetailsStack>
      </ScreenSection>
      {user.__typename === "Visitor" && facility && (
        <FacilityInfoSection facility={facility} />
      )}
    </>
  );
}
