import { useTranslation } from "react-i18next";
import CallEnded from "../Call/CallEnded.svg";
import EmptyScreen, { Props as EmptyScreenProps } from "../EmptyScreen";
import { useCurrentUser } from "../SessionBoundary";
import Events from "./Events.svg";

type Props = {
  cta: "connect" | "schedule" | "none";
  message?: string;
};

function EmptyEventsScreen(
  props: Omit<EmptyScreenProps, "illustration" | "illustrationWidth">
) {
  return (
    <EmptyScreen illustration={Events} illustrationWidth="314" {...props} />
  );
}

function VisitorNoEventsScreen({ cta, message }: Props) {
  const { t } = useTranslation();
  if (cta === "connect") {
    return (
      <EmptyEventsScreen
        message={
          message ||
          t(
            "Once your contacts have been approved by the facility, you'll be able to see your events on this page."
          )
        }
      />
    );
  }
  if (cta === "schedule") {
    return (
      <EmptyEventsScreen
        message={
          message ||
          t("Nothing right now. You can schedule events from the top right.")
        }
      />
    );
  }
  return (
    <EmptyEventsScreen
      message={message || t("You don't have any events yet.")}
    />
  );
}

export default function NoEventsScreen({ cta, message }: Props) {
  const user = useCurrentUser();
  const { t } = useTranslation();
  if (user.__typename === "Inmate") {
    return (
      <EmptyEventsScreen
        message={message || t("You don't have any events yet.")}
      />
    );
  }
  return <VisitorNoEventsScreen cta={cta} message={message} />;
}

export function NoContactsScreen({
  linkProps,
}: {
  linkProps?: EmptyScreenProps["linkProps"];
}) {
  const { t } = useTranslation();
  return (
    <EmptyScreen
      message={t("You don’t have any contacts yet.")}
      illustration={CallEnded}
      illustrationWidth="318"
      linkProps={linkProps}
    />
  );
}
