import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ConnectionsFlow from "../ConnectionsFlow";

export default function NewConnectionsScreen() {
  const { t } = useTranslation();
  const { organizationId } = useParams<{ organizationId: string }>();

  return (
    <ConnectionsFlow
      backTo={`/organization/${organizationId}/contacts`}
      parentName={t("Contacts")}
    />
  );
}
