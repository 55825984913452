import { Media, Tooltip } from "@ameelio/ui";
import {
  BadgeOutlined,
  LockOutlined,
  PeopleAltOutlined,
  Voicemail,
} from "@mui/icons-material";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import { Box, capitalize, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ConnectionFeature,
  FacilityService,
  MeetingType,
  PrivacyLevel,
} from "../../api/graphql";
import meetingTypeLabel from "../../lib/meetingTypeLabel";
import privacyLevelDescription from "../../lib/privacyLevelDescription";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { ScreenTitle } from "../../lib/typography";
import { useCurrentVisitor } from "../../SessionBoundary";
import EventTypeCard from "./EventTypeCard";
import { MeetingData } from "./types";

type Props = {
  defaultValues: {
    meetingType: MeetingData["meetingType"];
    connections?: MeetingData["connections"];
  };
  stepper: StepperType;
  onSubmit: (meetingType: MeetingData["meetingType"]) => void;
  willBeConfidential?: boolean;
};

export default function SelectEventTypeStep({
  defaultValues,
  stepper,
  onSubmit,
  willBeConfidential,
}: Props) {
  const { t } = useTranslation();
  const [selectedMeetingType, setSelectedMeetingType] = useState<
    MeetingData["meetingType"] | undefined
  >(defaultValues.meetingType);
  const user = useCurrentVisitor();

  // Meeting connections may contain registered guest connections, so filter
  // to ensure we're getting the current visitor's connections ONLY
  const connection =
    defaultValues.connections?.filter((c) => c.visitor.id === user.id)[0] ||
    undefined;
  // A connection must be provided in this step
  if (!connection) throw new Error("missing connection");

  // Parse connection restrictions
  const activeRestrictions = user.connections.find(
    (c) => c.id === connection.id
  )?.activeRestrictions;
  const restrictedServices = activeRestrictions?.map((r) => r.service) ?? [];
  const videoCallsRestricted = restrictedServices.includes(
    FacilityService.VideoCall
  );
  const ipvRestricted = restrictedServices.includes(
    FacilityService.InPersonVisit
  );

  const { inmate } = connection;
  const { firstName } = inmate;

  const videoCallsDisabledReason = (() =>
    videoCallsRestricted
      ? t(
          "You are currently restricted from scheduling video calls with {{firstName}}",
          {
            firstName,
          }
        )
      : !(
            connection.features?.includes(ConnectionFeature.VideoCall) ||
            connection.features?.includes(
              ConnectionFeature.ConfidentialVideoCall
            )
          )
        ? t("Video calls are not available with {{firstName}}", {
            firstName,
          })
        : "")();

  const ipvDisabledReason = (() =>
    ipvRestricted
      ? t(
          "You are currently restricted from scheduling in-person visits with {{firstName}}",
          {
            firstName,
          }
        )
      : !(
            connection.features?.includes(ConnectionFeature.InPersonVisit) ||
            connection.features?.includes(
              ConnectionFeature.ConfidentialInPersonVisit
            )
          )
        ? t("In-person visits are not available with {{firstName}}", {
            firstName,
          })
        : "")();

  const handleSubmit = (e: React.FormEvent) => {
    if (!selectedMeetingType) return;
    e.preventDefault();
    onSubmit(selectedMeetingType);
  };

  // used to "unset" a default meeting type selection, if, after
  // permissions analysis it is determined that it is not allowed.
  // this could happen in a situation where a meeting type that was
  // previously allowed is attempted to be duplicated, but now something
  // has changed (for example, a restriction on that meeting type, or the
  // facility has stopped offering that meeting type)
  useEffect(() => {
    if (
      (!!ipvDisabledReason &&
        defaultValues.meetingType === MeetingType.InPersonVisit) ||
      (!!videoCallsDisabledReason &&
        defaultValues.meetingType === MeetingType.VideoCall)
    )
      setSelectedMeetingType(undefined);
  }, [defaultValues.meetingType, ipvDisabledReason, videoCallsDisabledReason]);

  return (
    <StepperFormWrapper
      stepper={stepper({ disabled: !selectedMeetingType, loading: false })}
      handleSubmit={handleSubmit}
    >
      <ScreenTitle>{t("Select the type of event")}</ScreenTitle>
      <Box my={6}>
        <EventTypeCard
          title={capitalize(meetingTypeLabel(MeetingType.VideoCall))}
          onClick={
            !videoCallsDisabledReason
              ? () => setSelectedMeetingType(MeetingType.VideoCall)
              : undefined
          }
          selected={selectedMeetingType === MeetingType.VideoCall}
          disabled={!!videoCallsDisabledReason}
          tooltipTitle={videoCallsDisabledReason}
        >
          <Stack spacing={1.5}>
            <Media gap={2} image={<VideocamOutlined />}>
              <Typography
                variant="body2"
                color={
                  videoCallsDisabledReason
                    ? "rgba(0 0 0 / .23)"
                    : "text.secondary"
                }
              >
                {t("Connect virtually from your device")}
              </Typography>
            </Media>
            {willBeConfidential ? (
              <Media
                gap={2}
                image={
                  <Tooltip
                    title={privacyLevelDescription(
                      MeetingType.VideoCall,
                      PrivacyLevel.UnmonitoredLogged
                    )}
                  >
                    <LockOutlined />
                  </Tooltip>
                }
              >
                <Typography
                  variant="body2"
                  color={
                    videoCallsDisabledReason
                      ? "rgba(0 0 0 / .23)"
                      : "text.secondary"
                  }
                >
                  {t("Confidential")}
                </Typography>
              </Media>
            ) : (
              <Media gap={2} image={<Voicemail />}>
                <Typography
                  variant="body2"
                  color={
                    videoCallsDisabledReason
                      ? "rgba(0 0 0 / .23)"
                      : "text.secondary"
                  }
                >
                  {privacyLevelDescription(
                    MeetingType.VideoCall,
                    PrivacyLevel.Monitored
                  )}
                </Typography>
              </Media>
            )}
          </Stack>
        </EventTypeCard>
        <Box mt={2}>
          <EventTypeCard
            title={capitalize(meetingTypeLabel(MeetingType.InPersonVisit))}
            onClick={
              !ipvDisabledReason
                ? () => setSelectedMeetingType(MeetingType.InPersonVisit)
                : undefined
            }
            selected={selectedMeetingType === MeetingType.InPersonVisit}
            disabled={!!ipvDisabledReason}
            tooltipTitle={ipvDisabledReason}
          >
            <Stack spacing={1.5}>
              <Media gap={2} image={<PeopleAltOutlined />}>
                <Typography
                  variant="body2"
                  color={
                    ipvDisabledReason ? "rgba(0 0 0 / .23)" : "text.secondary"
                  }
                >
                  {t("Visit the facility in person")}
                </Typography>
              </Media>
              <Media gap={2} image={<BadgeOutlined />}>
                <Typography
                  variant="body2"
                  color={
                    ipvDisabledReason ? "rgba(0 0 0 / .23)" : "text.secondary"
                  }
                >
                  {t(
                    "Request approval to visit before traveling to the facility. You will need to show ID on arrival."
                  )}
                </Typography>
              </Media>
              {willBeConfidential && (
                <Media
                  gap={2}
                  image={
                    <Tooltip
                      title={privacyLevelDescription(
                        MeetingType.InPersonVisit,
                        PrivacyLevel.UnmonitoredLogged
                      )}
                    >
                      <LockOutlined />
                    </Tooltip>
                  }
                >
                  <Typography
                    variant="body2"
                    color={
                      ipvDisabledReason ? "rgba(0 0 0 / .23)" : "text.secondary"
                    }
                  >
                    {t("Confidential")}
                  </Typography>
                </Media>
              )}
            </Stack>
          </EventTypeCard>
        </Box>
      </Box>
    </StepperFormWrapper>
  );
}
