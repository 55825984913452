import { Table, TableBody, TableHead } from "@ameelio/ui";
import {
  Alert,
  Box,
  CircularProgress,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ConnectionStatus, FacilityFeature } from "../api/graphql";
import { TableSkeleton } from "../lib/closet";
import { AvailableCountry } from "../lib/DocumentType";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { ScreenTitle } from "../lib/typography";
import { ltrTheme } from "../theme";
import ConnectionsFlowWarning from "./ConnectionsFlowWarning";
import FacilityFeaturesDialog from "./FacilityFeaturesDialog";
import FacilityFeaturesDialogButton from "./FacilityFeaturesDialogButton";
import MinorsAlert from "./MinorsAlert";
import { SearchCriteria, SearchResult, SingleSearchCriteria } from "./types";
import useConnectionStatusTooltipCopy from "./useConnectionStatusTooltipCopy";
import useCurrentConnectionStatus from "./useCurrentConnectionStatus";
import { useInmateSearch } from "./useInmateSearch";

type Props = {
  stepper: StepperType;
  partneredFacilityIds: string[];
  searchCriteria: SearchCriteria;
  onSubmit: (selected: SearchResult) => void | Promise<void>;
  onSearchMinors: () => void;
  onResultSelected: (selected: SearchResult) => void | Promise<void>;
};

export default function ConnectionsSelectOneForm({
  stepper,
  partneredFacilityIds,
  searchCriteria,
  onSubmit,
  onSearchMinors,
  onResultSelected,
}: Props) {
  const { t } = useTranslation();
  const [showFacilityFeatures, setShowFacilityFeatures] = useState(false);
  const [facilityFeaturesData, setFacilityFeaturesData] = useState<{
    name: string;
    features: FacilityFeature[];
  }>();
  const [selectedId, setSelectedId] = useState<string>();
  const {
    handleSubmit: handleFormSubmit,
    formState: { isSubmitting },
  } = useForm();

  const { results, minorsOmitted, loading, error } =
    useInmateSearch(searchCriteria);

  if (error) throw error;

  const currentConnectionStatus = useCurrentConnectionStatus();

  const partneredResults = results.filter(({ facilityId }) =>
    partneredFacilityIds.includes(facilityId)
  );
  const doSubmit = async () => {
    const selected = results.find((r) => r.id === selectedId);
    if (!selected) return;
    await onSubmit(selected);
  };

  const getTooltipCopy = useConnectionStatusTooltipCopy();

  if (loading) return <TableSkeleton />;

  return (
    <>
      {showFacilityFeatures && (
        <FacilityFeaturesDialog
          onClose={() => {
            setShowFacilityFeatures(false);
            setFacilityFeaturesData(undefined);
          }}
          data={facilityFeaturesData}
        />
      )}
      <StepperFormWrapper
        stepper={stepper({
          loading: isSubmitting,
          disabled: !selectedId,
        })}
        handleSubmit={handleFormSubmit(doSubmit)}
      >
        {partneredResults.length > 0 ? (
          <>
            <ScreenTitle>
              {t("We found {{count}} results for ‘{{lastNameOrInmateId}}’", {
                // TODO: handling singular/plural in english means we need to add english translations
                lastNameOrInmateId:
                  searchCriteria &&
                  (searchCriteria as SingleSearchCriteria).lastNameOrInmateId,
                count: results.length,
              })}
            </ScreenTitle>
            <Typography mt={2}>
              {t(
                "Confirm who you'd like to request by selecting their name below."
              )}
            </Typography>
            <Box my={6}>
              {minorsOmitted && <MinorsAlert onClick={onSearchMinors} />}
              <RadioGroup
                value={selectedId}
                onChange={(_, value) => {
                  setSelectedId(value);
                  const res = results.find((r) => r.id === value);
                  if (res) onResultSelected(res);
                }}
                sx={{
                  maxWidth: "100%",
                  overflow: "auto",
                  backgroundColor: "white",
                  maxHeight: { lg: "250px", xs: "400px" },
                }}
              >
                <Table
                  sx={{
                    minWidth:
                      ltrTheme.breakpoints.values.sm -
                      parseInt(ltrTheme.spacing(6), 10),
                  }}
                  stickyHeader
                >
                  <TableHead>
                    <TableCell />
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell>{t("Facility")}</TableCell>
                    <TableCell>{t("ID")}</TableCell>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {partneredResults.map((r) => (
                      <Tooltip
                        key={r.id}
                        followCursor
                        arrow
                        placement="top"
                        title={getTooltipCopy(currentConnectionStatus(r.id))}
                      >
                        <TableRow>
                          <TableCell>
                            <Radio
                              disabled={[
                                ConnectionStatus.Active,
                                ConnectionStatus.Pending,
                              ].includes(currentConnectionStatus(r.id))}
                              value={r.id}
                              inputProps={{
                                "aria-label": r.fullName,
                              }}
                            />
                          </TableCell>
                          <TableCell>{r.fullName}</TableCell>
                          <TableCell sx={{ wordBreak: "break-word" }}>
                            {r.facilityName} ({r.facilityPublicId})
                            <br />
                            <FacilityFeaturesDialogButton
                              onClick={() => {
                                setFacilityFeaturesData({
                                  name: `${r.facilityName} (${r.facilityPublicId})`,
                                  features: r.features,
                                });
                                setShowFacilityFeatures(true);
                              }}
                            />
                          </TableCell>
                          <TableCell>{r.inmateIdentification}</TableCell>
                        </TableRow>
                      </Tooltip>
                    ))}
                  </TableBody>
                </Table>
              </RadioGroup>
            </Box>
          </>
        ) : (
          <>
            <ScreenTitle>
              {t("No results matched your search ‘{{lastNameOrInmateId}}’", {
                lastNameOrInmateId:
                  searchCriteria &&
                  (searchCriteria as SingleSearchCriteria).lastNameOrInmateId,
              })}
            </ScreenTitle>
            <Box my={6}>
              {minorsOmitted && <MinorsAlert onClick={onSearchMinors} />}
              {!minorsOmitted &&
                searchCriteria.country === AvailableCountry.US &&
                searchCriteria.province && (
                  <Alert severity="warning">
                    <ConnectionsFlowWarning
                      province={searchCriteria.province}
                      defaultMessage={t(
                        "We recommend going back to refine your search. If you are not sure if this person has been released or transferred to a different state, you can look up people currently incarcerated in {{stateName}} using their online tool <resourceLink>{{resourceName}}</resourceLink>."
                      )}
                    />
                  </Alert>
                )}
            </Box>
          </>
        )}
      </StepperFormWrapper>
    </>
  );
}
