import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import IdentificationScreen from "./IdentificationScreen";
import SettingsScreen from "./SettingsScreen";

const SettingsRoutes: RouteObject = {
  path: "/settings",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <MenuLayout currentTab="settings">
          <SettingsScreen />
        </MenuLayout>
      ),
    },
    { path: "identification", element: <IdentificationScreen /> },
  ],
};

export default SettingsRoutes;
