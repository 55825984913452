import { PasswordInput, SubmitButton, TextInput } from "@ameelio/ui";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Link from "../lib/Link";
import {
  isEmail,
  isPassword,
  isPasswordConfirmation,
  isRequiredString,
  mergeRules,
} from "../lib/validate";

export type CredentialsStepData = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

type Props = {
  defaultValues: CredentialsStepData;
  onSubmit: (data: CredentialsStepData) => void | Promise<void>;
  disableEmailField?: boolean;
  from?: string;
};

export default function CredentialsSignupForm({
  defaultValues,
  onSubmit,
  disableEmailField,
  from,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CredentialsStepData>({
    mode: "onTouched",
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          email: data.email.trim(),
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
        })
      )}
    >
      <Box marginTop={2} marginBottom={6}>
        <Typography variant="body1">
          {t(
            "Welcome to Ameelio Connect! First, please enter your desired login information."
          )}
        </Typography>
      </Box>
      <Stack spacing={2} marginBottom={6}>
        <TextInput
          control={control}
          name="email"
          type="email"
          autoComplete={disableEmailField ? "off" : "email"}
          label={t("Email address")}
          rules={mergeRules(
            isRequiredString(t("Please enter a valid email address.")),
            isEmail()
          )}
          disabled={disableEmailField}
        />
        <PasswordInput
          control={control}
          name="password"
          label={t("Password")}
          rules={isPassword()}
          autoComplete="new-password"
          helperText={t(
            "Create a strong password with a mix of letters, numbers and symbols."
          )}
        />
        <PasswordInput
          control={control}
          name="passwordConfirmation"
          label={t("Confirm password")}
          autoComplete="off"
          rules={isPasswordConfirmation("password")}
        />
      </Stack>
      <Grid container justifyContent="space-between" alignItems="center">
        <Link button to="/login" state={{ from }} variant="outlined">
          {t("Sign in instead")}
        </Link>
        <SubmitButton submitting={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
