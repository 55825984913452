import { Button } from "@ameelio/ui";
import { useTranslation } from "react-i18next";
import useBoolean from "../lib/useBoolean";
import InviteMemberDialog from "./InviteMemberDialog";

type Props = {
  isAdmin: boolean;
  organizationId: string;
  organizationInvitationId: string;
};

export default function RegenerateInvitationLinkButton({
  isAdmin,
  organizationId,
  organizationInvitationId,
}: Props) {
  const { t } = useTranslation();
  const dialog = useBoolean();

  return (
    <>
      {dialog.current && (
        <InviteMemberDialog
          organizationId={organizationId}
          organizationInvitationId={organizationInvitationId}
          onClose={dialog.disable}
        />
      )}
      <Button
        disabled={!isAdmin}
        variant="outlined"
        disabledReason={t("Only organization admins may invite members")}
        onClick={dialog.enable}
      >
        {t("Regenerate link")}
      </Button>
    </>
  );
}
