import { Avatar, ContextMenu } from "@ameelio/ui";
import { Box, Card, MenuItem, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ConnectionRestriction,
  ConnectionStatus,
  Correspondent,
  Connection as GQLConnection,
} from "../api/graphql";
import { DateInterval } from "../lib/dateUtils";
import Link from "../lib/Link";
import VoiceCallsButton from "../VoiceCall/VoiceCallsButton";
import ConnectionStatusChip from "./ConnectionStatusChip";

type Connection = Pick<GQLConnection, "id" | "status"> & {
  activeRestrictions: Pick<ConnectionRestriction, "id" | "service">[];
  contact: Pick<
    Correspondent,
    | "firstName"
    | "lastName"
    | "fullName"
    | "unavailableUntil"
    | "canReceiveVoiceCalls"
  >;
};

export type Props = {
  showVoiceCall: boolean;
  showMessage: boolean;
  mayMakeVoiceCall: boolean;
  showScheduleEvent: boolean;
  nextVoiceCallSlot: DateInterval | null;
  connection: Connection;
};

export default function ConnectionCard({
  showVoiceCall,
  showMessage,
  showScheduleEvent,
  mayMakeVoiceCall,
  nextVoiceCallSlot,
  connection,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hasVoiceCallRestriction = Boolean(
    connection.activeRestrictions.find((r) => r.service === "VOICE_CALL")
  );

  const actions = [
    showMessage && (
      <MenuItem
        key="action-message"
        onClick={() => navigate(`/messages/${connection.id}`)}
      >
        {t("Message")}
      </MenuItem>
    ),
    showScheduleEvent && (
      <MenuItem
        key="action-schedule-event"
        onClick={() =>
          navigate("/events/new", {
            state: {
              connectionId: connection.id,
              fromPath: `/contacts`,
              fromName: t("Contacts"),
            },
          })
        }
      >
        {t("Schedule event")}
      </MenuItem>
    ),
  ].filter(Boolean);

  return (
    <Card variant="outlined" sx={{ px: { xs: 1, sm: 2 }, py: 2 }}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={2}
        sx={{ maxWidth: "100%", overflow: "auto" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar contact={connection.contact} />
          <Link to={`/contacts/${connection.id}`}>
            {`${connection.contact.fullName}`}
          </Link>
          {[ConnectionStatus.Pending, ConnectionStatus.Rejected].includes(
            connection.status
          ) && <ConnectionStatusChip status={connection.status} />}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} component="nav">
          {showVoiceCall && (
            <VoiceCallsButton
              showButtonText
              mayMakeVoiceCall={mayMakeVoiceCall}
              hasRestriction={hasVoiceCallRestriction}
              nextVoiceCallSlot={nextVoiceCallSlot}
              connectionId={connection.id}
              contact={connection.contact}
            />
          )}
          {actions.length > 0 && (
            <ContextMenu id={`connection-${connection.id}`}>
              {actions}
            </ContextMenu>
          )}
        </Stack>
      </Box>
    </Card>
  );
}
