import { Typography, TypographyProps } from "@mui/material";

type Props = Omit<TypographyProps<"blockquote">, "component">;

export default function Quotation({ children, sx, ...rest }: Props) {
  return (
    <Typography
      component="blockquote"
      variant="body1"
      color="text.primary"
      {...rest}
    >
      &quot;{children}&quot;
    </Typography>
  );
}
