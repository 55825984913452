import { Button } from "@ameelio/ui";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FourOFour from "./404.svg";
import FullscreenBox from "./FullscreenBox";
import { desktopAndUp } from "./lib/responsiveHelpers";
import { ScreenTitle } from "./lib/typography";

export default function NotFoundScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDesktop = desktopAndUp(useMediaQuery);

  return (
    <FullscreenBox
      sx={{
        backgroundColor: "#FAFAFA",
      }}
    >
      <Stack
        spacing={6}
        sx={{
          marginTop: isDesktop ? "160px" : 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={FourOFour} alt="" width="258" />
        <ScreenTitle>{t("Page not found")}</ScreenTitle>
        <Typography textAlign="center">
          {t("The page you're looking for doesn't exist")}
        </Typography>
        <Button variant="text" onClick={() => navigate("/")}>
          {t("Return home")}
        </Button>
      </Stack>
    </FullscreenBox>
  );
}
