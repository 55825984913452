import {
  Dialog,
  DialogActions,
  DialogContent,
  SelectInputBase,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { sub } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Language } from "../api/graphql";
import DateInput from "../lib/DateInput";
import useApolloErrorHandler from "../lib/handleApolloError";
import { languageLabel, languageToI18n } from "../lib/Language";
import getLanguageDirection from "../lib/languageDir";
import { PrimaryButton, SecondaryButton } from "../lib/ModalButtons";
import { dateToIso } from "../lib/timeFormats";
import {
  isDateOfBirth,
  isPhoneNumber,
  isRequired,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_USER_AGE,
  mergeRules,
} from "../lib/validate";
import { useCurrentUser } from "../SessionBoundary";
import { SetLanguagePreferenceDocument } from "./SetLanguagePreference.generated";
import { UpdateVisitorDocument } from "./UpdateVisitor.generated";
import { UpdateVisitorIdentityDocument } from "./UpdateVisitorIdentity.generated";

export type EditFields =
  | "name_dob"
  | "email_phone"
  | "identification"
  | "language";

type EditProps = Omit<Props, "editFields">;

type EditNameAndDOBData = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
};

function EditNameAndDOBDialog({ isOpen, defaultValues, onClose }: EditProps) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<EditNameAndDOBData>({
    mode: "onChange",
    defaultValues,
  });

  const [updateVisitorIdentity, { loading: updateLoading }] = useMutation(
    UpdateVisitorIdentityDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: () => {
        snackbarContext.alert("info", t("Information saved."));
        onClose();
      },
    }
  );

  const updateNameAndDOB = async (formData: EditNameAndDOBData) => {
    const { firstName, lastName, dateOfBirth } = formData;

    await updateVisitorIdentity({
      variables: {
        input: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          dateOfBirth: dateToIso(new Date(dateOfBirth)),
        },
      },
    });
  };

  if (!isOpen) return null;

  return (
    <Dialog title={t("Name and date of birth")} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" color="text.primary">
          {t(
            "Make sure this information is accurate and matches the ID you provided."
          )}
        </Typography>
        <Stack spacing={2} mt={3}>
          <TextInput
            control={control}
            name="firstName"
            type="text"
            autoComplete="given-name"
            label={t("First name")}
            sx={{ width: 1 }}
            rules={isRequired(t("Please enter your first name."))}
          />
          <TextInput
            control={control}
            name="lastName"
            autoComplete="family-name"
            type="text"
            label={t("Last name")}
            sx={{ width: 1 }}
          />
          <DateInput
            control={control}
            name="dateOfBirth"
            autoComplete="bday"
            label={t("Date of birth")}
            disableFuture
            disableHighlightToday
            minDate={sub(new Date(), { years: MAX_USER_AGE, days: 364 })}
            rules={isDateOfBirth()}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>{t("Cancel")}</SecondaryButton>
        <PrimaryButton
          disabled={!isValid || isSubmitting || updateLoading}
          onClick={handleSubmit((data) => updateNameAndDOB(data))}
        >
          {t("Save")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

type EditEmailAndPhoneData = {
  email: string;
  phone: string;
};

function EditEmailAndPhoneDialog({
  isOpen,
  defaultValues,
  onClose,
}: EditProps) {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const handleApolloError = useApolloErrorHandler();

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<EditEmailAndPhoneData>({
    mode: "onChange",
    defaultValues,
  });

  const [updateUser, { loading: updateLoading }] = useMutation(
    UpdateVisitorDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: onClose,
    }
  );

  const updatePhoneAndEmail = async (formData: EditEmailAndPhoneData) => {
    const { phone } = formData;
    await updateUser({
      variables: {
        input: {
          visitorId: user.id,
          phone,
        },
      },
    });
  };

  if (!isOpen) return null;

  return (
    <Dialog title={t("Email and phone number")} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2} mt={2}>
          <Tooltip
            key="change-email"
            followCursor
            arrow
            placement="top"
            title={t("Changing email address is not supported")}
          >
            <span>
              <TextInput
                control={control}
                disabled // Not allowing editing email at present
                name="email"
                type="email"
                label={t("Email")}
                sx={{ width: 1 }}
                rules={isRequired(t("Please enter a valid email address."))}
              />
            </span>
          </Tooltip>
          <TextInput
            control={control}
            name="phone"
            type="tel"
            autoComplete="tel"
            label={t("Phone number")}
            inputProps={{ maxLength: MAX_PHONE_NUMBER_LENGTH }}
            sx={{ width: { xs: 1, sm: 400, md: 500 } }}
            rules={mergeRules(
              isRequired(t("Please enter your phone number.")),
              isPhoneNumber()
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>{t("Cancel")}</SecondaryButton>
        <PrimaryButton
          disabled={!isValid || isSubmitting || updateLoading}
          onClick={handleSubmit((data) => updatePhoneAndEmail(data))}
        >
          {t("Save")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

function EditLanguageDialog({ isOpen, defaultValues, onClose }: EditProps) {
  const { t, i18n } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    defaultValues.language
  );
  const handleApolloError = useApolloErrorHandler();

  const [mutateLanguagePreference] = useMutation(
    SetLanguagePreferenceDocument,
    {
      onCompleted: () => {
        onClose();
        snackbarContext.alert("info", t("Language preference saved."));
      },
      onError: (e) => handleApolloError(e),
    }
  );

  const handleLanguageChange = async () => {
    await i18n.changeLanguage(languageToI18n[selectedLanguage as Language]);
    document.documentElement.lang = i18n.language;
    await mutateLanguagePreference({
      variables: {
        input: {
          language: selectedLanguage as Language,
        },
      },
    });
    i18n.dir(
      getLanguageDirection(languageToI18n[selectedLanguage as Language])
    );
  };

  if (!isOpen) return null;

  return (
    <Dialog title={t("Language")} onClose={onClose}>
      <DialogContent>
        <Stack spacing={3} mt={1} mb={0}>
          <SelectInputBase
            sx={{ width: { xs: 250, sm: 350, md: 500 } }}
            label={t("Language")}
            value={selectedLanguage}
            onChange={(event: SelectChangeEvent<unknown>) =>
              setSelectedLanguage(event.target.value as Language)
            }
            items={[
              { value: Language.En, name: languageLabel(Language.En) },
              { value: Language.Es, name: languageLabel(Language.Es) },
              { value: Language.It, name: languageLabel(Language.It) },
            ]}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>{t("Cancel")}</SecondaryButton>
        <PrimaryButton onClick={handleLanguageChange}>
          {t("Save")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

type Props = {
  isOpen: boolean;
  editFields: EditFields;
  defaultValues: EditNameAndDOBData &
    EditEmailAndPhoneData & { language: Language };
  onClose: () => void;
};

export default function EditProfileInfoDialog({
  isOpen,
  editFields,
  defaultValues,
  onClose,
}: Props) {
  if (!isOpen) return null;
  if (editFields === "name_dob") {
    return (
      <EditNameAndDOBDialog
        isOpen={isOpen}
        defaultValues={defaultValues}
        onClose={onClose}
      />
    );
  }
  if (editFields === "email_phone") {
    return (
      <EditEmailAndPhoneDialog
        isOpen={isOpen}
        defaultValues={defaultValues}
        onClose={onClose}
      />
    );
  }
  if (editFields === "language") {
    return (
      <EditLanguageDialog
        isOpen={isOpen}
        defaultValues={defaultValues}
        onClose={onClose}
      />
    );
  }
  return null;
}
