import { Box } from "@mui/material";
import PendingOrganizationInvitation from "../lib/PendingOrganizationInvitationReminder";
import ProvideYourIdReminder from "../lib/ProvideYourIdReminder";
import useConnectionRequestRejections, {
  RejectedConnectionAlert,
} from "../lib/useConnectionRequestRejections";
import useIdRejectionStatus, {
  RejectedIDAlert,
} from "../lib/useIdRejectionStatus";
import useRemindMinorToProvideId from "../lib/useRemindMinorToProvideId";
import { useCurrentCorrespondent } from "../SessionBoundary";

export default function UserAlerts() {
  const user = useCurrentCorrespondent();
  const rejectedId = useIdRejectionStatus();
  const rejectedConnections = useConnectionRequestRejections();

  const { showProvideIdReminder, reminderDueDate } = useRemindMinorToProvideId({
    user,
  });

  return (
    <>
      {user.__typename === "Visitor" &&
        user.organizationInvitation &&
        user.organizationInvitation?.organization && (
          <Box mb={2}>
            <PendingOrganizationInvitation
              organization={user.organizationInvitation.organization}
              inviteCode={user.organizationInvitation.inviteCode}
            />
          </Box>
        )}
      {/* Remind minors to provide an ID */}
      {showProvideIdReminder && (
        <Box mb={2}>
          <ProvideYourIdReminder dueDate={reminderDueDate} />
        </Box>
      )}
      {/* Rejected ID or connection request */}
      {rejectedConnections.map((c) => (
        <Box key={c.id} mb={2}>
          <RejectedConnectionAlert id={c.id} name={c.inmate.fullName} />
        </Box>
      ))}
      {rejectedId && (
        <Box mb={2}>
          <RejectedIDAlert />
        </Box>
      )}
    </>
  );
}
