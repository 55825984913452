/* eslint-disable jsx-a11y/media-has-caption */
import {
  CallStatus,
  ClientStatus,
  OutputConnectionState,
} from "@ameelio/connect-call-client";
import { Box, SxProps, Theme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ContainedAspectRatio from "../../../lib/ContainedAspectRatio";
import { ltrTheme } from "../../../theme";
import { ConnectionStrength } from "./ConnectionStrength";
import ParticipantAudioStatus from "./ParticipantAudioStatus";
import UserVideoNameTag from "./UserVideoNameTag";
import {
  AvatarVideoPlaceholderWithAudio,
  FruxVideoPlaceholder,
  TextVideoPlaceholder,
} from "./VideoPlaceholders";

type Placeholder = "text" | "frux"; // frux = First-Responder User Experience

const DEFAULT_TRANSITION_DURATION_MS = 200;

type Props = {
  user: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  userNameTag?: string;
  origin: "local" | "remote";
  audioStream: MediaStream | undefined;
  stream: MediaStream | undefined;
  audioMuted?: boolean;
  videoPaused?: boolean;
  callStatus?: CallStatus;
  clientStatus?: ClientStatus;
  connectionState?: OutputConnectionState;
  canControlAudio?: boolean;
  onMute?: () => void;
  onUnmute?: () => void;
  containerSx?: SxProps<Theme>;
  fit?: "contain" | "cover";
  transitionDuration?: number;
  mirrored?: boolean;
};

/**
 * Displays html5 video in a styled container. Fills containing element using 'best-fit' approach.
 */
export default function VideoParticipant({
  user,
  userNameTag,
  origin,
  audioStream,
  stream,
  audioMuted,
  videoPaused,
  callStatus,
  clientStatus,
  connectionState,
  canControlAudio,
  onMute,
  onUnmute,
  containerSx,
  fit,
  transitionDuration,
  mirrored,
}: Props) {
  const { t } = useTranslation();
  // Attach the stream to a video element for playing
  const refVideo = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (!refVideo.current || !stream) return;
    refVideo.current.srcObject = stream;
    // intentionally including more deps than appear
    // necessary - we WANT the src object to be potentially
    // updated anytime clientStatus or videoPaused change
  }, [refVideo, stream, clientStatus, videoPaused]);

  // remember if video was disabled due to a bad connection so we can
  // maintain the alert until the connection clears and the user enables
  // video again.
  //
  // NOTE: can't useMemo because the logic depends on changes
  const badConnection = connectionState?.badConnection;
  const [fruxDisabledVideo, setFruxDisabledVideo] = useState(false);
  useEffect(() => {
    if (badConnection && videoPaused) setFruxDisabledVideo(true);
    if (!videoPaused) setFruxDisabledVideo(false);
  }, [badConnection, videoPaused]);

  // determine if any placeholder messages should appear
  const [placeholder, setPlaceholder] = useState<Placeholder>();
  const [placeholderMsg, setPlaceholderMsg] = useState<string>("");
  useEffect(() => {
    if (badConnection) {
      setPlaceholder("frux");
      setPlaceholderMsg(
        origin === "local"
          ? t("We turned off your video because your connection is unstable")
          : t("We turned off their video because their connection is unstable")
      );
      return;
    }
    if (origin === "local" && stream && fruxDisabledVideo) {
      setPlaceholder("frux");
      setPlaceholderMsg(
        t("Your signal is stronger and you can turn your video back on")
      );
      return;
    }
    if (origin === "remote" && callStatus === "missing_monitor") {
      setPlaceholder("text");
      setPlaceholderMsg(
        t("Your call will resume once the monitor joins the room...")
      );
      return;
    }
    if (!stream && !audioStream) {
      setPlaceholderMsg(t("Connecting..."));
      return;
    }
    if (origin === "remote" && clientStatus !== ClientStatus.connected) {
      setPlaceholder("text");
      setPlaceholderMsg(t("Disconnected"));
      return;
    }
    setPlaceholder(undefined);
    setPlaceholderMsg("");
  }, [
    t,
    origin,
    audioStream,
    stream,
    callStatus,
    clientStatus,
    badConnection,
    fruxDisabledVideo,
    videoPaused,
  ]);

  // TODO: also enable interaction layer on tap, for touch
  // devices.
  const [hovered, setHovered] = useState(false);
  const interactable = !!(hovered && onMute && onUnmute);

  // we can't get any information about remote video tracks without playing
  // them due to a limitation with MediaStreamTrack.getSettings() in Firefox.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1537986#c21
  const [videoAspectRatio, setVideoAspectRatio] = useState<number>(1);
  const onLoadedMetadata = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    // when a video becomes paused, i sometimes observe width=2 height=2
    if (e.currentTarget.videoWidth < 10) return;
    setVideoAspectRatio(
      e.currentTarget.videoWidth / e.currentTarget.videoHeight
    );
  };

  // annoying. should we pass border radius separately? or standardize
  // the radius size in FloatingSelfieVideo?
  const borderRadius =
    containerSx &&
    "borderRadius" in containerSx &&
    (typeof containerSx.borderRadius === "number" ||
      typeof containerSx.borderRadius === "string")
      ? containerSx.borderRadius
      : 4;

  return (
    <ContainedAspectRatio
      ratio={videoAspectRatio}
      fit={fit}
      sx={{
        transform: "translate3d(0,0,0)",
        transition: `all ${
          transitionDuration ?? DEFAULT_TRANSITION_DURATION_MS
        }ms ease-in-out`,
        position: "relative",
        borderRadius,
        ...containerSx,
      }}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <video
        ref={refVideo}
        autoPlay
        muted
        width="100%"
        height="100%"
        style={{
          display: videoPaused ? "none" : "block",
          transform: mirrored ? "rotateY(180deg)" : undefined,
          objectFit: fit,
        }}
        onLoadedMetadata={onLoadedMetadata}
      />
      {connectionState && !placeholder && (
        <Box
          sx={{ position: "absolute", bottom: 0, right: ltrTheme.spacing(1) }}
        >
          <ConnectionStrength
            connectionState={connectionState}
            origin={origin}
          />
        </Box>
      )}
      {placeholder === "text" && (
        <TextVideoPlaceholder message={placeholderMsg} />
      )}
      {placeholder === "frux" && (
        <FruxVideoPlaceholder
          message={placeholderMsg}
          quality={connectionState?.quality}
        />
      )}
      {videoPaused && !placeholder && (
        <AvatarVideoPlaceholderWithAudio
          user={user}
          stream={audioStream}
          sx={{ borderRadius }}
        />
      )}
      {userNameTag && <UserVideoNameTag nameTag={userNameTag} />}
      <ParticipantAudioStatus
        stream={audioStream}
        audioMuted={!!audioMuted}
        borderRadius={borderRadius}
        showAudioControls={canControlAudio && interactable}
        onMute={onMute}
        onUnmute={onUnmute}
      />
    </ContainedAspectRatio>
  );
}
