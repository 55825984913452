import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardHeader,
  ClickAwayListener,
  Fade,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useApolloErrorHandler from "../lib/handleApolloError";
import { belowLargeTablet } from "../lib/responsiveHelpers";
import { useCurrentUser } from "../SessionBoundary";
import { getBGColor } from "../theme";
import { GetUnreadNotificationCountDocument } from "./GetUnreadNotificationCount.generated";
import { MarkUnreadNotificationsAsReadDocument } from "./MarkUnreadNotificationsAsRead.generated";
import NotificationsBadge from "./NotificationsBadge";
import { NotificationsList } from "./NotificationsList";

const listStyle: SxProps<Theme> = {
  width: (theme) => theme.spacing(50),
  position: "absolute",
  mt: 1,
  right: 0,
  zIndex: "modal",
  display: "flex",
  flexDirection: "column",
  borderWidth: "0.5px",
  borderStyle: "solid",
  borderColor: (theme) => getBGColor(theme.palette.mode)("#000", 0.88),
};

export default function Notifications() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(false);
  const handleApolloError = useApolloErrorHandler();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);
  const onError = useApolloErrorHandler();

  const { data: d, error } = useQuery(GetUnreadNotificationCountDocument, {
    onError,
    fetchPolicy: "cache-and-network",
  });

  const unreadCount: number = error
    ? 0
    : d?.currentCorrespondent?.notificationEvents.unreadNotificationCount || 0;

  const [markUnreadNotificationsAsRead] = useMutation(
    MarkUnreadNotificationsAsReadDocument,
    {
      onError: (e) => handleApolloError(e),
      optimisticResponse: d?.currentCorrespondent
        ? {
            __typename: "Mutation",
            markUnreadNotificationsAsRead: {
              __typename: "MarkUnreadNotificationsAsReadResponse",
              currentCorrespondent: {
                __typename: d?.currentCorrespondent.__typename,
                id: d?.currentCorrespondent.id,
                notificationEvents: {
                  __typename: "NotificationEventConnection",
                  unreadNotificationCount: 0,
                },
              },
              notificationEvents: [],
            },
          }
        : undefined,
    }
  );

  const currentUser = useCurrentUser();
  const canHaveNotifications = ["Visitor", "Inmate"].includes(
    currentUser?.__typename
  );

  if (isMobileOrSmallTablet && canHaveNotifications) {
    return (
      <Box sx={{ position: "relative" }}>
        <NotificationsBadge
          unreadCount={unreadCount}
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(false);
            navigate("/notifications");
            if (unreadCount) markUnreadNotificationsAsRead();
          }}
        />
      </Box>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box sx={{ position: "relative" }} role="presentation">
        <NotificationsBadge
          unreadCount={unreadCount}
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
            setShowList(true);
          }}
        />
        <Fade
          in={isOpen}
          onExited={() => {
            setShowList(false);
            if (unreadCount) markUnreadNotificationsAsRead();
          }}
          style={{ transitionDelay: "50ms" }}
        >
          <Card
            elevation={1}
            sx={{
              ...listStyle,
            }}
          >
            <CardHeader title={t("Notifications")} />
            {showList && <NotificationsList />}
          </Card>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
}
