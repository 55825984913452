import { Button } from "@ameelio/ui";
import { Alert, Box, Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Facility, Inmate, Kiosk, MeetingType } from "../../../api/graphql";
import removeIndex from "../../../lib/removeIndex";
import StepperFormWrapper from "../../../lib/StepperFormWrapper";
import { StepperType } from "../../../lib/StepperWrapper";
import { ScreenTitle } from "../../../lib/typography";
import useBoolean from "../../../lib/useBoolean";
import { getMaxGuestsForCapacity } from "../SelectTimeStep";
import {
  GuestListData,
  MeetingData,
  RegisteredGuest,
  UnregisteredGuest,
} from "../types";
import { WorkflowAction } from "../useWorkflowSteps";
import AddGuestDialog from "./AddGuestDialog";
import GuestCard from "./GuestCard";
import LearnMoreDialog from "./LearnMoreDialog";

type Props = {
  stepper: StepperType;
  action: WorkflowAction;
  defaultValues: {
    meetingType: MeetingData["meetingType"];
    registeredGuests?: RegisteredGuest[];
    unregisteredGuests?: UnregisteredGuest[];
    visitGuestRegistrationRequired?: boolean;
    kiosk?: Pick<Kiosk, "id" | "capacity">;
  };
  inmate: Pick<Inmate, "id" | "firstName"> & {
    facility: Pick<Facility, "visitGuestRegistrationRequired">;
  };
  onSubmit: (data: GuestListData) => void;
};

export default function PersonalAddGuestListStep({
  stepper,
  action,
  defaultValues,
  inmate,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const isEdit = action === "edit";

  // used for managing and validating the guest list
  const maxGuests = defaultValues.kiosk
    ? getMaxGuestsForCapacity(defaultValues.kiosk.capacity)
    : null;

  // used to display the Learn More dialog
  const [showLearnMore, setShowLearnMore] = useState<boolean>(false);

  // manage guests
  const guestListDialog = useBoolean();
  const [registeredGuests, setRegisteredGuests] = useState<RegisteredGuest[]>(
    defaultValues.registeredGuests || []
  );
  const onAddRegisteredGuest = (newGuest: RegisteredGuest) => {
    setRegisteredGuests([...registeredGuests, newGuest]);
    guestListDialog.disable();
  };

  const [unregisteredGuests, setUnregisteredGuests] = useState<
    UnregisteredGuest[]
  >(defaultValues.unregisteredGuests || []);
  const onAddUnregisteredGuest = (newGuest: UnregisteredGuest) => {
    setUnregisteredGuests([...unregisteredGuests, newGuest]);
    guestListDialog.disable();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({ registeredGuests, unregisteredGuests });
  };

  const registrationRequired =
    defaultValues.meetingType === MeetingType.VideoCall ||
    inmate.facility.visitGuestRegistrationRequired;

  return (
    <StepperFormWrapper
      stepper={stepper({ disabled: false, loading: false })}
      handleSubmit={handleSubmit}
    >
      <ScreenTitle>{t("Guest list")}</ScreenTitle>
      <Typography variant="body1" color="text.primary" mt={2}>
        {t("Guests must be added to the list for approval.")}{" "}
        {registrationRequired &&
          t(
            "Registered Connect accounts are required for guests ages 13 and older."
          )}{" "}
        {isEdit &&
          maxGuests &&
          t(
            "The current meeting time can accommodate up to {{count}} guests, if you add more you will need to choose a new time.",
            { count: maxGuests }
          )}
      </Typography>
      <Button
        variant="text"
        onClick={() => setShowLearnMore(true)}
        sx={{
          my: 2,
          px: 0,
          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
      >
        {t("Learn more about bringing guests")}
      </Button>
      {isEdit && (
        <Box my={3}>
          <Alert severity="warning">
            {t(
              "Some facilities require reapproval for events with new guests. Requests are approved in most cases."
            )}
          </Alert>
        </Box>
      )}
      {unregisteredGuests.length === 0 && registeredGuests.length === 0 ? (
        <Card variant="outlined" sx={{ my: 4, textAlign: "center" }}>
          <CardContent>{t("No guests yet")}</CardContent>
        </Card>
      ) : (
        <Box my={4}>
          {registeredGuests.map((guest, index) => (
            <GuestCard
              key={`${guest.email}`}
              title={guest.fullName}
              subtitle={guest.email}
              onRemove={() =>
                setRegisteredGuests(removeIndex(registeredGuests, index))
              }
            />
          ))}
          {unregisteredGuests.map((guest, index) => (
            <GuestCard
              // eslint-disable-next-line react/no-array-index-key
              key={`${guest.name}_${index}`}
              title={guest.name}
              subtitle={t("Unregistered")}
              onRemove={() =>
                setUnregisteredGuests(removeIndex(unregisteredGuests, index))
              }
            />
          ))}
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "right", mb: 6 }}>
        <Button variant="outlined" onClick={guestListDialog.enable}>
          {t("Add guest")}
        </Button>
      </Box>
      {guestListDialog.current && (
        <AddGuestDialog
          meetingType={defaultValues.meetingType}
          inmate={inmate}
          registrationRequired={!!registrationRequired}
          onAddRegisteredGuest={onAddRegisteredGuest}
          onAddUnregisteredGuest={onAddUnregisteredGuest}
          unregisteredGuests={unregisteredGuests}
          onClose={guestListDialog.disable}
        />
      )}
      {showLearnMore && (
        <LearnMoreDialog
          meetingType={defaultValues.meetingType}
          visitorType="personal"
          onClose={() => setShowLearnMore(false)}
        />
      )}
    </StepperFormWrapper>
  );
}
