import { SxProps, Theme } from "@mui/material";
import useTimeLeft from "../../lib/useTimeLeft";
import CallWidget from "./CallWidget";

const style: SxProps<Theme> = {
  fontStyle: "normal",
  fontWeight: 400,
  letterSpacing: "0.02px",
  fontFamily: '"Roboto Mono", monospace',
};

type Props = {
  startAt: number;
  endAt: number;
};

/**
 * Simple count-up/down
 */
export default function CallTimerWidget({ startAt, endAt }: Props) {
  const timeLeft = useTimeLeft({ startAt, endAt, countDownAt: 120000 });

  return <CallWidget sx={{ ...style }}>{timeLeft}</CallWidget>;
}
