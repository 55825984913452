import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import { Box, Button } from "@mui/material";
import { common } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { ltrTheme } from "../../../theme";

const iconStyles = {
  fontSize: 20,
};

const rootAudioStyles = {
  position: "absolute",
  display: "flex",
  top: ltrTheme.spacing(1),
  right: ltrTheme.spacing(1),
  p: "6px",
  borderRadius: "10000px",
  minWidth: 0,
};

export function AudioMutedIcon() {
  return (
    <Box
      sx={{
        ...rootAudioStyles,
        aspectRatio: "1",
        color: common.white,
        background: "rgba(0, 0, 0, 0.5)",
        "&:hover": {
          background: "rgba(0, 0, 0, 0.5)",
          color: common.white,
        },
      }}
    >
      <MicOffOutlinedIcon sx={iconStyles} />
    </Box>
  );
}

export function AudioActiveIcon() {
  return (
    <Box
      sx={{
        ...rootAudioStyles,
        aspectRatio: "1",
        color: common.white,
        background: ltrTheme.palette.primary.light,
        "&:hover": {
          background: ltrTheme.palette.primary.light,
        },
      }}
    >
      <GraphicEqOutlinedIcon sx={iconStyles} />
    </Box>
  );
}

export function AudioActiveFrame({
  borderRadius,
}: {
  borderRadius?: number | string;
}) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: 1,
        width: 1,
        border: 6,
        borderColor: "primary.light",
        borderRadius: borderRadius || 4,
        pointerEvents: "none",
      }}
    />
  );
}

type AudioToggleButtonProps = {
  audioMuted: boolean;
  onMute: () => void;
  onUnmute: () => void;
};

export function AudioToggleButton({
  audioMuted,
  onUnmute,
  onMute,
}: AudioToggleButtonProps) {
  const { t } = useTranslation();
  return (
    <Button
      onClick={audioMuted ? onUnmute : onMute}
      sx={{
        ...rootAudioStyles,
        px: 1,
        color: "text.primary",
        background: common.white,
        "&:hover": {
          background: common.white,
          color: "text.primary",
        },
      }}
    >
      {audioMuted ? (
        <>
          {t("Unmute")} <MicOffOutlinedIcon sx={iconStyles} />
        </>
      ) : (
        <>
          {t("Mute")} <MicOutlinedIcon sx={iconStyles} />
        </>
      )}
    </Button>
  );
}
