import { Outlet, RouteObject } from "react-router-dom";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";
import VideoCallScreen from "./VideoCallScreen";

const CallRoutes: RouteObject = {
  path: "/call/:id",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <ValidateUUID>
          <VideoCallScreen />
        </ValidateUUID>
      ),
    },
  ],
};

export default CallRoutes;
