import { Avatar, IconButton, Media } from "@ameelio/ui";
import { Info, InfoOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConnectionRestriction, Correspondent } from "../api/graphql";
import { DateInterval } from "../lib/dateUtils";
import VoiceCallsButton from "../VoiceCall/VoiceCallsButton";

type Props = {
  showVoiceCall: boolean;
  mayMakeVoiceCall: boolean;
  nextVoiceCallSlot: DateInterval | null;
  connection: {
    id: string;
    activeRestrictions: Pick<ConnectionRestriction, "id" | "service">[];
  };
  contact: Pick<
    Correspondent,
    | "firstName"
    | "lastName"
    | "fullName"
    | "unavailableUntil"
    | "canReceiveVoiceCalls"
  >;
  onSeeDetails: () => void;
  detailsOpen: boolean;
};

export default function ConnectionHeader({
  showVoiceCall,
  mayMakeVoiceCall,
  nextVoiceCallSlot,
  connection,
  contact,
  onSeeDetails,
  detailsOpen,
}: Props) {
  const { t } = useTranslation();
  const hasVoiceCallRestriction = Boolean(
    connection.activeRestrictions.find((r) => r.service === "VOICE_CALL")
  );
  return (
    <Box
      py={1.5}
      px={2}
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: "background.default",
        zIndex: "appBar", // 1100
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Media image={<Avatar contact={contact} />}>
        <Typography variant="body1">{contact.fullName}</Typography>
      </Media>
      <Box>
        {showVoiceCall && (
          <VoiceCallsButton
            connectionId={connection.id}
            contact={contact}
            mayMakeVoiceCall={mayMakeVoiceCall}
            hasRestriction={hasVoiceCallRestriction}
            nextVoiceCallSlot={nextVoiceCallSlot}
          />
        )}
        <IconButton ariaLabel={t("Info")} size="large" onClick={onSeeDetails}>
          {detailsOpen ? (
            <Info color="primary" />
          ) : (
            <InfoOutlined color="primary" />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}
