import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  onClick: () => void;
};

export default function FacilityFeaturesDialogButton({ onClick }: Props) {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link onClick={onClick} role="button" sx={{ cursor: "pointer" }}>
      {t("Learn more about available services")}
    </Link>
  );
}
