import { Tooltip } from "@ameelio/ui";
import { Box, Card, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

type Props = {
  disabled: boolean;
  selected: boolean;
  onClick: () => void;
  children: React.ReactNode;
  unavailableReason?: string;
};

export default function AvailabilityTile({
  disabled,
  selected,
  onClick,
  children,
  unavailableReason,
}: Props) {
  return (
    <Tooltip title={unavailableReason || ""}>
      <Card
        component={Box}
        elevation={0}
        tabIndex={0}
        sx={{
          padding: 2,
          textAlign: "center",
          border: 1,
          borderColor: selected ? "primary.main" : `rgba(0 0 0 / .12)`,
          borderRadius: (theme) => theme.spacing(1),
          cursor: disabled ? undefined : "pointer",
          backgroundColor: selected ? blue[50] : undefined,
          opacity: disabled ? 0.5 : 1,
          textDecoration: disabled ? "line-through" : undefined,
        }}
        onClick={disabled ? undefined : onClick}
        onKeyDown={(event) => {
          if (!disabled && event.key === "Enter") {
            onClick();
          }
        }}
      >
        <Typography variant="button">{children}</Typography>
      </Card>
    </Tooltip>
  );
}
