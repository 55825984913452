import { Button } from "@ameelio/ui";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, SxProps, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getBGColor } from "../theme";

const listOpenBellStyle: SxProps<Theme> = {
  background: (currentTheme) =>
    getBGColor(currentTheme.palette.mode)(currentTheme.palette.info.main, 0.88),
};

type BadgeProps = {
  unreadCount: number;
  isOpen?: boolean;
  onClick?: () => void;
};

export default function NotificationsBadge({
  unreadCount,
  isOpen,
  onClick,
}: BadgeProps) {
  const { t } = useTranslation();
  return (
    <Badge
      badgeContent={unreadCount}
      color="primary"
      overlap="circular"
      sx={{
        ".BaseBadge-badge": {
          top: "10%",
          right: "6%",
        },
      }}
    >
      <Button
        sx={{
          color: "primary.main",
          ...(isOpen ? listOpenBellStyle : {}),
        }}
        size="large"
        onClick={onClick}
        endIcon={<NotificationsNoneIcon />}
      >
        {t("Notifications")}
      </Button>
    </Badge>
  );
}
