import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetInviteOrganizationQueryVariables = Types.Exact<{
  inviteCode: Types.Scalars['String']['input'];
}>;


export type GetInviteOrganizationQuery = { __typename: 'Query', organizationInvitationByCode: { __typename: 'OrganizationInvitation', id: Types.Scalars['ID']['input'], email: string, organization: { __typename: 'Organization', id: Types.Scalars['ID']['input'], name: string, logoImageUrl: string | null } } | null };


export const GetInviteOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInviteOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationInvitationByCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"logoImageUrl"}}]}}]}}]}}]} as unknown as DocumentNode<GetInviteOrganizationQuery, GetInviteOrganizationQueryVariables>;