import { Button } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useApolloErrorHandler from "../lib/handleApolloError";
import { RevokeOrganizationInvitationDocument } from "./RevokeOrganizationInvitation.generated";

type Props = {
  isAdmin: boolean;
  organizationInvitationId: string;
};

export default function RevokeOrganizationInvitationButton({
  isAdmin,
  organizationInvitationId,
}: Props) {
  const { t } = useTranslation();

  const onError = useApolloErrorHandler();
  const [revoke, { loading }] = useMutation(
    RevokeOrganizationInvitationDocument,
    {
      variables: { input: { organizationInvitationId } },
      update: (cache, { data }) => {
        if (!data) return;
        cache.evict({
          id: cache.identify({
            __typename: "OrganizationInvitation",
            id: organizationInvitationId,
          }),
        });
      },
      onError,
    }
  );

  if (loading) return <Button disabled>{t("Revoke invite")}</Button>;

  return (
    <Button
      disabled={!isAdmin}
      variant="outlined"
      disabledReason={t("Only organization admins may invite members")}
      onClick={() => revoke()}
    >
      {t("Revoke invite")}
    </Button>
  );
}
