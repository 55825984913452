import { groupBy } from "@ameelio/core";
import { Button } from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { relativeDate } from "../lib/timeFormats";
import EventCard, { MeetingEvent } from "./EventCard";
import NoEventsScreen from "./NoEventsScreen";

type Props = {
  meetings: MeetingEvent[];
  onCancel: (args: { description: string; meetingId: string }) => void;
  onLeaveEvent: (args: { description: string; meetingId: string }) => void;
  noEventsMessage?: string;
  fetchOlder?: () => void;
  showDateInfo?: boolean;
};

export default function EventList({
  meetings,
  onCancel,
  onLeaveEvent,
  fetchOlder,
  showDateInfo,
  noEventsMessage,
}: Props) {
  const { t } = useTranslation();
  const groupedMeetings = groupBy(meetings, (m) =>
    relativeDate(m.interval.startAt)
  );

  return (
    <>
      {!meetings.length && (
        <NoEventsScreen
          message={noEventsMessage || t("You don't have any events yet.")}
          cta="none"
        />
      )}
      {Object.entries(groupedMeetings).map(([groupLabel, group]) => (
        <Fragment key={groupLabel}>
          <Typography variant="h3">{groupLabel}</Typography>
          {group && (
            <Stack spacing={2} marginTop={3} marginBottom={4}>
              {group.map((m) => (
                <EventCard
                  key={m.id}
                  meeting={m}
                  onCancel={onCancel}
                  onLeaveEvent={onLeaveEvent}
                  showDateInfo={showDateInfo}
                />
              ))}
            </Stack>
          )}
        </Fragment>
      ))}
      {fetchOlder && (
        <Box justifyContent="center" display="flex" flexDirection="row" p={2}>
          <Button onClick={fetchOlder}>{t("Load more")}</Button>
        </Box>
      )}
    </>
  );
}
