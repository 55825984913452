import { Tooltip } from "@ameelio/ui";
import { Box, Radio, Typography } from "@mui/material";
import { ltrTheme } from "../../theme";

type Props = {
  title: string;
  children: React.ReactElement;
  onClick?: () => void;
  selected: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
};

export default function EventTypeCard({
  title,
  children,
  onClick,
  selected,
  disabled,
  tooltipTitle,
}: Props) {
  return (
    <Tooltip title={tooltipTitle || ""}>
      <Box
        display="flex"
        justifyContent="space-between"
        p={2.5}
        pl={2}
        sx={{
          borderRadius: "12px",
          cursor: onClick ? "pointer" : disabled ? "not-allowed" : "default",
          border: selected ? 2 : 0.5,
          borderColor: selected
            ? ltrTheme.palette.primary.main
            : "rgba(0 0 0 / .23)",
          color: disabled ? "rgba(0 0 0 / .23)" : "inherit",
          backgroundColor: disabled ? "rgba(0 0 0 / .01)" : "inherit",
        }}
        onClick={onClick}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            component="label"
            color={disabled ? "rgba(0 0 0 / .23)" : "text.primary"}
          >
            {title}
          </Typography>
          <Box mt={1} pl={1} sx={{ display: "flex", flexDirection: "column" }}>
            {children}
          </Box>
        </Box>
        {onClick && (
          <Box display="flex" alignItems="flex-start">
            <Radio
              checked={selected}
              onChange={onClick}
              name="meeting-type"
              sx={{ p: 0, height: "32px" }}
              inputProps={{ "aria-label": title }}
            />
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}
