import { SxProps, Theme, Typography, TypographyProps } from "@mui/material";

type StyleNames = "textOverflow" | "overflow" | "whiteSpace";

type Props = Omit<TypographyProps, StyleNames | "sx"> & {
  sx?: Omit<SxProps<Theme>, StyleNames>;
  component?: string;
};

/**
 * Truncation may may also depend on the nearest flex child ancestor
 * having a minWidth, as explained in:
 * https://css-tricks.com/flexbox-truncated-text/
 */
export default function TruncatedTypography(props: Props) {
  return (
    <Typography
      {...props}
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
    />
  );
}
