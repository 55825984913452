import { groupBy } from "@ameelio/core";
import { Button, Dialog, DialogActions } from "@ameelio/ui";
import { Box, DialogContent, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { shortMonthDateTime } from "../lib/timeFormats";
import { GetEventDetailsQuery } from "./GetEventDetails.generated";

type MeetingDetails = GetEventDetailsQuery["meeting"];
type Screenshot = Exclude<MeetingDetails["call"], null>["screenshots"][0];

type Props = {
  meeting: MeetingDetails;
  onClose: () => void;
};

function ScreenshotBox({ screenshot }: { screenshot: Screenshot }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ textAlign: "center" }}>
      <img
        src={screenshot.url}
        style={{ maxWidth: "200px", width: "100%" }}
        key={screenshot.url}
        alt={`${t("Screenshot")}: ${screenshot.user?.fullName}`}
      />
    </Box>
  );
}

export default function EventScreenshotModal({ meeting, onClose }: Props) {
  const { t } = useTranslation();

  const screenshotsGroups = groupBy(meeting.call?.screenshots || [], (sc) =>
    new Date(sc.createdAt).toISOString()
  );

  const visitorInfo = meeting.primaryVisitor ||
    meeting.visitors[0] || { fullName: t("Unknown") };

  return (
    <Dialog
      title={t("Screenshots from confidential video call")}
      onClose={onClose}
    >
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t(
            "These low-quality screenshots were taken during your video call with {{visitorName}} on {{meetingDate}}. {{visitorName}} also has a copy of these screenshots.",
            {
              visitorName: visitorInfo.fullName,
              meetingDate: shortMonthDateTime(
                new Date(meeting.interval.startAt)
              ),
            }
          )}
        </Typography>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "400px",
            backgroundColor: grey[100],
            p: 2,
          }}
        >
          {Object.entries(screenshotsGroups).map(
            ([ts, screenshots]) =>
              screenshots && (
                <Stack spacing={1} mb={1} key={ts}>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {shortMonthDateTime(new Date(ts))}
                  </Typography>
                  <Stack
                    direction={{ md: "row", xs: "column" }}
                    justifyContent="center"
                    spacing={1}
                  >
                    {screenshots.map((sc) => (
                      <ScreenshotBox screenshot={sc} key={sc.url} />
                    ))}
                  </Stack>
                </Stack>
              )
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t("Okay")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
