import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MeetingType } from "../../api/graphql";
import { WebinarHostInformation } from "../../Call/WebinarInfoCard";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { ScreenTitle } from "../../lib/typography";
import EventTypeCard from "./EventTypeCard";

type Props = {
  stepper: StepperType;
  onSubmit: (meetingType: MeetingType.Webinar) => void;
};

export default function SelectWebinarStep({ stepper, onSubmit }: Props) {
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(MeetingType.Webinar);
  };

  return (
    <StepperFormWrapper
      stepper={stepper({ disabled: false, loading: false })}
      handleSubmit={handleSubmit}
    >
      <ScreenTitle>{t("Schedule webinar")}</ScreenTitle>

      <Box marginY={6}>
        <EventTypeCard title={t("Webinar class")} selected>
          <WebinarHostInformation textColor="text.primary" />
        </EventTypeCard>
      </Box>
    </StepperFormWrapper>
  );
}
