import { Tooltip } from "@ameelio/ui";
import { Lock } from "@mui/icons-material";
import { MeetingType, PrivacyLevel } from "../../api/graphql";
import privacyLevelDescription from "../../lib/privacyLevelDescription";
import CallWidget from "./CallWidget";

export default function CallConfidentialWidget({
  privacyLevel,
}: {
  privacyLevel: PrivacyLevel;
}) {
  return (
    <CallWidget>
      <Tooltip
        title={privacyLevelDescription(MeetingType.VideoCall, privacyLevel)}
      >
        <Lock sx={{ height: 16 }} />
      </Tooltip>
    </CallWidget>
  );
}
