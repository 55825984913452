import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Connection,
  ConnectionStatus,
  CorrespondentFeature,
  Facility,
  SystemUserStatus,
} from "../api/graphql";
import { appendItem } from "../client";
import { CreatePendingConnectionDocument } from "../ConnectionsFlow/CreatePendingConnection.generated";
import { unaccompaniedVisitAllowed } from "../Events/utils";
import { ContentSkeleton } from "../lib/closet";
import connectionFeatureToFacilityFeature from "../lib/connectionFeatureToFacilityFeature";
import ContactRestrictionsAlert from "../lib/ContactRestrictionsAlert";
import useApolloErrorHandler from "../lib/handleApolloError";
import Link from "../lib/Link";
import permittedFeaturesList from "../lib/permittedFeaturesList";
import { PersonalRelationship } from "../lib/Relationship";
import Screen from "../lib/Screen";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import { RejectedIDAlert } from "../lib/useIdRejectionStatus";
import { useCurrentCorrespondent } from "../SessionBoundary";
import ArchiveConnectionButton from "./ArchiveConnectionButton";
import ConnectionStatusChip from "./ConnectionStatusChip";
import ContactRejectedAlert from "./ContactRejectedAlert";
import ContactDetails from "./ContactsDetails";
import EditConnectionRelationshipButton from "./EditConnectionRelationshipButton";
import { GetContactDetailsDocument } from "./GetContactDetails.generated";

export default function ContactDetailsScreen() {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const user = useCurrentCorrespondent();

  const isOrganizationMember =
    user.__typename === "Visitor" && user.organizationMemberships?.length > 0;
  const canEditRelationship =
    user.__typename === "Visitor" && !isOrganizationMember;

  const { connectionId } = useParams<{
    connectionId: string;
  }>();
  if (!connectionId) throw new Error("missing connectionId param");

  const mayScheduleEvents =
    useFeaturePermitted(CorrespondentFeature.ScheduleMeetings) &&
    unaccompaniedVisitAllowed(user, connectionId);

  const { data, error } = useQuery(GetContactDetailsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { connectionId },
  });

  // By the time this is called, data will exist - have
  // to add here because the hook can't be called conditionally
  // after the early returns below this
  const [createPendingConnection, { loading: createPendingLoading }] =
    useMutation(CreatePendingConnectionDocument, {
      variables: {
        input: {
          inmateId: data?.connection.inmate.id || "",
          relationship:
            data?.connection.relationship || PersonalRelationship.Other,
        },
      },
      onError: (e) => handleApolloError(e),
      update: (cache, { data: createPendingData }) => {
        if (!createPendingData) return;
        cache.modify({
          id: cache.identify({ __typename: "Visitor", id: user.id }),
          fields: {
            connections: appendItem(
              createPendingData.createPendingConnection.connection
            ),
          },
        });
      },
    });

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const { connection } = data;
  const { facility } = connection.inmate;

  const permittedFeatures = permittedFeaturesList({
    features: connection.features.map((f) =>
      connectionFeatureToFacilityFeature(f)
    ),
    activeRestrictions: connection.activeRestrictions.map((r) => r.service),
  });

  const action =
    mayScheduleEvents && connection.status === ConnectionStatus.Active ? (
      <Link
        button
        variant="outlined"
        to="/events/new"
        state={{
          connectionId: connection.id,
          fromPath: `/contacts/${connection.id}`,
          fromName: t("Contacts"),
        }}
        sx={{ flexGrow: 1 }}
      >
        {t("Schedule event")}
      </Link>
    ) : null;

  const mayRequestConnections = user.correspondentFeatures.includes(
    CorrespondentFeature.RequestConnections
  );

  const visitorHasRejectedID =
    user.__typename === "Visitor" &&
    user.systemRelationships.find(
      (sr) =>
        sr.status === SystemUserStatus.Rejected &&
        facility.system.id === sr.system.id
    );

  return (
    <Screen
      showBack
      showNotifications
      headerAction={action}
      title={connection.contact.fullName}
    >
      {visitorHasRejectedID && (
        <Box sx={{ my: 4 }}>
          <RejectedIDAlert />
        </Box>
      )}
      {!!connection?.activeRestrictions.length && (
        <ContactRestrictionsAlert
          contact={connection.contact}
          restrictions={connection.activeRestrictions}
        />
      )}
      <ContactDetails
        user={user}
        contact={connection.contact}
        connection={connection as unknown as Connection}
        facility={facility as Facility}
        permittedFeatures={permittedFeatures}
        statusComponent={
          connection.status === ConnectionStatus.Rejected &&
          mayRequestConnections ? (
            <Box flex={1}>
              <ContactRejectedAlert
                statusDetails={connection.statusDetails}
                submitting={createPendingLoading}
                onSubmit={() => createPendingConnection()}
              />
            </Box>
          ) : (
            <Box>
              <ConnectionStatusChip status={connection.status} />
            </Box>
          )
        }
      />
      {user.__typename === "Visitor" &&
        connection.status !== ConnectionStatus.Inactive && (
          <Box sx={{ mt: 4, display: "flex" }}>
            <ArchiveConnectionButton
              firstName={connection.inmate.firstName}
              status={connection.status}
              connectionId={connection.id}
            />
            {canEditRelationship && (
              <EditConnectionRelationshipButton
                relationship={connection.relationship}
                connectionId={connection.id}
              />
            )}
          </Box>
        )}
    </Screen>
  );
}
