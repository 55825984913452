import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";
import AddContactsScreen from "./AddContactsScreen";
import ContactDetailsScreen from "./ContactDetailsScreen";
import ContactsScreen from "./ContactsScreen";

const ContactsRoutes: RouteObject = {
  path: "/contacts",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <MenuLayout currentTab="contacts">
          <ContactsScreen />
        </MenuLayout>
      ),
    },
    {
      path: ":connectionId",
      element: (
        <MenuLayout currentTab="contacts">
          <ValidateUUID id="connectionId">
            <ContactDetailsScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
    { path: "add", element: <AddContactsScreen /> },
  ],
};

export default ContactsRoutes;
