import { belowLargeTablet } from "@ameelio/ui";
import { Box, Container, useMediaQuery } from "@mui/material";
import { BaseSkeleton, ListSkeleton } from "./lib/closet";

export default function AppSkeleton() {
  const isMobileOrSmallTablet = belowLargeTablet(useMediaQuery);

  if (isMobileOrSmallTablet) {
    return (
      <Box sx={{ height: 1, mx: 2 }}>
        <Box component="main" sx={{ marginTop: 8 }}>
          <ListSkeleton />
        </Box>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ display: "flex", padding: 3, height: 1 }}>
      <BaseSkeleton variant="rectangular" width={240} sx={{ height: 1 }} />
      <Box component="main" sx={{ flexGrow: 1, marginLeft: 3 }}>
        <ListSkeleton />
      </Box>
    </Container>
  );
}
