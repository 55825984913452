import { Box, Stack, Typography } from "@mui/material";

type Props = {
  children: React.ReactNode;
};

export default function DetailsStack({ children }: Props) {
  return <Stack spacing={1.5}>{children}</Stack>;
}

type Detail = {
  label: string;
  value?: string | null;
  children?: React.ReactNode;
};

export function DetailsStackItem({ label, value, children }: Detail) {
  return (
    <Box
      sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
      gap={1.5}
    >
      <Box sx={{ width: 160, minWidth: 160, flex: 0 }}>
        <Typography variant="body1" color="text.secondary">
          {label}
        </Typography>
      </Box>

      {children || (
        <Typography variant="body1" color="text.primary">
          {value}
        </Typography>
      )}
    </Box>
  );
}
