import { Box } from "@mui/material";
import { ltrTheme } from "../theme";

type Props = {
  stepper: React.ReactNode;
  children: React.ReactNode;
  handleSubmit: (e: React.FormEvent) => void;
};

export default function StepperFormWrapper({
  stepper,
  children,
  handleSubmit,
}: Props) {
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        minHeight: "100%",
        flexDirection: "column",
        paddingBottom: ltrTheme.spacing(4),
      }}
    >
      <Box sx={{ flex: 1 }}>{children}</Box>

      <Box>{stepper}</Box>
    </form>
  );
}
