import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ContentSkeleton } from "../lib/closet";
import Screen from "../lib/Screen";
import { GetOrganizationMembershipsDocument } from "./GetOrganizationMemberships.generated";

export default function OrganizationMembersScreen() {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("missing id param");

  const { t } = useTranslation();

  const { data, error } = useQuery(GetOrganizationMembershipsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { organizationId: id },
  });

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const { memberships } = data.organization;

  return (
    <Screen title={t("Members")}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberships.map((m) => (
              <TableRow key={m.id}>
                <TableCell>{m.visitor.email}</TableCell>
                <TableCell>{m.role}</TableCell>
                <TableCell>{m.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Screen>
  );
}
