import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";
import MessageCenterScreen from "./MessageCenterScreen";

const MessagesRoutes: RouteObject = {
  path: "/messages",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <MenuLayout currentTab="messages">
          <MessageCenterScreen />
        </MenuLayout>
      ),
    },
    {
      path: ":connectionId",
      element: (
        <MenuLayout currentTab="messages">
          <ValidateUUID id="connectionId">
            <MessageCenterScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
  ],
};

export default MessagesRoutes;
