import { resetSessionSurveys } from "@ameelio/nps-survey";
import { useEffect } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import AppSkeleton from "../AppSkeleton";
import { useAuthContext } from "../AuthProvider";
import isObjectWithKey from "../lib/isObjectWithKey";

type LocationState = {
  tokenError?: boolean;
};

function isLocationState(obj: unknown): obj is LocationState {
  return (
    isObjectWithKey(obj, "tokenError") && typeof obj.tokenError === "boolean"
  );
}

function getLocationState(location: Location): LocationState | undefined {
  if (isLocationState(location.state)) return location.state;
  return undefined;
}

export default function LogoutScreen() {
  const { logout } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const state = getLocationState(location) ?? { tokenError: false };
    resetSessionSurveys();
    logout(state.tokenError).then(() => navigate("/"));
  }, [logout, navigate, location]);

  return <AppSkeleton />;
}
