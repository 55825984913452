import * as Types from './api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteVisitorMutationVariables = Types.Exact<{
  input: Types.DeleteVisitorInput;
}>;


export type DeleteVisitorMutation = { __typename: 'Mutation', deleteVisitor: { __typename: 'DeleteVisitorResponse', scheduledDeletionAt: Types.Scalars['Date']['input'] } };


export const DeleteVisitorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteVisitor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteVisitorInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteVisitor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scheduledDeletionAt"}}]}}]}}]} as unknown as DocumentNode<DeleteVisitorMutation, DeleteVisitorMutationVariables>;