import { Button } from "@ameelio/ui";
import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  label?: string;
  buttonCopy?: string;
};

export default function CopyToClipboard({ value, label, buttonCopy }: Props) {
  const { t } = useTranslation();

  // show helper text for some seconds
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (!copied) return () => {};
    const timer = setInterval(() => setCopied(false), 3000);
    return () => {
      clearInterval(timer);
    };
  }, [copied]);

  const inputRef = useRef<HTMLInputElement>();
  const doCopy = () => {
    if (!inputRef.current) return;
    inputRef.current.select();
    document.execCommand("copy");
    setCopied(true);
  };

  return (
    <>
      <TextField
        inputRef={inputRef}
        label={label}
        value={value}
        onFocus={doCopy}
        sx={{ width: 1 }}
      />

      {buttonCopy && (
        <Button
          variant="contained"
          type="button"
          onClick={doCopy}
          sx={{ mt: 1 }}
        >
          {copied ? t("Copied!") : buttonCopy}
        </Button>
      )}
    </>
  );
}
