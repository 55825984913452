import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Facility } from "../api/graphql";
import { FAQListItem } from "../lib/FAQListDialog";

export default function InPersonVisitFAQList({
  facility,
}: {
  facility: Pick<Facility, "publicId" | "policyLink">;
}) {
  const { t } = useTranslation();
  const faqItems = [
    {
      question: t("Arrive early"),
      answer: t(
        "All visitors need to be at the facility and sign in at least 10 minutes prior to your visiting time. The facility does not allow for late arrivals—if you are late, there will be no visitation."
      ),
    },
    {
      question: t("Bring your ID"),
      answer: t(
        "All visitors must show a valid government-issued ID or be accompanied by a legal guardian."
      ),
    },
    {
      question: t("Dress appropriately"),
      answer: t("All visitors must be clothed in accordance with policy."),
    },
    {
      question: t("No extra guests"),
      answer: t("Only visitors on this guest list are approved to visit."),
    },
  ];
  return (
    <>
      {faqItems.map((faq) => (
        <FAQListItem key={faq.question} {...faq} />
      ))}
      {facility.policyLink && (
        <Link href={facility.policyLink} target="_blank">
          {t("Read {{publicId}}'s policies", { publicId: facility.publicId })}
        </Link>
      )}
    </>
  );
}
