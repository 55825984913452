import { belowLargeTablet, largeTabletAndUp, TextInputBase } from "@ameelio/ui";
import {
  Box,
  useMediaQuery as measureScreenWidth,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import {
  ConnectionFeature,
  CorrespondentFeature,
  FacilityFeature,
  FacilityService,
} from "../api/graphql";
import buildPageTitle from "../lib/buildPageTitle";
import messagingEnabled from "../lib/messagingEnabled";
import ProvideYourIdReminder from "../lib/ProvideYourIdReminder";
import ScreenHeader from "../lib/ScreenHeader";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import useRemindMinorToProvideId from "../lib/useRemindMinorToProvideId";
import useScrolledToBottom from "../lib/useScrolledToBottom";
import withSx from "../lib/withSx";
import { useCurrentUser } from "../SessionBoundary";
import ConnectionHeader from "./ConnectionHeader";
import ConnectionsListItem from "./ConnectionsListItem";
import ConnectionTimeline from "./ConnectionTimeline";
import ContactDetailsPanel from "./ContactDetailsPanel";
import { useMessageBadges } from "./MessageBadgeProvider";
import MessageCenterScreenSkeleton from "./MessageCenterScreenSkeleton";
import MessagingRestrictedAlert from "./MessagingRestrictedAlert";
import MessagingUnavailableAlert from "./MessagingUnavailableAlert";
import NoConnectionsScreen from "./NoConnectionsScreen";
import SendMessageForm from "./SendMessageForm";
import {
  ConnectionsColumn,
  ConnectionsFilter,
  ConnectionsList,
  MessageCenterPaper,
  TimelineColumn,
} from "./ui";
import useConnectionsList from "./useConnectionsList";

const MessagingAlertBox = withSx(Box, { mt: 1, mx: 2, mb: 0 });
const TimelineContainer = withSx(Box, {
  flexGrow: 1,
  overflow: "auto",
  mb: 1,
  pt: 2,
});

export default function MessageCenterScreen() {
  const { t } = useTranslation();
  const { connectionId } = useParams<{ connectionId: string }>();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);
  const lgTabletAndUp = largeTabletAndUp(measureScreenWidth);

  const user = useCurrentUser();

  const { setCurrentTimeline } = useMessageBadges();
  useEffect(() => {
    setCurrentTimeline(connectionId);
    return () => setCurrentTimeline(undefined);
  }, [setCurrentTimeline, connectionId]);

  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);

  const { scrolledToBottom, onScroll } = useScrolledToBottom(true);

  const connections = useConnectionsList();

  const { showProvideIdReminder, reminderDueDate } = useRemindMinorToProvideId({
    user,
  });

  // visible connections may be filtered
  const [filter, setFilter] = useState("");
  const filteredConnections = useMemo(() => {
    const normalizedFilter = filter.trim().toLocaleLowerCase();
    return connections?.filter(
      (c) =>
        !filter ||
        c.contact.firstName.toLocaleLowerCase().includes(normalizedFilter) ||
        c.contact.lastName.toLocaleLowerCase().includes(normalizedFilter)
    );
  }, [connections, filter]);

  const mayInitiateVoiceCall = useFeaturePermitted(
    CorrespondentFeature.MakeVoiceCalls
  );

  // loading
  if (!connections) return <MessageCenterScreenSkeleton />;

  // empty
  if (connections.length === 0) return <NoConnectionsScreen />;

  // auto-select most recently active connection
  if (!connectionId && connections.length > 0)
    return <Navigate replace to={`/messages/${connections[0].id}`} />;
  if (!connectionId) throw new Error("missing connectionId param");

  const connection = connections.find((c) => c.id === connectionId);
  if (!connection) throw new Error("unknown connectionId");

  const isMessagingEnabled = messagingEnabled(user);
  const isMessagingAvailableOnConnection =
    isMessagingEnabled &&
    !!connection?.features.includes(ConnectionFeature.SendMessages);

  const messageRestriction = connection?.activeRestrictions.find(
    (r) => r.service === FacilityService.Emessaging
  );

  const { currentVoiceCallSlot, nextVoiceCallSlot } = connection.inmate.group;
  const userMayMakeVoiceCall =
    user.__typename === "Inmate" &&
    (!connection.inmate.group.hasVoiceCallSlots || !!currentVoiceCallSlot);

  const headerTitle = isMessagingEnabled ? t("Messages") : t("History");
  const pageTitle = buildPageTitle(headerTitle);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box sx={{ height: 1, display: "flex", flexDirection: "column" }}>
        {showProvideIdReminder && (
          <ProvideYourIdReminder dueDate={reminderDueDate} />
        )}
        <ScreenHeader>{headerTitle}</ScreenHeader>
        <MessageCenterPaper>
          <ConnectionsColumn
            sx={{
              backgroundColor: "background.paper",
              borderStyle: "solid",
              borderRightStyle: "solid",
              borderRadius: { xs: "16px", md: 0 },
              borderWidth: { xs: "1px", md: "0 1px 0 0" },
              width: { xs: "100%", md: 240 },
              maxWidth: { xs: "100%", md: 240 },
              maxHeight: { xs: "400px", md: "100%" },
              marginBottom: { xs: 5, md: 0 },
            }}
          >
            <ConnectionsFilter>
              <TextInputBase
                value={filter}
                onChange={(e) => setFilter(e.currentTarget.value)}
                label={t("Search for people")}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </ConnectionsFilter>
            <ConnectionsList component="nav">
              {filteredConnections?.map((conn) => (
                <ConnectionsListItem
                  key={conn.id}
                  selected={connectionId === conn.id}
                  contact={conn.contact}
                  connection={conn}
                />
              ))}
              {filter && filteredConnections?.length === 0 && (
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: 200,
                  }}
                >
                  <Box>
                    <Typography color="text.secondary">
                      {t("No results")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </ConnectionsList>
          </ConnectionsColumn>
          <TimelineColumn
            sx={{
              backgroundColor: "background.paper",
              borderStyle: "solid",
              borderRadius: { xs: "16px", md: 0 },
              borderWidth: { xs: "1px", md: 0 },
              borderColor: "divider",
            }}
          >
            {connection && (
              <>
                <ConnectionHeader
                  showVoiceCall={
                    mayInitiateVoiceCall &&
                    connection.inmate.facility.features.includes(
                      FacilityFeature.VoiceCall
                    )
                  }
                  connection={connection}
                  mayMakeVoiceCall={userMayMakeVoiceCall}
                  nextVoiceCallSlot={nextVoiceCallSlot}
                  contact={connection.contact}
                  onSeeDetails={() => setIsDetailsPanelOpen((o) => !o)}
                  detailsOpen={isDetailsPanelOpen}
                />
                {isMobileOrSmallTablet && isDetailsPanelOpen && (
                  <ContactDetailsPanel connectionId={connection.id} />
                )}
                <TimelineContainer onScroll={onScroll}>
                  <ConnectionTimeline
                    connectionId={connection.id}
                    pinToBottom={scrolledToBottom}
                  />
                </TimelineContainer>
                {isMessagingEnabled && (
                  <>
                    {messageRestriction && (
                      <MessagingAlertBox>
                        <MessagingRestrictedAlert
                          expiresAt={messageRestriction.expiresAt}
                        />
                      </MessagingAlertBox>
                    )}
                    {!isMessagingAvailableOnConnection && (
                      <MessagingAlertBox>
                        <MessagingUnavailableAlert
                          professionalConnection={
                            !!connection.organizationMembership
                          }
                        />
                      </MessagingAlertBox>
                    )}
                    <SendMessageForm
                      connectionId={connection.id}
                      disabled={
                        !!messageRestriction ||
                        !isMessagingAvailableOnConnection
                      }
                    />
                  </>
                )}
              </>
            )}
          </TimelineColumn>
          {lgTabletAndUp && connection && isDetailsPanelOpen && (
            <ContactDetailsPanel connectionId={connection.id} />
          )}
        </MessageCenterPaper>
      </Box>
    </>
  );
}
