import { Role } from "@ameelio/connect-call-client";
import { Avatar, IconButton } from "@ameelio/ui";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCurrentCorrespondent } from "../../SessionBoundary";
import { getPeersOrdered } from "../utils";
import { Peer } from "./types/ConnectionInfo";

type ParticipantCardProps = {
  meetingUser: {
    id?: string;
    firstName: string;
    lastName: string;
    fullName: string;
  };
  isHost: boolean;
  muteAudio?: () => void;
  unmuteAudio?: () => void;
  audioMuted?: boolean;
};

function ParticipantCard({
  meetingUser,
  isHost,
  muteAudio,
  unmuteAudio,
  audioMuted,
}: ParticipantCardProps) {
  const { t } = useTranslation();
  const currentUser = useCurrentCorrespondent();
  return (
    <Card variant="outlined" sx={{ my: 2, px: { xs: 1, sm: 2 }, py: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ maxWidth: "100%", overflow: "auto" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar contact={meetingUser} />
          <Box>
            {meetingUser.fullName}{" "}
            {currentUser.id === meetingUser.id
              ? `${t("(you)")}`
              : isHost && currentUser.__typename === "Visitor"
                ? `${t("(co-host)")}`
                : isHost && currentUser.__typename === "Inmate"
                  ? `${t("(host)")}`
                  : null}
          </Box>
        </Stack>
        {audioMuted != null && (
          <Stack direction="row" alignItems="center" spacing={2}>
            {unmuteAudio && muteAudio ? (
              audioMuted ? (
                <IconButton
                  tooltip
                  ariaLabel={t("Unmute")}
                  onClick={unmuteAudio}
                >
                  <MicOffOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton tooltip ariaLabel={t("Mute")} onClick={muteAudio}>
                  <MicIcon />
                </IconButton>
              )
            ) : audioMuted ? (
              <IconButton ariaLabel={t("Unmute")} disabled>
                <MicOffOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton ariaLabel={t("Mute")} disabled>
                <MicIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </Box>
    </Card>
  );
}
type AbsentPeer = {
  firstName: string;
  lastName: string;
  id: string;
};

const byLastThenFirstName = (a: AbsentPeer, b: AbsentPeer) => {
  const lastNameComparison = a.lastName.localeCompare(b.lastName);
  if (lastNameComparison)
    // If last names are different, sort by last name
    return lastNameComparison;
  // If last names are the same, sort by first name
  return a.firstName.localeCompare(b.firstName);
};

type Props = {
  userId: string;
  isHost: boolean;
  peers: Peer[];
  meetingUsers: {
    [id: string]: { firstName: string; lastName: string; fullName: string };
  };
  remoteAudioUnmute?: (id: string) => void;
  remoteAudioMute?: (id: string) => void;
  toggleAudio: () => void;
  audioPaused: boolean;
  onClose: () => void;
};

export default function CallParticipants({
  userId,
  isHost,
  peers,
  remoteAudioUnmute,
  remoteAudioMute,
  toggleAudio,
  audioPaused,
  meetingUsers,
  onClose,
}: Props) {
  const { t } = useTranslation();

  // Put hosts at the top of the list to mirror order in page
  const presentPeers = getPeersOrdered(peers).filter(
    (x) => x.user.id in meetingUsers && x.user.id !== userId
  );
  const absentPeers = Object.keys(meetingUsers)
    .filter((id) => !peers.some((peer) => peer.user.id === id) && userId !== id)
    .sort()
    .map((id) => ({ id, ...meetingUsers[id] }));

  async function askUnmute(peer: { user: { id: string } }) {
    if (remoteAudioUnmute) await remoteAudioUnmute(peer.user.id);
  }

  async function askMute(peer: { user: { id: string } }) {
    if (remoteAudioMute) await remoteAudioMute(peer.user.id);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 1,
        overflow: "auto",
      }}
    >
      <Box sx={{ display: "flex", padding: 2 }}>
        <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
          {t("Participants")}
        </Typography>
        <IconButton size="small" onClick={onClose} ariaLabel={t("Close")}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box>
          <Typography variant="overline">
            {t("In Call")} ({presentPeers.length + 1})
          </Typography>
        </Box>
        <ParticipantCard
          meetingUser={meetingUsers[userId]}
          isHost={isHost}
          muteAudio={isHost ? toggleAudio : undefined}
          unmuteAudio={isHost ? toggleAudio : undefined}
          audioMuted={audioPaused}
        />
        {presentPeers.map((peer) => {
          const consideredAudioMuted = peer.consumers.audio?.paused;

          const canModify =
            isHost &&
            [Role.webinarAttendee, Role.webinarIsolatedAttendee].includes(
              peer.user.role
            );

          return (
            <ParticipantCard
              key={peer.user.id}
              meetingUser={meetingUsers[peer.user.id]}
              isHost={peer.user.role === Role.webinarHost}
              muteAudio={canModify ? () => askMute(peer) : undefined}
              unmuteAudio={canModify ? () => askUnmute(peer) : undefined}
              audioMuted={consideredAudioMuted}
            />
          );
        })}
        <Typography variant="overline">
          {t("Not Present")} ({absentPeers.length})
        </Typography>
        {absentPeers.sort(byLastThenFirstName).map((meetingUser) => (
          <ParticipantCard
            key={meetingUser.id}
            meetingUser={meetingUser}
            isHost={false}
            muteAudio={undefined}
            unmuteAudio={undefined}
            audioMuted={undefined}
          />
        ))}
      </Box>
    </Box>
  );
}
