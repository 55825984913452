import { useSnackbarContext } from "@ameelio/ui";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

export default function useApolloErrorHandler() {
  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();

  // Moved inside the function to allow for correct translation
  const ERROR_MESSAGES: Record<string, string> = {
    "The requesting visitor already has a meeting scheduled at this time.": t(
      "You already have a meeting scheduled at this time."
    ), // Tailored to visitor first-person experience
    "User with an active organization membership can't have personal connections.":
      t(
        "Since you have an active organizational membership, you can't have personal connections with this account. You can set up another account to use with personal connections."
      ),
    "inmate identification not found": t(
      "We could not find an account with that username. Please check and try again."
    ),
    "inmate account is inactive": t(
      "Your account is inactive. In order to use Ameelio Connect you must have an active account."
    ),
    "email not found": t(
      "We could not find an account with that email address. Please check and try again."
    ),
    "invalid credentials": t(
      "Your password is incorrect. Please check and try again."
    ),
    "staff cannot authenticate as a user": t(
      "Your account is not supported by this application."
    ),
    "Connect user already exists.": t(
      "You already have an Ameelio Connect account. Please sign in using that email and password."
    ),
    "User already exists.": t(
      "You already have an Ameelio account from Ameelio Mail. Please sign in using that email and password."
    ),
  };

  return function handleApolloError(e: unknown) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV) console.error(e);
    if (!(e instanceof Error)) return;

    if (!(e.message in ERROR_MESSAGES))
      Sentry.captureException(e, { level: "info" });
    snackbarContext.alert(
      "error",
      e.message in ERROR_MESSAGES ? ERROR_MESSAGES[e.message] : e.message
    );
  };
}
