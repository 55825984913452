import {
  Avatar,
  Button,
  Media,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationMembershipRole } from "../api/graphql";
import useApolloErrorHandler from "../lib/handleApolloError";
import Link from "../lib/Link";
import { useCurrentMembership } from "../SessionBoundary";
import { GetOrganizationMembershipsDocument } from "./GetOrganizationMemberships.generated";
import RegenerateInvitationLinkButton from "./RegenerateInvitationLinkButton";
import RemoveOrganizationMembershipModal from "./RemoveOrganizationMembershipModal";
import RevokeOrganizationInvitationButton from "./RevokeOrganizationInvitationButton";

type Props = {
  organizationId: string;
};

export default function OrganizationMemberships({ organizationId }: Props) {
  const { t } = useTranslation();
  const { role } = useCurrentMembership();
  const [membershipIdToRemove, setMembershipIdToRemove] = useState<string>();
  const isAdmin = role === OrganizationMembershipRole.Admin;
  const handleApolloError = useApolloErrorHandler();
  const { data, error } = useQuery(GetOrganizationMembershipsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { organizationId },
  });

  if (error) throw error;
  if (!data) return <Box />;

  const { organization } = data;
  const { memberships: teamMembers } = data.organization;
  const invitations = [...data.organization.invitations].sort((a, b) =>
    a.email.localeCompare(b.email)
  );

  return (
    <TableContainer>
      <RemoveOrganizationMembershipModal
        organizationMembershipId={membershipIdToRemove}
        onSuccess={() => setMembershipIdToRemove(undefined)}
        onCancel={() => setMembershipIdToRemove(undefined)}
        onFail={(e) => {
          setMembershipIdToRemove(undefined);
          handleApolloError(e);
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Name")}</TableCell>
            <TableCell>{t("Email")}</TableCell>
            <TableCell>{t("Role")}</TableCell>
            <TableCell>{t("Actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teamMembers.map((tm) => (
            <TableRow key={tm.id}>
              <TableCell>
                <Link
                  sx={{ textDecoration: "none" }}
                  to={`/organization/${organization.id}/team-member/${tm.id}`}
                >
                  <Media image={<Avatar contact={tm.visitor} />}>
                    <Typography
                      variant="body2"
                      sx={{ textDecoration: "underline" }}
                    >
                      {tm.visitor.fullName}
                    </Typography>
                  </Media>
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{tm.visitor.email}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {
                    {
                      [OrganizationMembershipRole.Admin]: t("Admin"),
                      [OrganizationMembershipRole.Member]: t("Member"),
                    }[tm.role]
                  }
                </Typography>
              </TableCell>
              <TableCell>
                {tm.role !== OrganizationMembershipRole.Admin && (
                  <Button
                    disabled={!isAdmin}
                    variant="outlined"
                    disabledReason={t(
                      "Only organization admins may remove members"
                    )}
                    onClick={() => {
                      setMembershipIdToRemove(tm.id);
                    }}
                  >
                    {t("Remove team member")}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
          {invitations.map((i) => (
            <TableRow key={i.id}>
              <TableCell>
                <Media image={<Avatar />}>
                  <Typography variant="body2" color="text.disabled">
                    -
                  </Typography>
                </Media>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.disabled">
                  {i.email}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.disabled">
                  {
                    {
                      [OrganizationMembershipRole.Admin]: t("Admin"),
                      [OrganizationMembershipRole.Member]: t("Member"),
                    }[i.role]
                  }{" "}
                  ({t("Invite pending")})
                </Typography>
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <RegenerateInvitationLinkButton
                    isAdmin={isAdmin}
                    organizationId={organizationId}
                    organizationInvitationId={i.id}
                  />
                  <RevokeOrganizationInvitationButton
                    isAdmin={isAdmin}
                    organizationInvitationId={i.id}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
