import { Chip } from "@ameelio/ui";
import { useTranslation } from "react-i18next";
import { ConnectionStatus } from "../api/graphql";

export default function ConnectionStatusChip({
  status,
}: {
  status: ConnectionStatus;
}) {
  const { t } = useTranslation();
  switch (status) {
    case ConnectionStatus.Active:
      return <Chip color="green" label={t("Active")} />;
    case ConnectionStatus.Inactive:
      return <Chip color="grey" label={t("Inactive")} />;
    case ConnectionStatus.Pending:
      return <Chip color="orange" label={t("Pending")} />;
    case ConnectionStatus.Rejected:
      return <Chip color="red" label={t("Rejected")} />;
    default:
      throw new Error("unexpected connection status");
  }
}
