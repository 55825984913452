import { FluidGrid, FluidGridItem } from "@ameelio/ui";
import { SxProps, Theme } from "@mui/material";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import Pager, { getTotalPages } from "../../lib/Pager";
import { isHost } from "../utils";
import ParticipantAudio from "./ParticipantAudio";
import {
  ConnectionInfo,
  LocalWebinarParticipant,
  RemoteWebinarParticipant,
  WebinarParticipant,
} from "./types/ConnectionInfo";
import VideoParticipant from "./VideoParticipant";

type Props = {
  remoteParticipants: RemoteWebinarParticipant[];
  localParticipant: LocalWebinarParticipant;
  // TODO: merge with localParticipant
  localProducers: ConnectionInfo["localProducers"];
  participantsPerPage: number;
  callStatus: ConnectionInfo["callStatus"];
  clientStatus: ConnectionInfo["clientStatus"];
  gridColumns: Record<number, number>;
  gridRows?: number;
  onRemoteAudioMute?: (userId: string) => void;
  onRemoteAudioUnmute?: (userId: string) => void;
  pagerStyleOverrides?: SxProps<Theme>;
};

function isLocalParticipant(
  p: WebinarParticipant
): p is LocalWebinarParticipant {
  return "isLocal" in p;
}

export default function VideosGrid({
  remoteParticipants,
  localParticipant,
  participantsPerPage,
  gridColumns,
  gridRows,
  onRemoteAudioMute,
  onRemoteAudioUnmute,
  callStatus,
  clientStatus,
  localProducers,
  pagerStyleOverrides,
}: Props) {
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Reset the current page if it is ever detected to be out of bounds.
  // This might happen at different screen sizes, for example, if the
  // parent component sends a new participantsPerPage.
  useEffect(() => {
    const totalPages = getTotalPages(
      participantsPerPage,
      remoteParticipants.length + 1
    );
    if (currentPage > totalPages) setCurrentPage(totalPages);
  }, [setCurrentPage, currentPage, participantsPerPage, remoteParticipants]);

  // Slice out the current page
  const pageParticipants: WebinarParticipant[] = useMemo(
    () =>
      [localParticipant, ...remoteParticipants].slice(
        participantsPerPage * (currentPage - 1),
        participantsPerPage * currentPage
      ),
    [currentPage, localParticipant, remoteParticipants, participantsPerPage]
  );

  // Gets non-visible peers (for the purposes of mounting their audio tags)
  const nonVisibleParticipants = remoteParticipants.filter(
    (p) => !pageParticipants.map((pp) => pp.user.id).includes(p.user.id)
  );

  const totalPages = getTotalPages(
    participantsPerPage,
    remoteParticipants.length + 1
  );
  const selfIsHost = isHost(localParticipant.user.role);

  return (
    <Pager
      currentPage={currentPage}
      totalItems={remoteParticipants.length + 1}
      itemsPerPage={participantsPerPage}
      onPrevious={setCurrentPage}
      onNext={setCurrentPage}
      styleOverrides={pagerStyleOverrides}
    >
      {nonVisibleParticipants.map((p) => (
        <ParticipantAudio
          key={p.user.id}
          srcObject={p?.consumers.audio?.stream}
          autoPlay
        />
      ))}
      <FluidGrid
        size={
          totalPages > 1 || gridRows === 1
            ? participantsPerPage
            : pageParticipants.length
        }
        gap={2}
        sxOverrides={{
          backgroundColor: "inherit",
          height: 1,
          width: 1,
          ...(totalPages > 1 && currentPage === totalPages
            ? {
                alignItems: "flex-start",
                justifyContent: "flex-start",
                alignContent: "flex-start",
              }
            : {}),
        }}
        gridColumns={gridColumns}
        numRows={gridRows}
      >
        {pageParticipants.map((p) => (
          <FluidGridItem key={p.user.id}>
            {isLocalParticipant(p) ? (
              <VideoParticipant
                user={localParticipant.user}
                userNameTag={t("You")}
                origin="local"
                mirrored
                audioMuted={!!localProducers.audio?.paused}
                videoPaused={localProducers.video?.paused}
                audioStream={localProducers.audio?.stream}
                stream={localProducers.video?.stream}
                callStatus={callStatus}
                clientStatus={clientStatus}
              />
            ) : (
              <>
                <VideoParticipant
                  user={p.name}
                  userNameTag={p.label}
                  origin="remote"
                  audioMuted={!!p.consumers.audio?.paused}
                  videoPaused={p.consumers.video?.paused}
                  audioStream={p.consumers.audio?.stream}
                  stream={p.consumers.video?.stream}
                  callStatus={callStatus}
                  clientStatus={clientStatus}
                  onMute={
                    onRemoteAudioMute
                      ? () => onRemoteAudioMute(p.user.id)
                      : undefined
                  }
                  onUnmute={
                    onRemoteAudioUnmute
                      ? () => onRemoteAudioUnmute(p.user.id)
                      : undefined
                  }
                  canControlAudio={selfIsHost && !isHost(p.user.role)}
                  fit="cover"
                />
                <ParticipantAudio
                  srcObject={p?.consumers.audio?.stream}
                  autoPlay
                />
              </>
            )}
          </FluidGridItem>
        ))}
      </FluidGrid>
    </Pager>
  );
}
