import { uniq } from "@ameelio/core";
import { MultiSelectChipInput, TextInput } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ConnectionStatus } from "../../api/graphql";
import activeOrgMembership from "../../lib/activeOrgMembership";
import useApolloErrorHandler from "../../lib/handleApolloError";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { ScreenTitle } from "../../lib/typography";
import { hasMaxLength, isRequired, mergeRules } from "../../lib/validate";
import { useCurrentCorrespondent } from "../../SessionBoundary";
import { GetColleagueMembershipsDocument } from "../GetColleagueMemberships.generated";
import { InmateConnection, SelectWebinarData } from "./types";

type Props = {
  connections: InmateConnection[];
  defaultValues: SelectWebinarData;
  stepper: StepperType;
  onSubmit: SubmitHandler<SelectWebinarData>;
};

export default function WebinarDetailsStep({
  connections,
  defaultValues,
  stepper,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const onError = useApolloErrorHandler();
  const user = useCurrentCorrespondent();
  const organizationId = activeOrgMembership(user)?.organization.id;

  const {
    watch,
    formState: { isValid },
    control,
    handleSubmit,
  } = useForm<{
    title: string;
    attendeeIds: string[];
    coHostIds: string[];
  }>({
    mode: "onChange",
    defaultValues: {
      title: defaultValues.title || "",
      attendeeIds: uniq(
        (defaultValues.connections || []).map((c) => c.inmate.id)
      ),
      coHostIds: uniq(
        (defaultValues.connections || [])
          .filter((c) => c.visitor.id !== user.id)
          .map((c) => c.visitor.id)
      ),
    },
  });
  const watchedAttendeeIds = watch("attendeeIds", []);
  const watchedCoHostIds = watch("coHostIds", []);

  const {
    data: colleaguesData,
    loading: orgMembersLoading,
    error: colleaguesError,
  } = useQuery(GetColleagueMembershipsDocument, {
    onError,
    fetchPolicy: "cache-and-network",
    variables: { organizationId: organizationId || "" },
  });

  if (colleaguesError) throw colleaguesError;

  const organization = colleaguesData?.organization;
  // Be sure to filter out the logged-in user from the list
  // of organization members so they can't add themselves as
  // a co-host (memberships are already filtered for only active
  // memberships by the query resolver in the API).
  const colleagueMemberships =
    organization?.memberships.filter((m) => m.visitor.id !== user.id) || [];

  const selectedLocation = connections.find((c) =>
    watchedAttendeeIds.includes(c.inmate.id)
  )?.inmate.facility;

  return (
    <StepperFormWrapper
      stepper={stepper({
        disabled: !isValid,
        loading: orgMembersLoading,
      })}
      handleSubmit={handleSubmit((data) => {
        if (!selectedLocation) throw new Error("A facility is required");
        onSubmit({
          title: data.title,
          connections: [
            ...connections.filter((c) =>
              data.attendeeIds.includes(c.inmate.id)
            ),
            ...colleagueMemberships
              .filter((m) => data.coHostIds.includes(m.visitor.id))
              .flatMap((m) =>
                m.visitor.connections
                  .filter((c) => c.status === ConnectionStatus.Active)
                  .filter((c) => data.attendeeIds.includes(c.inmate.id))
                  // TODO: hoist or update query
                  .map((c) => ({
                    ...c,
                    inmate: { ...c.inmate, facility: selectedLocation },
                    visitor: m.visitor,
                  }))
              ),
          ],
        });
      })}
    >
      <ScreenTitle>{t("Great, now let's get some details")}</ScreenTitle>
      <Typography variant="body1" marginTop={2}>
        {t(
          "For your webinar, please include a title, invited students, and any co-hosts."
        )}
      </Typography>

      <Stack spacing={2} marginY={6}>
        <TextInput
          control={control}
          name="title"
          label={t("Webinar title")}
          helperText={t("i.e. “Intro to Economics”")}
          rules={mergeRules(
            isRequired(t("Please enter a title.")),
            hasMaxLength(
              100,
              t("The title must be {{number}} characters or less.", {
                number: 100,
              })
            )
          )}
          autoComplete="off"
          autoFocus
        />

        <MultiSelectChipInput
          name="attendeeIds"
          label={t("Student(s)")}
          control={control}
          helperText={t(
            "If you can't find who you're looking for, try adding them as a contact"
          )}
          rules={isRequired(t("Please select at least one student."))}
          items={connections.map((conn) => {
            const locationMismatch =
              !!selectedLocation &&
              selectedLocation.id !== conn.inmate.facility.id;

            // find hosts i have selected who this inmate is not connected to
            const missingHosts = watchedCoHostIds
              .map((visitorId) =>
                colleagueMemberships.find((m) => m.visitor.id === visitorId)
              )
              // type narrowing after lookup
              .filter((i) => i !== undefined)
              .filter(
                (m) =>
                  !m.visitor.connections.find(
                    (c) => conn.inmate.id === c.inmate.id
                  )
              );

            return {
              value: conn.inmate.id,
              name: conn.inmate.fullName,
              disabled: locationMismatch || missingHosts.length > 0,
              helperText:
                selectedLocation && locationMismatch
                  ? t(
                      "Students may only be added from one facility. {{studentName}} does not reside at {{facilityName}}.",
                      {
                        studentName: conn.inmate.firstName,
                        facilityName: selectedLocation.name,
                      }
                    )
                  : missingHosts.length > 0
                    ? t("Not connected with {{ hostName }}", {
                        hostName: missingHosts[0].visitor.fullName,
                      })
                    : undefined,
            };
          })}
        />

        <MultiSelectChipInput
          name="coHostIds"
          label={t("Co-hosts (optional)")}
          helperText={t(
            "Select colleagues to give them hosting capabilities (i.e. video, audio, and screen-sharing)"
          )}
          control={control}
          items={colleagueMemberships.map((m) => {
            // find attendees i have selected who this colleague is not connected to
            const missingAttendees = watchedAttendeeIds
              .map(
                (inmateId) =>
                  connections.find((c) => c.inmate.id === inmateId)?.inmate
              )
              // type narrowing after lookup
              .filter((i) => i !== undefined)
              .filter(
                (i) =>
                  !m.visitor.connections.find(
                    (c) =>
                      c.status === ConnectionStatus.Active &&
                      i.id === c.inmate.id
                  )
              );
            return {
              value: m.visitor.id,
              name: m.visitor.fullName,
              disabled: missingAttendees.length > 0,
              helperText:
                missingAttendees.length > 0
                  ? t("Not connected with {{ studentName }}", {
                      studentName: missingAttendees[0].fullName,
                    })
                  : undefined,
            };
          })}
        />
      </Stack>
    </StepperFormWrapper>
  );
}
