import { Avatar } from "@ameelio/ui";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { Organization, User } from "../api/graphql";

type Props = {
  membership?: {
    organization: Pick<Organization, "logoImageUrl">;
  };
  user: Pick<User, "fullName">;
};

export default function OrganizationMembershipLogo({
  membership,
  user,
}: Props) {
  return membership?.organization.logoImageUrl ? (
    <Avatar src={membership.organization.logoImageUrl} contact={user} />
  ) : (
    <Avatar contact={user}>
      {membership && !membership.organization.logoImageUrl ? (
        <BusinessOutlinedIcon fontSize="medium" />
      ) : null}
    </Avatar>
  );
}
