import { Button } from "@ameelio/ui";
import { useMediaQuery as measureScreenWidth } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import addStylesForDevices from "../lib/addStylesForDevices";
import { onlyMobile } from "../lib/responsiveHelpers";
import EditConnectionRelationshipDialog from "./EditConnectionRelationshipDialog";

type Props = {
  relationship: string;
  connectionId: string;
};

export default function EditConnectionRelationshipButton({
  relationship,
  connectionId,
}: Props) {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const isMobile = onlyMobile(measureScreenWidth);
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setShowDialog(true)}
        sx={addStylesForDevices(
          isMobile,
          {
            ml: 2,
          },
          { width: 1 }
        )}
      >
        {t("Edit relationship")}
      </Button>
      {showDialog && (
        <EditConnectionRelationshipDialog
          relationship={relationship}
          connectionId={connectionId}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
}
