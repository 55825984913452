import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateVisitorIdentityMutationVariables = Types.Exact<{
  input: Types.CreateVisitorIdentityInput;
}>;


export type CreateVisitorIdentityMutation = { __typename: 'Mutation', createVisitorIdentity: { __typename: 'CreateVisitorIdentityResponse', visitor: { __typename: 'Visitor', id: Types.Scalars['ID']['input'], fullName: string, dateOfBirth: string | null, identity: { __typename: 'VisitorIdentity', id: Types.Scalars['ID']['input'], documentType: Types.IdDocumentType, lastUploaded: Types.Scalars['Date']['input'] } | null, systemRelationships: Array<{ __typename: 'SystemUser', id: Types.Scalars['ID']['input'], status: Types.SystemUserStatus }> } } };


export const CreateVisitorIdentityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateVisitorIdentity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateVisitorIdentityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createVisitorIdentity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"dateOfBirth"}},{"kind":"Field","name":{"kind":"Name","value":"identity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"documentType"}},{"kind":"Field","name":{"kind":"Name","value":"lastUploaded"}}]}},{"kind":"Field","name":{"kind":"Name","value":"systemRelationships"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateVisitorIdentityMutation, CreateVisitorIdentityMutationVariables>;