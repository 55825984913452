import { Button } from "@ameelio/ui";
import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetEventDetailsQuery } from "./GetEventDetails.generated";
import LeaveMeetingDialog from "./LeaveMeetingDialog";

type Props = {
  description: string;
  meeting: GetEventDetailsQuery["meeting"];
  onLeave: () => void;
};

export default function LeaveMeetingButton({
  description,
  meeting,
  onLeave,
}: Props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {open && (
        <LeaveMeetingDialog
          meetingToLeave={{
            description,
            meetingId: meeting.id,
          }}
          onClose={() => setOpen(false)}
          onLeave={onLeave}
        />
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Button
          variant="outlined"
          sx={{ width: 1 }}
          onClick={() => setOpen(true)}
        >
          {t("Leave event")}
        </Button>
      </Box>
    </>
  );
}
