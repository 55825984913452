import { Button, IconButton, Tooltip } from "@ameelio/ui";
import { LocalPhone, PhoneEnabledOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Correspondent } from "../api/graphql";
import { DateInterval } from "../lib/dateUtils";
import { formatTime } from "../lib/timeFormats";
import useBoolean from "../lib/useBoolean";
import useNow from "../lib/useNow";
import BeginVoiceCallDialog from "./BeginVoiceCallDialog";

type Props = {
  connectionId: string;
  showButtonText?: boolean;
  hasRestriction: boolean;
  mayMakeVoiceCall: boolean;
  nextVoiceCallSlot: DateInterval | null;
  contact: Pick<
    Correspondent,
    "firstName" | "unavailableUntil" | "canReceiveVoiceCalls"
  >;
};

function OfflineStatus({ right, bottom }: { right: number; bottom: number }) {
  return (
    <Box
      right={right}
      bottom={bottom}
      position="absolute"
      width={8}
      height={8}
      borderRadius="50%"
      bgcolor="salmon"
    />
  );
}

export default function VoiceCallsButton({
  showButtonText,
  connectionId,
  contact,
  nextVoiceCallSlot,
  mayMakeVoiceCall,
  hasRestriction,
}: Props) {
  const { t } = useTranslation();
  const voiceCallModal = useBoolean();
  const now = useNow({ updateDelay: 5000 });
  const unavailable = useMemo(() => {
    if (!contact.unavailableUntil) return false;
    return now.getTime() <= contact.unavailableUntil;
  }, [now, contact.unavailableUntil]);

  const callButtonIsDisabled =
    !contact.canReceiveVoiceCalls ||
    mayMakeVoiceCall === false ||
    hasRestriction;

  const onIconButtonClick = useCallback(
    () => (unavailable ? () => {} : voiceCallModal.enable()),
    [unavailable, voiceCallModal]
  );

  let tooltipMessage = "";

  if (hasRestriction) {
    tooltipMessage = t(
      "You are currently restricted from making voice calls with {{firstName}}",
      {
        firstName: contact.firstName,
      }
    );
  } else if (contact.canReceiveVoiceCalls) {
    // Visitor can receive voice calls

    if (mayMakeVoiceCall === false) {
      // Inmate cannot make voice calls
      if (!nextVoiceCallSlot) {
        // There is no slot available next, so we show a generic message
        tooltipMessage = t("Voice calls are not allowed right now");
      } else {
        tooltipMessage = t("You can make calls again starting at {{time}}", {
          time: formatTime(nextVoiceCallSlot.startAt),
        });
      }
    }
  } else {
    tooltipMessage = t("Voice calls are not available with {{firstName}}", {
      firstName: contact.firstName,
    });
  }

  if (unavailable) {
    tooltipMessage = t("Unavailable for voice calls until {{time}}", {
      time: formatTime(contact.unavailableUntil!),
    });
  }

  return (
    <>
      <Tooltip title={tooltipMessage}>
        <span>
          {showButtonText ? (
            <Button
              onClick={onIconButtonClick}
              disabled={callButtonIsDisabled || unavailable}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {unavailable && (
                  <Box position="relative" display="flex" alignItems="center">
                    <PhoneEnabledOutlined />
                    <OfflineStatus right={0} bottom={0} />
                  </Box>
                )}
                {!unavailable && <LocalPhone />}
                <Box ml={1}>{t("Call")}</Box>
              </Box>
            </Button>
          ) : (
            <IconButton
              size="large"
              color="primary"
              onClick={onIconButtonClick}
              ariaLabel={t("Voice calls")}
              disabled={callButtonIsDisabled || unavailable}
            >
              {unavailable && (
                <>
                  <PhoneEnabledOutlined />
                  <OfflineStatus right={10} bottom={10} />
                </>
              )}
              {!unavailable && <LocalPhone />}
            </IconButton>
          )}
        </span>
      </Tooltip>
      {voiceCallModal.current && (
        <BeginVoiceCallDialog
          connectionId={connectionId}
          onCancel={voiceCallModal.disable}
        />
      )}
    </>
  );
}
