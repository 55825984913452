import { Button } from "@ameelio/ui";
import { Alert, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  onClick: () => void;
};

export default function RejectionAlert({ title, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Alert severity="error" sx={{ mt: 1, alignItems: "flex-start" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography variant="body1" color="text.primary">
          {title}
        </Typography>
        <Box display="flex" alignItems="center" onClick={onClick} mt={3}>
          <Button variant="contained">{t("Review")}</Button>
        </Box>
      </Box>
    </Alert>
  );
}
