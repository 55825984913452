import { Link } from "@mui/material";
import { t } from "i18next";
import { Facility } from "../api/graphql";
import DetailsStack, { DetailsStackItem } from "../lib/DetailsStack";
import getHostname from "../lib/getHostname";
import ScreenSection from "../lib/ScreenSection";

type Props = {
  facility: Pick<
    Facility,
    | "name"
    | "publicId"
    | "province"
    | "country"
    | "email"
    | "phone"
    | "visitationFormLink"
  >;
};

export default function FacilityInfoSection({ facility }: Props) {
  return (
    <ScreenSection title={t("Facility info")}>
      <DetailsStack>
        <DetailsStackItem
          label={t("Name")}
          value={`${facility.name} (${facility.publicId})`}
        />
        <DetailsStackItem
          label={t("Location")}
          value={`${facility.province}, ${facility.country}`}
        />
        {facility.visitationFormLink && (
          <DetailsStackItem label={t("Visitor approval form")}>
            <Link href={facility.visitationFormLink}>
              {getHostname(facility.visitationFormLink) ||
                facility.visitationFormLink}
            </Link>
          </DetailsStackItem>
        )}
        <DetailsStackItem label={t("Visitors email")} value={facility.email} />
        <DetailsStackItem label={t("Visitors phone")} value={facility.phone} />
      </DetailsStack>
    </ScreenSection>
  );
}
