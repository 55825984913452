import { Button, SubmitButton } from "@ameelio/ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Grid,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import addStylesForDevices from "./addStylesForDevices";
import { onlyMobile } from "./responsiveHelpers";

type Props = {
  onBack?: () => void;
  next?: boolean;
  finish?: boolean;
  start?: boolean;
  loading: boolean;
  disabled?: boolean;
  step?: string;
  steps?: string[];
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 4,
  borderRadius: 5,
  marginBottom: 24,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#2E47BD60",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#2E47BD",
  },
}));

export default function Stepper({
  onBack,
  next,
  finish,
  start,
  loading,
  disabled,
  step,
  steps,
}: Props) {
  const { t } = useTranslation();
  const mobileOnly = onlyMobile(measureScreenWidth);
  const stepperValue = useMemo(() => {
    if (step && steps) {
      const index = steps.indexOf(step);
      const fraction = (index + 1) / steps.length;
      return fraction * 100;
    }
    // if you don't need progressions then will display a faded line
    return 0;
  }, [step, steps]);

  const styles: SxProps<Theme> = addStylesForDevices(
    mobileOnly,
    { minWidth: 160 },
    { minWidth: 125 }
  );

  return (
    <>
      <BorderLinearProgress variant="determinate" value={stepperValue} />
      <Grid container justifyContent="space-between">
        <>
          {onBack && (
            <Button variant="outlined" onClick={onBack} sx={styles}>
              <ArrowBackIcon />
              &nbsp;
              {t("Back")}
            </Button>
          )}
          {(next || start) && (
            <SubmitButton
              submitting={loading}
              disabledReason={
                disabled
                  ? t("Please fill all the required fields to continue")
                  : ""
              }
              disabled={disabled}
              sx={styles}
            >
              {start ? t("Start") : t("Next")}
            </SubmitButton>
          )}
          {finish && (
            <SubmitButton
              submitting={loading}
              disabledReason={
                disabled
                  ? t("Please fill all the required fields to continue")
                  : ""
              }
              disabled={disabled}
              sx={styles}
            >
              {t("Submit")}
            </SubmitButton>
          )}
        </>
      </Grid>
    </>
  );
}
