import { Button } from "@ameelio/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Error from "./Error.svg";
import FullscreenBox from "./FullscreenBox";

type Props = {
  resetError: () => void;
};

export default function ErrorScreen({ resetError }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <FullscreenBox
      sx={{
        backgroundColor: "#FAFAFA",
      }}
    >
      <Stack
        spacing={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
          minHeight: (theme) => `calc(100vh - ${theme.spacing(6)})`,
        }}
      >
        <img src={Error} alt="" width="257" />
        <Typography textAlign="center" variant="h3" component="h1">
          {t("Our apologies, this isn't working as it should.")}
        </Typography>
        <Typography textAlign="center">
          {t(
            "We’ll do what we can to fix this. In the meantime, you can try refreshing the page."
          )}
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          {t("Refresh")}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            resetError();
            navigate("/");
          }}
        >
          {t("Return home")}
        </Button>
      </Stack>
    </FullscreenBox>
  );
}

/**
 * This really shouldn't need to exist. However, rendering ErrorScreen directly
 * results in complaints about violating hook rules. It may be the result of how
 * the fallback function gets called inside a state component.
 */
export function ErrorFallback(props: Props) {
  return <ErrorScreen {...props} />;
}
