import { Button } from "@ameelio/ui";
import { Alert, AlertTitle, Box } from "@mui/material";
import { common } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

type Props = {
  onClick: () => void;
};

export default function MinorsOmitted({ onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Alert severity="info" sx={{ mb: 2.5, alignItems: "flex-start" }}>
      <Box
        display="flex"
        gap={1.5}
        sx={{ flexDirection: { xs: "column", sm: "row" } }}
      >
        <Box flex={0.75}>
          <AlertTitle>{t("Minors have been removed from results")}</AlertTitle>
          {t(
            "If you are looking for someone who is under the age of 18, you will need to provide more information."
          )}
        </Box>
        <Box
          flex={0.25}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            sx={{
              backgroundColor: common.white,
              border: "1px solid #E0E0E0",
              width: "100%",
            }}
            variant="text"
            size="medium"
            onClick={onClick}
          >
            {t("Search for a minor")}
          </Button>
        </Box>
      </Box>
    </Alert>
  );
}
