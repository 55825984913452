import { Box, Typography } from "@mui/material";
import { common } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

export default function SpotlightPlaceholder() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 1,
        width: 1,
        color: common.white,
      }}
    >
      <Typography variant="body1">
        {t("Waiting for host to join...")}
      </Typography>
    </Box>
  );
}
