import { useSnackbarContext } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  Alert,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { PickersDay } from "@mui/x-date-pickers";
import * as Sentry from "@sentry/react";
import { isSameDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "../../analytics";
import { ConnectionFeature, Facility } from "../../api/graphql";
import { DateInputBase } from "../../lib/DateInput";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { formatTimeRange } from "../../lib/timeFormats";
import { ScreenTitle } from "../../lib/typography";
import AvailabilityTile from "./AvailabilityTile";
import { GetConnectionAvailabilityDocument } from "./GetConnectionAvailability.generated";
import {
  Availability,
  InmateConnection,
  MeetingData,
  SelectTimeData,
} from "./types";
import { WorkflowAction } from "./useWorkflowSteps";

type Props = {
  editMeetingId?: string;
  action: WorkflowAction;
  defaultValues: MeetingData;
  stepper: StepperType;
  connection: Pick<InmateConnection, "id" | "confidential"> & {
    inmate: Pick<InmateConnection["inmate"], "id" | "firstName"> & {
      facility: Pick<Facility, "id" | "publicId">;
    };
    features?: ConnectionFeature[];
  };
  onSubmit: (data: SelectTimeData) => void;
};

export function getMaxGuestsForCapacity(capacity: number): number {
  // Total number of guests = total capacity - scheduler (1) - inmate (1)
  const NUM_SCHEDULERS = 1;
  const NUM_INMATES_PER_MEETING = 1;
  return capacity - NUM_SCHEDULERS - NUM_INMATES_PER_MEETING;
}

export default function SelectTimeStep({
  editMeetingId,
  action,
  defaultValues,
  stepper,
  connection,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const { meetingType } = defaultValues;
  const isEdit = action === "edit";

  const numGuests =
    (defaultValues.registeredGuests?.length || 0) +
    (defaultValues.unregisteredGuests?.length || 0);

  const [date, setDate] = useState<Date | null>(
    defaultValues.availability?.interval?.startAt
      ? new Date(defaultValues.availability?.interval?.startAt)
      : null
  );

  const {
    data: availabilityData,
    loading: availabilityLoading,
    error: availabilityError,
  } = useQuery(GetConnectionAvailabilityDocument, {
    fetchPolicy: "network-only",
    variables: {
      connectionId: connection.id,
      meetingType,
      ignoreMeetingId: editMeetingId,
    },
    onCompleted: (res) => {
      track("Meeting request - Availabilities searched", {
        meetingType,
        action: action === "edit" ? "update" : "create",
        facilityId: connection.inmate.facility.id,
        groupSize: numGuests + 1,
        availableSlots: res.connection.meetingAvailability.filter(
          (a) => a.availableKiosks.length > 0
        ).length,
      });
    },
  });
  if (availabilityError) throw availabilityError;

  const [availability, setAvailability] = useState<Availability | undefined>(
    isEdit &&
      defaultValues.availability &&
      defaultValues.kiosk &&
      numGuests > getMaxGuestsForCapacity(defaultValues.kiosk.capacity)
      ? undefined
      : defaultValues?.availability
  );

  const availabilitiesOnDate: Availability[] = useMemo(() => {
    if (availabilityData && date) {
      return availabilityData.connection.meetingAvailability.filter((a) =>
        isSameDay(date, a.interval.startAt)
      );
    }
    return [];
  }, [availabilityData, date]);

  // used to inform schedulers what guest quantity will reveal availabilities
  const guestCapacityWithAvailabilities = useMemo(() => {
    const capacities = availabilitiesOnDate.flatMap((avail) =>
      avail.availableKiosks.map((kiosk) =>
        getMaxGuestsForCapacity(kiosk.capacity)
      )
    );
    if (capacities.length) return Math.max(...capacities);
    return 0;
  }, [availabilitiesOnDate]);

  // clear the selected availability when date changes
  useEffect(() => {
    // This guards against an initial case when date is initialized with
    // a default value
    try {
      if (
        date &&
        defaultValues.availability &&
        date.toISOString() ===
          new Date(defaultValues.availability.interval.startAt).toISOString()
      ) {
        return;
      }
    } catch (e) {
      // The Date object can only represent dates between approximately ±273,790 years
      // relative to 1970. If the date object is NaN or undefined, it is considered invalid,
      // and calling toISOString() will throw a RangeError as well.
      if (e instanceof Error && e.name !== "RangeError") {
        Sentry.captureException(e);
      }
    }

    // Only set to undefined if the date doesn't exist or
    // if it has changed from a previously-selected date
    setAvailability(undefined);
  }, [setAvailability, date, defaultValues]);

  const dayHasAvailableMeetings = (day: Date) =>
    availabilityData?.connection.meetingAvailability.some(
      (a) =>
        !a.conflict &&
        a.availableKiosks.length > 0 &&
        isSameDay(day, a.interval.startAt)
    );

  const meetingDayIsUnavailable = (day: Date) =>
    availabilityData?.connection.meetingAvailability
      .filter((a) => isSameDay(day, a.interval.startAt))
      .every((a) => a.conflict || a.availableKiosks.length === 0);

  const dayHasNoMeetings = (day: Date) =>
    !availabilityData?.connection.meetingAvailability.some((a) =>
      isSameDay(day, a.interval.startAt)
    );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!availability) return;
    // Find the smallest kiosk available that can
    // accommodate a potential guest list size
    const kiosk = availability.availableKiosks
      ? availability.availableKiosks
          .sort((a, b) => a.capacity - b.capacity)
          .find((k) => getMaxGuestsForCapacity(k.capacity) >= numGuests)
      : defaultValues.kiosk;
    if (!kiosk) {
      snackbarContext.alert(
        "error",
        t("There are no available slots to accommodate your meeting")
      );
      return;
    }
    onSubmit({ availability, kiosk });
  };

  const now = Date.now();

  return (
    <StepperFormWrapper
      stepper={stepper({ disabled: !availability || availabilityLoading })}
      handleSubmit={handleSubmit}
    >
      <ScreenTitle>{t("When would you like to meet?")}</ScreenTitle>
      <Typography variant="body1" mt={2}>
        {t(
          "Availability is limited by the number of stations at the facility. {{facilityName}} approves a limited number of requests to meet with {{firstName}} each week.",
          {
            facilityName: connection.inmate.facility.publicId,
            firstName: connection.inmate.firstName,
          }
        )}
      </Typography>

      <Stack spacing={6} marginY={6}>
        <DateInputBase
          value={date}
          label={t("Select a date")}
          onChange={(d) => setDate(d)}
          disablePast
          loading={availabilityLoading}
          renderLoading={() => <CircularProgress />}
          disableHighlightToday
          shouldDisableDate={(d) => dayHasNoMeetings(d)}
          renderDay={(day, _selectedDays, pickersDayProps) => (
            <PickersDay
              {...pickersDayProps}
              sx={{
                backgroundColor:
                  pickersDayProps.disabled || !dayHasAvailableMeetings(day)
                    ? undefined
                    : dayHasAvailableMeetings(day)
                      ? blue[50]
                      : meetingDayIsUnavailable(day)
                        ? grey[200]
                        : undefined,
              }}
            />
          )}
        />

        {date && (
          <>
            {!availabilityLoading && !availabilitiesOnDate.length && (
              <Alert severity="error">
                {t(
                  "There are no time slots on this day. Please select a different day."
                )}
              </Alert>
            )}

            {availabilitiesOnDate.length > 0 &&
              !availabilitiesOnDate.some(
                (a) =>
                  a.availableKiosks.length > 0 &&
                  a.availableKiosks.some(
                    (kiosk) =>
                      getMaxGuestsForCapacity(kiosk.capacity) >= numGuests
                  )
              ) && (
                <Alert severity="error">
                  {guestCapacityWithAvailabilities > 0
                    ? t(
                        "There are no available time slots on this day. Meeting times are available for groups of up to {{count}} people, including yourself. You may edit your guest list to see more times.",
                        { count: guestCapacityWithAvailabilities + 1 } // copy references "yourself", so include scheduler in count
                      )
                    : t(
                        "There are no available time slots on this day. This is based on the number of time slots the facility makes available. Please select a different day."
                      )}
                </Alert>
              )}

            {/** the div fixes a Stack vs Grid conflict */}
            <div>
              {!availabilityLoading && availabilitiesOnDate.length > 0 && (
                <Typography variant="body2" mb={2}>
                  {t("Select a time")}
                </Typography>
              )}
              <Grid container spacing={1}>
                {availabilitiesOnDate.map((a) => (
                  <Grid
                    key={`${date}-${a.interval.startAt}-${a.interval.endAt}`}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <AvailabilityTile
                      disabled={
                        a.availableKiosks.length === 0 ||
                        !a.availableKiosks.some(
                          (avail) =>
                            getMaxGuestsForCapacity(avail.capacity) >= numGuests
                        ) ||
                        a.conflict ||
                        a.interval.endAt < now
                      }
                      unavailableReason={
                        a.availableKiosks.length === 0
                          ? t("No kiosks are available at this time")
                          : a.conflict
                            ? t(
                                "Your contact has a schedule conflict at this time"
                              )
                            : a.interval.endAt < now
                              ? t("This time slot has already passed")
                              : !a.availableKiosks.some(
                                    (avail) =>
                                      getMaxGuestsForCapacity(avail.capacity) >=
                                      numGuests
                                  )
                                ? t(
                                    "Your guest list is too large for this time slot"
                                  )
                                : ""
                      }
                      selected={
                        availability?.interval.startAt === a.interval.startAt &&
                        availability.interval.endAt === a.interval.endAt
                      }
                      onClick={() => setAvailability(a)}
                    >
                      {formatTimeRange(a.interval.startAt, a.interval.endAt)}
                    </AvailabilityTile>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        )}
      </Stack>
    </StepperFormWrapper>
  );
}
