import { OutputConnectionState } from "@ameelio/connect-call-client";
import { Box, SxProps, Theme, useMediaQuery } from "@mui/material";
import useChange from "@react-hook/change";
import { useRef, useState } from "react";
import { onlyMobile } from "../../lib/responsiveHelpers";
import videoStreamDimensions from "../../lib/videoStreamDimensions";
import { ltrTheme } from "../../theme";
import { ConnectionInfo } from "./types/ConnectionInfo";
import VideoParticipant from "./VideoParticipant";

type Props = {
  user: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  peerHasConnected: boolean;
  muted: boolean;
  hidden: boolean;
  localProducers: ConnectionInfo["localProducers"];
  callStatus?: ConnectionInfo["callStatus"];
  clientStatus?: ConnectionInfo["clientStatus"];
  connectionState?: OutputConnectionState;
};

const slowVideoTransitionDurationMS = 500;

const myVideoStyle: SxProps<Theme> = {
  position: "absolute",
  top: ltrTheme.spacing(3),
  left: { xs: ltrTheme.spacing(2), sm: ltrTheme.spacing(3) },
  zIndex: "modal",
  "> div:first-of-type": {
    position: "absolute",
  },
  width: { xs: "50%", md: "100%" },
};

export default function FloatingSelfieVideo({
  user,
  localProducers,
  callStatus,
  clientStatus,
  connectionState,
  peerHasConnected,
  hidden,
  muted,
}: Props) {
  const isMobile = onlyMobile(useMediaQuery);

  // determine the size of the floating window
  const myVideoDims = (() => {
    // selfie fills screen before peer joins
    if (!peerHasConnected) {
      return {
        width: `calc(100% - ${
          isMobile ? ltrTheme.spacing(4) : ltrTheme.spacing(6)
        })`,
        paddingBottom: `calc(100vh - ${ltrTheme.spacing(6)})`,
      };
    }

    // after peer joins, selfie shrinks to the top left corner
    const { aspectRatio } = videoStreamDimensions(localProducers.video?.stream);
    const videoScaleWidth = aspectRatio >= 1 ? 20 : isMobile ? 5 : 12;
    const spacing = aspectRatio >= 1 ? 25 : isMobile ? 10 : 17;
    return {
      width: `max(${videoScaleWidth}%, ${ltrTheme.spacing(spacing)})`,
      paddingBottom: `max(${videoScaleWidth / aspectRatio}%, ${ltrTheme.spacing(
        (videoScaleWidth + 5) / aspectRatio
      )})`,
    };
  })();

  // when a visitor signs in to an empty room, use a normal speed
  // transition to grow and fill the screen. when the other participant
  // joins, use a slightly slower transition to shrink into the corner.
  const [useSlowTransitionVideo, setUseSlowTransitionVideo] =
    useState<boolean>(false);
  const slowTransitionVideoTimeout = useRef<NodeJS.Timeout>();
  useChange(peerHasConnected, () => {
    clearTimeout(slowTransitionVideoTimeout.current);
    setUseSlowTransitionVideo(true);
    slowTransitionVideoTimeout.current = setTimeout(() => {
      setUseSlowTransitionVideo(false);
    }, slowVideoTransitionDurationMS);
  });

  return (
    <Box
      sx={{
        ...myVideoStyle,
        ...myVideoDims,
      }}
    >
      <VideoParticipant
        origin="local"
        mirrored
        audioMuted={muted}
        videoPaused={hidden}
        audioStream={localProducers.audio?.stream}
        stream={localProducers.video?.stream}
        user={user}
        callStatus={callStatus}
        clientStatus={clientStatus}
        connectionState={connectionState}
        containerSx={{
          borderRadius: peerHasConnected ? 2 : 4,
          filter: peerHasConnected
            ? "drop-shadow(0px 2px 32px rgba(0, 0, 0, 1))"
            : undefined,
        }}
        transitionDuration={
          useSlowTransitionVideo ? slowVideoTransitionDurationMS : undefined
        }
      />
    </Box>
  );
}
