import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivacyLevel } from "../api/graphql";
import { ContentSkeleton } from "../lib/closet";
import { GetContactsScreenDataQuery } from "./GetContactsScreenData.generated";

type Props = {
  facilityContacts: GetContactsScreenDataQuery | undefined;
};

export default function FacilityContactsList({ facilityContacts }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const data = facilityContacts;
  if (!data?.currentInmate) return <ContentSkeleton />;

  const approvedPhones = data.currentInmate.facility.approvedPhones
    .filter((p) => p.active)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box>
      {approvedPhones.length === 0 && (
        <Box mt={2}>
          <Typography variant="body1" color="text.secondary">
            {t("{{facility}} does not have any facility-wide resources", {
              facility: data.currentInmate.facility.name,
            })}
          </Typography>
        </Box>
      )}
      {approvedPhones.map((approvedPhone) => (
        <Card
          key={approvedPhone.id}
          variant="outlined"
          sx={{ my: 2, px: { xs: 1, sm: 2 }, py: 2 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="column" spacing={1}>
              <Typography variant="h2">{approvedPhone.name}</Typography>
              <Typography color="text.secondary" variant="body1">
                {approvedPhone.description}
              </Typography>
              {approvedPhone.privacyLevel === PrivacyLevel.Monitored ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <VisibilityOutlinedIcon color="action" />{" "}
                  <Box>
                    <Typography color="text.secondary" variant="body1">
                      {t("May be monitored by staff")}
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <LockOutlinedIcon color="action" />{" "}
                  <Box>
                    <Typography color="text.secondary" variant="body1">
                      {t("Not monitored by staff")}
                    </Typography>
                  </Box>
                </Stack>
              )}
            </Stack>
            <Button
              onClick={() =>
                navigate(`/phone-call/${approvedPhone.id}`, {
                  state: { from: location.pathname },
                })
              }
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <CallOutlinedIcon /> <Box> {t("Call")} </Box>
              </Stack>
            </Button>
          </Stack>
        </Card>
      ))}
    </Box>
  );
}
