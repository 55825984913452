import {
  Box,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import addStylesForDevices from "./lib/addStylesForDevices";
import Link from "./lib/Link";
import { onlyMobile } from "./lib/responsiveHelpers";

export type Props = {
  message: string;
  linkProps?: {
    label: string;
    to: string;
    state?: {
      fromPath?: string;
      fromName?: string;
    };
    disabled?: boolean;
  };
  illustration: string;
  illustrationWidth: string;
};

export default function EmptyScreen({
  message,
  linkProps,
  illustration,
  illustrationWidth,
}: Props) {
  const isMobile = onlyMobile(measureScreenWidth);
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
      <img src={illustration} alt={message} width={illustrationWidth} />
      <Typography textAlign="center" variant="body1">
        {message}
      </Typography>
      {linkProps && (
        <Box
          sx={addStylesForDevices(
            isMobile,
            {},
            {
              width: 1,
            }
          )}
        >
          <Link
            button
            disabled={linkProps.disabled}
            variant="contained"
            sx={addStylesForDevices(isMobile, {}, { width: 1 })}
            {...linkProps}
          >
            {linkProps.label}
          </Link>
        </Box>
      )}
    </Box>
  );
}
